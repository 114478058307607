import React, { useState } from "react";

import styles from "./predictcreate.module.scss";
import { ReactComponent as Naver } from "../../../assets/images/share/naver.svg";
import { ReactComponent as Kakao } from "../../../assets/images/share/kakao.svg";
import { ReactComponent as Ga4 } from "../../../assets/images/home/home-report-ga4.svg";
import { ReactComponent as Google } from "../../../assets/images/share/google.svg";
import { ReactComponent as Facebook } from "../../../assets/images/share/facebook.svg";

import { SelectList } from "./select/SelectList";
import { CreateDetail } from "./detail/CreateDetail";
import { CreatedList } from "./list/CreatedList";


export const PredictCreate = () => {
    const [openDetails, setOpenDetails] = useState("default");
    const [isReportOpen, setIsReportOpen] = useState([false, false, false, false, false]);
    const [menu, setMenu] = useState("select")
    const [media, setMedia] = useState(null)

    const reportData = [
        {
            icons: [<Naver />, <Kakao />, <Google />],
            title: "광고비 입력 예측모델",
            description: "광고비를 입력하면 애드포레스트의 예측 모델을 통해 광고비 대비 광고 성과를 예측할 수 있습니다. 이를 위해 차트, 표, 예측 모델이 제공됩니다.",
            // dataLink: "GA4 데이터를 연결해주세요.",
            dataLink: "매체 데이터를 연결해주세요.",
            value: "cost_report"
        },
        {
            icons: [<Naver />, <Kakao />, <Google />],
            title: "주간 월간 예측모델",
            description: "애드포레스트의 예측 모델을 통해 주간, 월간 광고비 대비 광고 성과를 예측할 수 있습니다. 이를 위해 차트, 표, 예측 모델이 제공됩니다.",
            // dataLink: "GA4 데이터를 연결해주세요.",
            dataLink: "매체 데이터를 연결해주세요.",
            value: "period_report"
        },
    ];

    const handleDetailClick = (value, index) => {
        setMedia(value)
        setOpenDetails((prevState) => (prevState === value ? "default" : value));
        setIsReportOpen((prevState) => {
            const newState = Array(reportData.length).fill(false); // 모든 보고서를 접기 상태로 초기화
            newState[index] = !prevState[index]; // 클릭된 보고서의 상태 토글
            return newState;
        });
    };

    const handleSelectMenu = (menu) => {
        setMenu(menu);
    }

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div 
                    className={styles.headerMenu}
                    onClick={() => handleSelectMenu("select")}
                >
                    <p>1. 예측모델 선택하기</p>
                    <div className={menu === "select" ? styles.activeBottom : styles.menuBottom}></div>
                </div>
                <div 
                    className={styles.headerMenu}
                    onClick={() => handleSelectMenu("list")}
                >
                    <p>2. 생성된 예측모델</p>
                    <div className={menu === "list" ? styles.activeBottom : styles.menuBottom}></div>
                </div>
            </div>
            <div className={styles.container}>
                {menu === "select" && 
                <div className={styles.selectBox}>
                    <SelectList reportData={reportData} handleDetailClick={handleDetailClick} isReportOpen={isReportOpen} media={media} />
                    <CreateDetail openDetails={openDetails} />
                </div>
                }
                {menu === "list" &&
                <>
                    <CreatedList />
                </>
                }
            </div>
        </div>
    );
};
