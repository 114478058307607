import React from "react";

import { ReactComponent as LinkImg } from "../../../../assets/images/datalink/datalink-link.svg"

import styles from "../scss/modal/unlinksuccessmodal.module.scss"

export const UnLinkSuccessModal = ({ onClose }) => {
    
    return (
        <div className={styles.modalBackdrop}>
            <div className={styles.modalContent}>
                <LinkImg />
                <p>정상적으로 해지되었습니다.</p>
                <button onClick={onClose}>확인</button>
            </div>
        </div>
    );
};