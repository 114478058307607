import React from 'react';

import styles from './scorecard.module.scss';

export const ScoreCard = ({data}) => {
    // console.log(data)
    const getChangeClassName = (operator) => {
        if (operator === "up") return styles.positive;
        if (operator === "down") return styles.negative;
        return '';
    }

    const formattedPercent = (name, change, operator) => {
        if (name === '클릭률' || name === '전환율') {
            if (operator === "up") return `+${change}%p`;
            else if (operator === "down") return `-${change}%p`;
            return `${change}%p`
        } else  {
            if (operator === "up") return `+${change}%`;
            else if (operator === "down") return `-${change}%`;
            return `${change}%`
        }
    }

    return (
        <>
            {data.map((item, index) => (
                <div key={index} className={styles.scoreCard}>
                    <div className={styles.scoreCardTitle}>{item.name}</div>
                    <div className={styles.scoreCardValue}>{item.value}</div>
                    <div className={`${styles.scoreCardChange} ${getChangeClassName(item.operator)}`}>
                        {formattedPercent(item.name, item.change, item.operator)}
                    </div>
                </div>
            ))}
        </>
    );
} 