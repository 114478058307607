import axios from "../axios";

export const TargetResultApi = async (reportId) => {
    try {
        const path = `report/${reportId}/target-result/`
        const { data } = await axios.get(path);
        return data
    } catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error)
        return null;
    }
}

export const ChangeTargetResultApi = async (reportId, conversion, conversionAmount) => {
    try {
        const path = `report/${reportId}/target-result/`
        const { data }  = await axios.patch(path, { 
            conversion: conversion,
            sale_conversion_amount: conversionAmount
        });
        return data;
    } catch (error) {
        throw error;
    }
};