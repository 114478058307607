import axios from "../../axios";

export const PrevSalesTrendApi = async ( incrementId, reportType ) => {
    const path = `prediction-model/past/sales-trend/${incrementId}`

    try {
        const { data } = await axios.get(path, {
            params: {
                report_type: reportType
            }
        });
        return data;
    } catch (error) {
        console.error("예측모델 매출 추이 오류 발생:", error);
        throw error;
    }
};

export const PrevMediaMetricsApi = async ( incrementId, reportType ) => {
    const path = `prediction-model/past/media-metrics/${incrementId}`

    try {
        const { data } = await axios.get(path, {
            params: {
                report_type: reportType
            }
        });
        return data;
    } catch (error) {
        console.error("예측모델 매체별 성과 지표 오류 발생:", error);
        throw error;
    }
};
