import React from 'react';

import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from "chartjs-plugin-datalabels";
import 'chart.js/auto';
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export const DevicePieChart = ({ data, color1, color2, color3, selectedValue }) => {
    // console.log(selectedValue)
    const values = data.map(item => item.value);
    const intValues = values.map(value => {
        // 문자열인지 확인 후 변환
        return typeof value === 'string' ? parseInt(value, 10) : value;
    });

    const chartData = {
        labels: data.map(item => item.device),
        datasets: [
            {
                data: intValues,
                backgroundColor:[
                    color1,
                    color2,
                    color3
                ],
                borderWidth: 1,
            }
        ]
    }

    const total = intValues.reduce((a, b) => a + b, 0);

    // console.log(intValues, total)

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation:true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',
                    boxWidth: 10,
                    boxHeight: 10,
                    color: '#000',
                    font: {
                        family: "Pretendard",
                        size: 12,
                        weight: "bold",
                    },
                },
            },
            datalabels: {
                formatter: function (value) {
                    let percentage = ((value * 100) / total).toFixed(2);
                    if (percentage <= 10) { 
                        return ''; 
                    } else {
                        if (selectedValue === "click_rate") {
                            return `${percentage}%\n${value}%`; // roas일 경우 퍼센트 반환
                        } else if (selectedValue === "count" || selectedValue === "impression" || selectedValue === "click") {
                            return `${percentage}%\n${value.toLocaleString()}회`;
                        } else if (selectedValue === "roas") {
                            return `${percentage}%\n${value}%`; // roas일 경우 퍼센트 반환
                        } else {
                        }
                            return `${percentage}%\n${value.toLocaleString()}원`; // 그 외의 경우 원 반환
                    }
                },
                color: '#000',
                font: {
                    family: 'Pretendard',
                    weight: 'bold'
                }
            },
        }
    }

	return (
		<Pie data={chartData} options={options} />
	);
}
