import React, { useState } from 'react'

import styles from './predtable.module.scss'

export const PredTable = ({ 
        headers, 
        items, 
        mediaInfoId,
    }) => {

    return (
        <>
            <div className={styles.table}>
                <div className={styles.tableHeader}>
                    <div className={styles.tableHeaderRow}>
                        {headers.map(header => (
                            <div key={header}>{header}</div>
                        ))}
                    </div>
                </div>
                <div className={styles.tableBody}>
                    {items.map((item, index) => (
                        <div key={index} className={styles.tableBodyRow}>
                            {headers.map(header => {
                                const value = item[header];
                                return (
                                    <div key={header}>
                                        {header === "클릭률" || header === "광고수익률" ? `${value}%` : // 캠페인 유형일 때 value 출력
                                            (header === "광고비" || header === "전환 매출액"? `₩${value.toLocaleString()}` : `${value.toLocaleString()}`)
                                        }
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};
