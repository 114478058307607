import React, { useState } from 'react'

import styles from './reportdatable.module.scss'

export const ReportDaTable = ({ 
        headers, 
        items, 
        mediaInfoId,
    }) => {

    return (
        <>
            <div className={styles.table}>
                <div className={styles.tableHeader}>
                    <div className={styles.tableHeaderRow}>
                        {headers.map(header => (
                            <div key={header}>{header}</div>
                        ))}
                    </div>
                </div>
                <div className={styles.tableBody}>
                    {items.map((item, index) => (
                        <div key={index} className={styles.tableBodyRow}>
                            {headers.map(header => {
                                const value = item[header];
                                const isCurrencyRow = item["지표/요일"] === "평균매출" || item["지표/요일"] === "평균 비용";
                                const isChangeRow = item["지표/요일"] === "매출 증감률"; // 매출 증감률 조건 추가
                                return (
                                    <div key={header} style={isChangeRow && header !== "지표/요일" ? { color: value.includes('-') ? 'red' : value === "0.00" ? 'rgba(0,0,0,0.6)' : 'blue' } : {}}>
                                        {header === "캠페인 유형" ? value : // 캠페인 유형일 때 value 출력
                                            isChangeRow && header !== "지표/요일" ? 
                                            `${value}% ${value.includes('-') ? '▼' : value === "0.00" ? '-' : '▲'}` : // 매출 증감에 따라 기호 추가
                                            (isCurrencyRow && header !== "지표/요일" || header === "광고비" || header === "전환 매출액"? `₩${value.toLocaleString()}` : `${value.toLocaleString()}`)
                                        }
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};
