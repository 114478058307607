import React, { useState } from "react";
import { Link } from "react-router-dom";

import styles from "./reportlist.module.scss";


import { ReportCreateModal } from "../modal/ReportCreateModal";

export const ReportList = ({ reportData, handleDetailClick, isReportOpen, media, type, isMediaMatched }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleReportCreate = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // console.log(isMediaMatched);

    return (
        <div className={styles.main}>
            <div className={styles.listContainer}>
                {reportData.map((report, index) => (
                    <div key={report.value} className={isReportOpen[index] ? `${styles.listBox} ${styles.open}` : styles.listBox}>
                        <div className={isReportOpen[index] ? `${styles.list} ${styles.open}` : styles.list}>
                            <div className={styles.mediaImg}>
                                {report.icons.map((Icon, i) => (
                                    <span key={i}>{Icon}</span>
                                ))}
                            </div>
                            <div className={styles.titleBox}>
                                <p className={styles.listTxt1}>{report.title}</p>
                                <p className={styles.listTxt3}>{report.warning}</p>
                            </div>
                            <p className={styles.listTxt2}>{report.description}</p>
                            {!isReportOpen[index] ? (
                                <div className={styles.detailBtn} onClick={() => handleDetailClick(report.value, index, report.type, report.code)}>
                                    <p className={styles.detailBtnTxt1}>자세히보기</p>
                                    <p className={styles.detailBtnTxt2}>{">"}</p>
                                </div>
                            ) : (
                                <div>
                                    <div className={styles.dataLink}>
                                        <li>{report.dataLink}</li>
                                        {report.dataLink2 && 
                                            <li>{report.dataLink2}</li>
                                        }
                                    </div>
                                    <div className={styles.dataLinkBtn}>
                                        <button>
                                            <Link to="/datalink">데이터 연결하기</Link>
                                        </button>
                                    </div>
                                    <div className={styles.reportBtn}>
                                        <button 
                                            onClick={handleReportCreate}
                                            className={isMediaMatched ? styles.activeButton : styles.disabledButton} 
                                            disabled={!isMediaMatched}
                                        >
                                            보고서 생성
                                        </button>
                                    </div>
                                    <div className={styles.detailBtn} onClick={() => handleDetailClick(report.value, index)}>
                                        <p className={styles.detailBtnTxt1}>접기</p>
                                        <p className={styles.detailBtnTxt3}>{">"}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {isModalOpen && 
                <ReportCreateModal onClose={handleCloseModal} media={media} type={type}/>
            }
        </div>
    );
};
