import React from "react";

import styles from "../reportdetail.module.scss";
import { ReactComponent as Cost } from "../../../../assets/images/report/report-gfa-cost.svg";
import { ReactComponent as Campaign } from "../../../../assets/images/report/report-gfa-campaign.svg";
import { ReactComponent as Keyword } from "../../../../assets/images/report/report-gfa-keyword.svg";

export const ReportPreviewGfa = ({ currentIndex }) => {
    const images = [<Cost />, <Campaign />, <Keyword />];

    return (
        <>
            {images[currentIndex]}
        </>
    );
};
