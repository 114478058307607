import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from "chartjs-plugin-datalabels";
import 'chart.js/auto';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export const DaPieChart = ({data, mediaInfoId}) => {

    const backgroundColor = () => {
        if (mediaInfoId === 999) {
            return [
                'rgba(0, 176, 80, 0.8)',
                'rgba(254, 194, 32, 1)',
                'rgba(214, 73, 87, 1)',
                'rgba(0, 114, 240, 1)',                   
            ]
        }
        return [
            'rgba(255, 180, 18, 0.8)',
            'rgba(238, 238, 0, 0.6)',
            'rgba(255, 133, 214, 0.5)',
            'rgba(51, 243, 131, 1)',                   
            'rgba(83, 183, 255, 0.5)',
        ]
    }

    const label = () => {
        if (mediaInfoId === 999) {
            return data.data.map(item => item.media)
        } 
        return data.data.map(item => item.landing_type)
    }

    const chartData = {
        labels: label(),
        datasets: [
            {
                data: data.data.map(item => item.tot_cost),
                backgroundColor: backgroundColor(),
                borderWidth: 0,
            }
        ]
    }

    const total = data.total.sum_tot_cost;

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation:true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    // usePointStyle: true,
                    display: false,
                    boxWidth: 10,
                    boxHeight: 10,
                    color: '#000',
                    font: {
                        family: "Pretendard",
                        size: 12,
                        weight: "bold",
                    },
                },
            },
            datalabels: {
                formatter: function (value) {
                    let percentage = ((value * 100) / total).toFixed(2);
                    if (percentage <= 10) { 
                        return ''; 
                    } else {
                        return percentage + "%"; 
                    }
                },
                color: '#000',
                font: {
                    weight: 'bold'
                }
            },
        }
    }

    return (
        <div style={{width: "257px", height: "257px"}}>
            <Pie data={chartData} options={options}/>
        </div>
    )
};

export const PredPieChart = ({data}) => {
    const backgroundColor = (name) => {
        if (name === "네이버") {
            return 'rgba(0, 176, 80, 0.8)';
        }
        if (name === "구글") {
            return 'rgba(214, 73, 87, 1)';
        }
        if (name === "카카오") {
            return 'rgba(254, 194, 32, 1)';
        }
        if (name === "메타") {
            return 'rgba(0, 114, 240, 1)';
        }
        return '';
    }

    // const label = () => {
    //     return data.map(item => item.media)
    // }

    const chartData = {
        labels: data.map(item => item.media),
        datasets: [
            {
                data: data.map(item => item.total_cost),
                backgroundColor: data.map(item =>  backgroundColor(item.media)),
                borderWidth: 0,
            }
        ]
    }

    const total = data.reduce((acc, item) => acc + item.total_cost, 0);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation:true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    // usePointStyle: true,
                    display: false,
                    boxWidth: 10,
                    boxHeight: 10,
                    color: '#000',
                    font: {
                        family: "Pretendard",
                        size: 12,
                        weight: "bold",
                    },
                },
            },
            datalabels: {
                formatter: function (value) {
                    let percentage = ((value * 100) / total).toFixed(2);
                    if (percentage <= 10) { 
                        return ''; 
                    } else {
                        return percentage + "%"; 
                    }
                },
                color: '#000',
                font: {
                    weight: 'bold'
                }
            },
        }
    }

    return (
        <div style={{width: "257px", height: "257px"}}>
            <Pie data={chartData} options={options}/>
        </div>
    )
};