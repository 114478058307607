import React, { useState } from "react";

import styles from "./weeklyperiod.module.scss"

import { PrevWeekly } from "./prev/PrevWeekly";
import { ResultWeekly } from "./result/ResultWeekly";

export const WeeklyPeriod = ({ startDate, endDate, incrementId, linkedData }) => {
    const [date, setDate] = useState(null);
    console.log(date)
    return (
        <div className={styles.weekly}>
            <p className={styles.weeklyTitle}> {startDate} ~ {endDate} </p>
            <PrevWeekly incrementId={incrementId} reportType="weekly" setDate={setDate} />
            <p className={styles.weeklyTitle}>주간 예측 결과</p>
            <ResultWeekly startDate={startDate} endDate={endDate} incrementId={incrementId} reportType="weekly" linkedData={linkedData} />
        </div>
    )
}