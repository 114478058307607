import React, { useState } from "react";
import { useSelector } from "react-redux";

import styles from "./scss/reportnminputmodal.module.scss";
import { ReactComponent as ModalClose } from "../../../assets/images/share/modal-close-green.svg";
import { ReactComponent as NmInput } from "../../../assets/images/report/report-nmInput.svg";

import { CreateReportApi, CreatePredictApi } from "../../../api/report/CreateReportApi";

export const ReportNmInputModal = ({ onClose, handlePrevBtn, selectedOption, media, type }) => {
    const [reportName, setReportName] = useState("");
    const username = useSelector((state) => state.user.username);
    // console.log(selectedOption, media, type)

    const handleClose = () => {
        onClose();
    };

    const handleCreateReport = async () => {
        try {
            // 여러 개의 선택된 option을 API 호출 시 사용할 수 있게 처리
            const selectedIncrementIds = Object.values(selectedOption).map((option) => option.increment_id);
            const selectedMediaCode = Object.values(selectedOption).map((option) => option.media_info_code);
            const response = (media === 'cost_report' || media === 'preiod_report') 
                ? await CreatePredictApi(username, reportName, selectedIncrementIds, selectedMediaCode, media) 
                : await CreateReportApi(username, reportName, selectedIncrementIds, selectedMediaCode, type);
            // console.log("Report Created:", response);
            onClose();
            window.location.reload();
        } catch (error) {
            console.error("Failed to create report:", error.response.data.data);
            alert(error.response.data.error || error.response.data.data.details);
        }
    };

    return (
        <div className={styles.modalBackdrop}>
            <div className={styles.modalContent}>
                <div className={styles.modalCloseBtn}>
                    <div onClick={handleClose} style={{ cursor: "pointer" }}>
                        <ModalClose />
                    </div>
                </div>
                <div className={styles.modalTitle}>
                    <p>보고서 생성하기</p>
                </div>
                <div className={styles.mediaLinkBox}>
                    <NmInput />
                    <div className={styles.txtBox}>
                        <p className={styles.txtBoxTxt1}>광고매체 연결</p>
                        <p className={styles.txtBoxTxt2}>보고서 이름 입력</p>
                    </div>
                </div>
                <div className={styles.advMediaBox}>
                    <div>
                        <p className={styles.advMediaBoxTxt1}>보고서 이름 입력</p>
                        <p className={styles.advMediaBoxTxt2}>입력한 이름으로 보고서가 저장됩니다.</p>
                    </div>
                    <input
                        className={styles.inputBox}
                        placeholder="네이버 광고 성과 데이터 분석 보고서"
                        value={reportName} // state 값을 input에 연결
                        onChange={(e) => setReportName(e.target.value)} // input 변경 시 state 업데이트
                    />
                </div>
                <div className={styles.dataCheckBox}>
                    <p className={styles.checkBoxTxt}>연결하실 데이터를 확인해주세요.</p>
                    {Object.values(selectedOption).map((option, index) => (
                        <div className={styles.checkData}>
                            <div key={index}>
                                {option.link_data_nm || option.account_nm}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.modalBtnBox}>
                    <button className={styles.prevBtn} onClick={handlePrevBtn}>이전</button>
                    <button className={styles.nextBtn} onClick={handleCreateReport}>생성하기</button>
                </div>
            </div>
        </div>
    );
};
