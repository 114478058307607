import React from "react";

import styles from "./predictreport.module.scss"

import { PredictCreate } from "./create/PredictCreate";


export const PredictReport = () => {
    return (
        <div className={styles.predictReport}>
            {/* <button onClick={() => window.location.href = "predict/advcost"}>광고비 성과 예측 보고서</button>
            <button onClick={() => window.location.href = "predict/period"}>주간/월간 예측 보고서</button> */}
            <PredictCreate />
        </div>
    )
}

