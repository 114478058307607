import React, { useState } from "react";
import { ReactComponent as DataLinkOption } from "../../../../assets/images/datalink/datalink-option.svg";
import styles from "../scss/box/linkdatalistbox.module.scss";
import { ChangeNameModal } from "../modal/ChangeNameModal";
import { ChangeNameSuccessModal } from "../modal/ChangeNameSuccessModal";
import { UnLinkModal } from "../modal/UnLinkModal";
import { UnLinkSuccessModal } from "../modal/UnLinkSuccessModal";

export const ChangeNameBox = ({ link_data_nm, increment_id, handleUpdateName }) => {
    const [changeModal, setChangeModal] = useState(false);
    const [changeSuccessModal, setChangeSuccessModal] = useState(false);
    const [unLinkModal, setUnLinkModal] = useState(false);
    const [unLinkSuccessModal, setUnLinkSuccessModal] = useState(false);
    const [selectedIncrementId, setSelectedIncrementId] = useState(null);
    const [selectedLinkDataName, setSelectedLinkDataName] = useState("");


    const changeOpenModal = () => {
        setChangeModal(true);
    };

    const changeCloseModal = () => {
        setChangeModal(false);
    };

    const handleChange = async (incrementId, newName) => {
        await handleUpdateName(incrementId, newName);
        changeCloseModal();
        setChangeSuccessModal(true);
    };

    const closeChangeSuccessModal = () => {
        setChangeSuccessModal(false);
    };

    return (
        <>
            {/* <div className={styles.changeNmBtn}> */}
                <button onClick={() => changeOpenModal()} className={styles.contentOptionBtn} ><DataLinkOption /></button>
            {/* </div> */}
            {changeModal && (
                <ChangeNameModal 
                    linkDataName={link_data_nm}
                    incrementId={increment_id}
                    onClose={changeCloseModal}
                    onConfirm={handleChange}
                />
            )}
            {changeSuccessModal && (
                <ChangeNameSuccessModal onClose={closeChangeSuccessModal} />
            )}
        </>
    );
};
