import React, { useState, useEffect, useContext } from 'react';

import styles from '../scss/dapageperformance.module.scss';

import { DaPagePerformancePieApi, DaPagePerformanceBarApi } from '../../../../../api/reportDA/creative/DaPagePerformanceApi';

import { DaPieChart } from '../../../../utils/chart/DaPieChart';
import { DaBarChart } from '../../../../utils/chart/DaBarChart';
import Loading from '../../../../utils/loading/Loading';

import { DaPageTable } from './DaPageTable';


export const DaPagePerformance = ({mediaInfoId, adAccountsId, startDate, endDate, mediaName}) => {
    const [data, setData] = useState(null);
    const [pieData, setPieData] = useState(null);
    const [loading, setLoading] = useState(true);

    // const handleResponse = (response) => {
    //     const highestClickRateCampaign = response.data.reduce((prev, current) => {
    //         return (prev.click_rate > current.click_rate) ? prev : current;
    //     });
    //     setTopCampaign({clickRate: highestClickRateCampaign.campaign_name});
    // }

    useEffect(() => {
        const fetchData = async () => {
            const pieResponse = await DaPagePerformancePieApi(mediaName, startDate, endDate, adAccountsId);
            // handleResponse(gaugeResponse);
            setPieData(pieResponse);
            const barResponse = await DaPagePerformanceBarApi(mediaName, startDate, endDate, adAccountsId);
            setData(barResponse);
            setLoading(false);
            // console.log(pieResponse, barResponse)
        };
        fetchData();
    }, [startDate, endDate, adAccountsId]);

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    const transformedData = data.data.map(item => ({
        "캠페인 유형": item.campaign_type,
        "광고비": item.tot_cost,
        "노출수": item.tot_impression,
        "클릭수": item.tot_click,
        "클릭률": item.click_rate,
        "전환수": item.tot_conversion_count,
        "전환 매출액": item.tot_conversion_amount,
        "광고수익률": item.roas,
    }));

    return (
        <div className={styles.daPagePerformance}>
            <div className={styles.daPagePerformanceTitle}>지면별 성과 분석</div>
            <div className={styles.daPagePerformanceChart}>
                <div>
                    <p>광고비</p>
                    <DaPieChart data={pieData} mediaInfoId={mediaInfoId} />
                </div>
                <div>
                    <p>전환 매출액</p>
                    <DaBarChart data={data} mediaInfoId={mediaInfoId} label1="전환 매출액" label2="이전 기간 전환 매출액" />
                </div>
            </div>
            <DaPageTable mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} mediaName={mediaName} />
        </div>
    );
};