import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import styles from "./campaigngauge.module.scss"; // CSS 모듈 import 추가

ChartJS.register(ArcElement, Tooltip, Legend);

export const CampaignGauge = ({ data, selectedData, mediaInfoId, reportType }) => {
    const totalData = data.total[`sum_${selectedData}`];

    const dataName = () => {
        if (selectedData === "tot_impression") {
            return "노출수";
        } else if (selectedData === "tot_click") {
            return "클릭수";
        } else if (selectedData === "click_rate") {
            return "클릭률";
        } else if (selectedData === "tot_cost") {
            return "광고비";
        } else if (selectedData === "impression_cost") {
            return "노출당 비용";
        } else if (selectedData === "cost_click") {
            return "클릭당 비용";
        } else if (selectedData === "conversion_cost") {
            return "전환당 비용";
        }
    }

    const backgroundColor = () => {
        if (mediaInfoId === 5 ) {
            return ['rgba(0, 176, 80, 0.8)', 'rgba(249, 185, 112, 0.6)', 'rgba(255, 133, 214, 0.5)', 'rgba(83, 183, 255, 0.5)'];
        }
        else if (mediaInfoId === 89 ) {
            return ['rgba(255, 180, 18, 0.8)', 'rgba(249, 185, 112, 0.6)', 'rgba(255, 133, 214, 0.5)', 'rgba(83, 183, 255, 0.5)'];
        } 
        else if (mediaInfoId === 100 && reportType === "da" ) {
            return ['rgba(46, 103, 156, 0.8)', 'rgba(249, 185, 112, 0.6)', 'rgba(255, 133, 214, 0.5)','rgba(51, 243, 131, 1)', 'rgba(83, 183, 255, 0.5)'];
        }
        else if (mediaInfoId === 100 ) {
            return ['rgba(0, 114, 240, 0.8)', 'rgba(249, 185, 112, 0.6)', 'rgba(255, 133, 214, 0.5)', 'rgba(83, 183, 255, 0.5)'];
        }
        else if (mediaInfoId === 7 ) {
            return ['rgba(255, 180, 18, 0.8)', 'rgba(238, 238, 0, 0.6)', 'rgba(255, 133, 214, 0.5)', 'rgba(51, 243, 131, 1)', 'rgba(83, 183, 255, 0.5)'];
        }
    };
    
    const gaugeData = {
        labels: data.data.map(item => item.ad_group_name || item.campaign_name),
        datasets: [
            {
                data: data.data.map(item => item[selectedData]),
                backgroundColor: backgroundColor(),
                borderWidth: 0,
                circumference: 360,
                rotation: 0
            }
        ]
    }

    const options = {
        responsive: true,
        cutout: '55%',
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.label || '';
                        const value = context.raw || 0;
                        const percentage = ((value / totalData) * 100).toFixed(1);
                        // return `${label}: ${value.toLocaleString()}원 (${percentage}%)`;
                    }
                }
            },
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',
                    padding: 20,
                    color: '#000',
                    minWidth: 90,
                    font: {
                        size: 10,
                        weight: 'bold',
                        
                    },
                    boxWidth: 5,
                    boxHeight: 5,
                    filter: (item) => {
                        const maxWidth = 30;
                        const textWidth = item.text.length * 4;
                        item.text = textWidth > maxWidth ? item.text.substring(0, maxWidth / 6) + '...' : item.text;
                        return true;
                    }
                }
            },
            datalabels: {
                color: '#000',
                font: {
                    weight: 'bold',
                    size: 12
                },
                formatter: (value, context) => {
                    const percentage = ((value / totalData) * 100).toFixed(1);
                    return `${percentage}%`;
                },
                anchor: 'center',
                align: 'center',
                offset: 0
            }
        }
    };

    return (
        <div className={styles.campaignGauge}>
            <div className={styles.campaignGaugeTitle}>
                <p className={styles.campaignGaugeText1}>{dataName()}</p>
                <p className={styles.campaignGaugeText2}>
                    {totalData.toLocaleString()}{dataName() === "클릭률" ? "%" : (dataName() === "노출수" || dataName() === "클릭수" ? "회" : "원")}
                </p>
            </div>
            <Doughnut data={gaugeData} options={options} />
        </div>
    )
}