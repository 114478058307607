import React, { useState, useEffect, useContext } from 'react'

import styles from "../scss/keywordperformance.module.scss"
import {ReactComponent as ExcelDownloadNaver} from '../../../../../assets/images/report/report-excel-down-naver.svg'
import {ReactComponent as ExcelDownloadKakao} from '../../../../../assets/images/report/report-excel-down-kakao.svg'
import {ReactComponent as ExcelDownloadGoogle} from '../../../../../assets/images/report/report-excel-down-google.svg'

import { SummaryContext } from '../../../summary/SummaryProvider'

import { KeywordDetailAnalysisDataApi, KeywordDetailAnalysisTotalApi, KeywordDetailAnalysisDownloadApi } from '../../../../../api/report/keyword/KeywordDetailAnalysisApi'

import { ReportTable } from '../../../../utils/table/ReportTable'
import Loading from '../../../../utils/loading/Loading'

export const KeywordPerformance = ({mediaInfoId, adAccountsId, startDate, endDate, mediaName}) => {
    const [data, setData] = useState(null);
    const [total, setTotal] = useState(null);
    const [loading, setLoading] = useState(true);
    const pageSize = 10;
    const [row, setRow] = useState(null);
    const [orderBy, setOrderBy] = useState("tot_cost DESC");
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedButtons, setSelectedButtons] = useState({
        '클릭률': false,
        '클릭당 비용': false,
        '전환율': false,
        '광고수익률': false,
        '평균노출순위': false
    });
    const [prevData, setPrevData] = useState({ startDate: null, endDate: null, adAccountsId: null });
    const { setLowKeyword } = useContext(SummaryContext);

    const handleResponse = (response) => {
        if (response) {
            // console.log(response);
            const lowKeywords = response.data.slice(0, 3).map(item => item.keyword_name);
            setLowKeyword({avgRank: lowKeywords});
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const [detailAnalysisResponse] = await Promise.all([
                KeywordDetailAnalysisDataApi(mediaName, startDate, endDate, adAccountsId, pageSize, row, orderBy)
            ]);
            setData(detailAnalysisResponse);
            
            if (startDate !== prevData.startDate || endDate !== prevData.endDate || adAccountsId !== prevData.adAccountsId) {
                const totalDetailAnalysisResponse = await KeywordDetailAnalysisTotalApi(mediaName, startDate, endDate, adAccountsId);
                setTotal(totalDetailAnalysisResponse);
                setPrevData({ startDate, endDate, adAccountsId });
                // console.log(totalDetailAnalysisResponse);
            }
            
            setLoading(false);
            if (mediaInfoId === 5) {
                const avgRankData = await KeywordDetailAnalysisDataApi(mediaName, startDate, endDate, adAccountsId, pageSize, row, 'tot_avg_rank ASC');
                handleResponse(avgRankData);
            }
        };
        fetchData();
    }, [startDate, endDate, row, orderBy, adAccountsId]);

    if (loading) {
        return (
            <div className={styles.keywordPerformance}>
                <Loading />
            </div>
        );
    }

    if (!data) {
        return null;
    }

    const transformedData = data.data.map(item => ({
        "키워드": item.keyword_name,
        "PC/MO": item.device_type,
        "광고비": item.tot_cost,
        "노출수": item.tot_impression,
        "클릭수": item.tot_click,
        "클릭률": parseFloat(item.click_rate),
        "클릭당 비용": item.cost_click,
        "전환 매출액": parseFloat(item.tot_conversion_amount),
        "전환율": parseFloat(item.conversion_rate),
        "전환수": parseFloat(item.tot_conversion_count),
        "광고수익률": item.roas,
        "평균노출순위": item.tot_avg_rank
    }))

    const transformedTotalData = () => {
        return {
            "키워드": "총합",
            "PC/MO": "-",
            "광고비": total.data.tot_cost,
            "노출수": total.data.tot_impression,
            "클릭수": total.data.tot_click,
            "클릭률": parseFloat(total.data.click_rate),
            "클릭당 비용": total.data.cost_click,
            "전환 매출액": parseFloat(total.data.tot_conversion_amount),
            "전환율": parseFloat(total.data.conversion_rate),
            "전환수": parseFloat(total.data.tot_conversion_count),
            "광고수익률": total.data.roas,
            "평균노출순위": "-"
        };
    };

    const toggleButton = (buttonName) => {
        setSelectedButtons(prev => ({
            ...prev,
            [buttonName]: !prev[buttonName]
        }));
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRow((page - 1) * pageSize);
    };

    const getExcelDownloadIcon = () => {
        switch (mediaInfoId) {
            case 5:
                return <ExcelDownloadNaver />;
            case 89:
                return <ExcelDownloadKakao />;
            case 100:
                return <ExcelDownloadGoogle />;
            default:
                return ; // 기본값
        }
    };

    const handleDownload = async () => {
        let fields = [];

        for (const [key, value] of Object.entries(selectedButtons)) {
            if (value) {
                switch (key) {
                    case '클릭률':
                        fields.push('click_rate');
                        break;
                    case '클릭당 비용':
                        fields.push('cost_click');
                        break;
                    case '전환율':
                        fields.push('conversion_rate');
                        break;
                    case '광고수익률':
                        fields.push('roas');
                        break;
                    case '평균노출순위':
                        fields.push('tot_avg_rank');
                        break;
                    default:
                        break;
                }
            }
        }
    
        // 고정으로 전달할 필드 추가
        fields.push('tot_cost', 'tot_impression', 'tot_click', 'tot_conversion_amount', 'tot_conversion_count');
    
        try {
            const response = await KeywordDetailAnalysisDownloadApi(
                mediaName,
                startDate,
                endDate,
                adAccountsId,
                fields.join(','),
                orderBy,
                'blob'
            );

            if (!response.headers) {
                throw new Error("응답에 헤더가 포함되어 있지 않습니다.");
            }

            const disposition = response.headers['content-disposition'];
            let fileName = 'download.xlsx';

            if (disposition && disposition.includes('filename=')) {
                const fileNameMatch = disposition.match(/filename[^;=\n]*=(['"]?)([^'"]*)\1/);
                if (fileNameMatch != null && fileNameMatch.length > 2) {
                    fileName = `${decodeURIComponent(fileNameMatch[2])}.xlsx`;
                }
            }

            
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
    
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("다운로드 중 오류 발생:", error);
        }
    };

    return (
        <div className={styles.keywordPerformance}>
            <div className={styles.keywordPerformanceTitle}>키워드별 성과상세 분석</div>
            <div className={styles.dataSelectBox}>
                <div className={styles[`dataSelectButton${mediaInfoId}`]}>
                    {Object.keys(selectedButtons)
                        .filter(buttonName => !(mediaInfoId === 100 && buttonName === '평균노출순위'))
                        .map((buttonName) => (
                        <button
                            key={buttonName}
                            className={selectedButtons[buttonName] ? styles.active : ''}
                            onClick={() => toggleButton(buttonName)}
                        >
                            {buttonName}
                        </button>
                    ))}
                </div>
                <button className={styles.excelDownload} onClick={handleDownload}>
                    {getExcelDownloadIcon()}
                </button>
            </div>
            <ReportTable 
                defaultHeaders={['키워드','PC/MO','광고비','노출수','클릭수','전환 매출액','전환수']} 
                headers={selectedButtons} 
                items={transformedData} 
                totalItems={transformedTotalData()} 
                pageData={data.page}
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                mediaInfoId={mediaInfoId}
                setOrderBy={setOrderBy}
                defaultOrderBy={"tot_cost DESC"}
            />
        </div>
    )
}