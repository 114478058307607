import { useEffect } from "react";
import { useSelector } from "react-redux";

const pathTitles = {
    "home": "GA4데이터분석",
    "ga4Data": "GA4데이터분석",
    "advertisingData": "GA4&광고성과 데이터분석",
    "segment": "세그먼트 목록",
    "group_1": "세그먼트 리포트-자연검색을 통해서 유입된 사용자",
    "group_2": "세그먼트 리포트-광고를 통해서 유입된 사용자",
    "group_3": "세그먼트 리포트-장바구니 추가를 했지만 구매는 하지 않은 고객",
    "group_4": "세그먼트 리포트-주문서를 작성했지만 구매는 하지 않은 고객",
    "group_5": "세그먼트 리포트-직전 3일 동안 3번 이상 방문한 사용자",
    "group_6": "세그먼트 리포트-데스크톱으로 유입된 사용자",
    "group_7": "세그먼트 리포트-모바일로 유입된 사용자",
    "group_8": "세그먼트 리포트-구매를 완료한 사용자 그룹",
    "group_9": "세그먼트 리포트-구매를 완료하지 않은 사용자 그룹",
    "predmodel": "예측모델",
    "rfm": "RFM",
    "mypage": "마이페이지",
    "signup": "광고주 등록하기",
    "approve": "요청결과 조회하기"
};

const Title = () => {
    const path = useSelector((state) => state.path.path);

    useEffect(() => {
        if (path && pathTitles[path]) {
            document.title = pathTitles[path];
            // console.log(path)
            // console.log(pathTitles[path])
        } else {
            document.title = "애드포레스트"; // 기본 제목 설정
        }
    }, [path]);

    return null; // 컴포넌트는 아무것도 렌더링하지 않음
};

export default Title;
