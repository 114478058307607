import axios from "../axios"

export const FaceBookApi = async (code) => {
    try {
        const { data } = await axios.post('facebook/login/', 
            { code },
            { headers: { 'Content-Type': 'application/json' } }
        );
        return data; // 가져온 데이터 반환
    } catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error);
        return null;
    }
}

export const FaceBookAccountsApi = async (incrementId) => {
    try{
        const { data } = await axios.get('facebook/accounts/',{
            params: {
                incrementId: incrementId,
            },
        })
        return data;
    }catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error);
        return null;
    }
}