import React from "react";

import { ReactComponent as AdvCost } from "../../../../../assets/images/predict/pred-advcost-img.svg";


export const ReportPreviewAdvCost = () => {
    
    return (
        <>
            <AdvCost />
        </>
    );
};
