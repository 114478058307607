import React from "react";

import styles from "../reportdetail.module.scss";
import { ReactComponent as Keyword } from "../../../../assets/images/report/report-google-keyword.svg";
import { ReactComponent as Cost } from "../../../../assets/images/report/report-google-cost.svg";
import { ReactComponent as Click } from "../../../../assets/images/report/report-google-click.svg";

export const ReportPreviewGoogle = ({ currentIndex }) => {
    const images = [<Keyword />, <Click />, <Cost />];

    return (
        <>
            {images[currentIndex]}
        </>
    );
};
