import React from 'react';

import Header from '../../../shared/header/Header';
import Sidebar from '../../../shared/sidebar/Sidebar';
import { ReportDA } from '../../../components/report/da/ReportDA';

export const ReportNaverDA = () => {
    return (
        <>
            <Header />
            <Sidebar />
            <ReportDA media={"naver"}/>
        </>
    );
};

