import React, { useEffect } from "react";
import Select from "react-select";
import styles from "../signupCopy.module.scss";

const Sectors = ({ selectedCategory, setSelectedCategory, selectedSubcategory, setSelectedSubcategory }) => {
    const options = [
        { value: "건강/의료", label: "건강/의료" },
        { value: "건설/부동산", label: "건설/부동산" },
        { value: "건축/인테리어", label: "건축/인테리어" },
        { value: "게임", label: "게임" },
        { value: "결혼/출산/육아", label: "결혼/출산/육아" },
        { value: "관공서/단체", label: "관공서/단체" },
        { value: "교육/취업", label: "교육/취업" },
        { value: "금융", label: "금융" },
        { value: "화장품/미용", label: "화장품/미용" },
        { value: "생활용품", label: "생활용품" },
        { value: "레저스포츠/취미", label: "레저스포츠/취미" },
        { value: "비즈니스/전문서비스", label: "비즈니스/전문서비스" },
        { value: "식품/음료", label: "식품/음료" },
        { value: "유통", label: "유통" },
        { value: "자동차/수송", label: "자동차/수송" },
        { value: "엔터테인먼트", label: "엔터테인먼트" },
        { value: "여행/교통", label: "여행/교통" },
        { value: "의류/패션", label: "의류/패션" },
        { value: "전자/가전", label: "전자/가전" },
        { value: "산업기기", label: "산업기기" },
        { value: "주류/담배", label: "주류/담배" },
        { value: "ICT", label: "ICT" },
        { value: "꽃/이벤트", label: "꽃/이벤트" },
        { value: "인쇄/문구/사무기기", label: "인쇄/문구/사무기기" },
        { value: "기타", label: "기타" },
    ];

    const options2 = [
        { value: "병원/의원/의료기관", label: "병원/의원/의료기관", parent: "건강/의료" },
        { value: "의약품/의료기기", label: "의약품/의료기기", parent: "건강/의료" },
        { value: "건강보조식품", label: "건강보조식품", parent: "건강/의료" },
        { value: "건강정보/서비스", label: "건강정보/서비스", parent: "건강/의료" },
        { value: "건설/부동산", label: "건설/부동산", parent: "건설/부동산" },
        { value: "건축/토목/설계", label: "건축/토목/설계", parent: "건축/인테리어" },
        { value: "인테리어 자재/시공", label: "인테리어 자재/시공", parent: "건축/인테리어" },
        { value: "온라인게임", label: "온라인게임", parent: "게임" },
        { value: "모바일게임", label: "모바일게임", parent: "게임" },
        { value: "게임 기타", label: "게임 기타", parent: "게임" },
        { value: "결혼정보/중개", label: "결혼정보/중개", parent: "결혼/출산/육아" },
        { value: "웨딩상품/컨설팅", label: "웨딩상품/컨설팅", parent: "결혼/출산/육아" },
        { value: "출산/육아 서비스", label: "출산/육아 서비스", parent: "결혼/출산/육아" },
        { value: "출산/유아동 용품", label: "출산/유아동 용품", parent: "결혼/출산/육아" },
        { value: "관공서", label: "관공서", parent: "관공서/단체" },
        { value: "선거", label: "선거", parent: "관공서/단체" },
        { value: "비영리기관", label: "비영리기관", parent: "관공서/단체" },
        { value: "관공서/단체 기타", label: "관공서/단체 기타", parent: "관공서/단체" },
        { value: "학교/대학교", label: "학교/대학교", parent: "교육/취업" },
        { value: "어학/외국어", label: "어학/외국어", parent: "교육/취업" },
        { value: "유,초,중,고 교육", label: "유,초,중,고 교육", parent: "교육/취업" },
        { value: "강의/자격증/평생교육", label: "강의/자격증/평생교육", parent: "교육/취업" },
        { value: "출판/교재", label: "출판/교재", parent: "교육/취업" },
        { value: "유학/해외연수", label: "유학/해외연수", parent: "교육/취업" },
        { value: "취업/채용 정보", label: "취업/채용 정보", parent: "교육/취업" },
        { value: "교육/취업 기타", label: "교육/취업 기타", parent: "교육/취업" },
        { value: "보험", label: "보험", parent: "금융" },
        { value: "대출", label: "대출", parent: "금융" },
        { value: "은행", label: "은행", parent: "금융" },
        { value: "증권", label: "증권", parent: "금융" },
        { value: "신용카드", label: "신용카드", parent: "금융" },
        { value: "재테크정보", label: "재테크정보", parent: "금융" },
        { value: "금융 기타", label: "금융 기타", parent: "금융" },
        { value: "화장품/미용기기", label: "화장품/미용기기", parent: "화장품/미용" },
        { value: "미용케어/서비스", label: "미용케어/서비스", parent: "화장품/미용" },
        { value: "인테리어 소품", label: "인테리어 소품", parent: "생활용품" },
        { value: "가구/침구", label: "가구/침구", parent: "생활용품" },
        { value: "주방/생활용품", label: "주방/생활용품", parent: "생활용품" },
        { value: "스튜디오/사진관", label: "스튜디오/사진관", parent: "생활용품" },
        { value: "애완동물/동물병원", label: "애완동물/동물병원", parent: "생활용품" },
        { value: "생활용품 기타", label: "생활용품 기타", parent: "생활용품" },
        { value: "레저스포츠 정보/커뮤니티", label: "레저스포츠 정보/커뮤니티", parent: "레저스포츠/취미" },
        { value: "레저스포츠 용품", label: "레저스포츠 용품", parent: "레저스포츠/취미" },
        { value: "기타 취미정보/용품", label: "기타 취미정보/용품", parent: "레저스포츠/취미" },
        { value: "법률 서비스", label: "법률 서비스", parent: "비즈니스/전문서비스" },
        { value: "세무/회계 서비스", label: "세무/회계 서비스", parent: "비즈니스/전문서비스" },
        { value: "광고/홍보", label: "광고/홍보", parent: "비즈니스/전문서비스" },
        { value: "항공/운송/이사", label: "항공/운송/이사", parent: "비즈니스/전문서비스" },
        { value: "창업 정보/지원", label: "창업 정보/지원", parent: "비즈니스/전문서비스" },
        { value: "비즈니스/전문서비스 기타", label: "비즈니스/전문서비스 기타", parent: "비즈니스/전문서비스" },
        { value: "식품/음료 판매", label: "식품/음료 판매", parent: "식품/음료" },
        { value: "식자재/음식 배달", label: "식자재/음식 배달", parent: "식품/음료" },
        { value: "식당/외식프랜차이즈", label: "식당/외식프랜차이즈", parent: "식품/음료" },
        { value: "오픈마켓", label: "오픈마켓", parent: "유통" },
        { value: "온라인 쇼핑몰", label: "온라인 쇼핑몰", parent: "유통" },
        { value: "홈쇼핑", label: "홈쇼핑", parent: "유통" },
        { value: "백화점", label: "백화점", parent: "유통" },
        { value: "면세점", label: "면세점", parent: "유통" },
        { value: "할인점/슈퍼마켓", label: "할인점/슈퍼마켓", parent: "유통" },
        { value: "유통 기타", label: "유통 기타", parent: "유통" },
        { value: "자동차(신차,중고차) 판매/리스", label: "자동차(신차,중고차) 판매/리스", parent: "자동차/수송" },
        { value: "자동차 용품/서비스", label: "자동차 용품/서비스", parent: "자동차/수송" },
        { value: "렌터카/카쉐어링", label: "렌터카/카쉐어링", parent: "자동차/수송" },
        { value: "주유/정유", label: "주유/정유", parent: "자동차/수송" },
        { value: "자동차/수송 기타", label: "자동차/수송 기타", parent: "자동차/수송" },
        { value: "영화", label: "영화", parent: "엔터테인먼트" },
        { value: "공연/전시", label: "공연/전시", parent: "엔터테인먼트" },
        { value: "웹툰/서적", label: "웹툰/서적", parent: "엔터테인먼트" },
        { value: "연예/미디어", label: "연예/미디어", parent: "엔터테인먼트" },
        { value: "여행사", label: "여행사", parent: "여행/교통" },
        { value: "숙박시설", label: "숙박시설", parent: "여행/교통" },
        { value: "여행/교통 정보", label: "여행/교통 정보", parent: "여행/교통" },
        { value: "테마파크/놀이공원", label: "테마파크/놀이공원", parent: "여행/교통" },
        { value: "의류/패션잡화", label: "의류/패션잡화", parent: "의류/패션" },
        { value: "명품 브랜드", label: "명품 브랜드", parent: "의류/패션" },
        { value: "스포츠/아웃도어 웨어", label: "스포츠/아웃도어 웨어", parent: "의류/패션" },
        { value: "주방/생활 가전", label: "주방/생활 가전", parent: "전자/가전" },
        { value: "컴퓨터/주변기기", label: "컴퓨터/주변기기", parent: "전자/가전" },
        { value: "영상/음향 가전", label: "영상/음향 가전", parent: "전자/가전" },
        { value: "전자/가전 기타", label: "전자/가전 기타", parent: "전자/가전" },
        { value: "산업기기/부품", label: "산업기기/부품", parent: "산업기기" },
        { value: "주류", label: "주류", parent: "주류/담배" },
        { value: "담배", label: "담배", parent: "주류/담배" },
        { value: "통신망/방송", label: "통신망/방송", parent: "ICT" },
        { value: "ICT 기기/시스템", label: "ICT 기기/시스템", parent: "ICT" },
        { value: "웹/콘텐츠 제작", label: "웹/콘텐츠 제작", parent: "ICT" },
        { value: "꽃", label: "꽃", parent: "꽃/이벤트" },
        { value: "이벤트", label: "이벤트", parent: "꽃/이벤트" },
        { value: "청첩/판촉/인쇄제작", label: "청첩/판촉/인쇄제작", parent: "인쇄/문구/사무기기" },
        { value: "문구/사무용품", label: "문구/사무용품", parent: "인쇄/문구/사무기기" },
        { value: "사무기기", label: "사무기기", parent: "인쇄/문구/사무기기" },
        { value: "성인", label: "성인", parent: "기타" },
        { value: "분류외항목", label: "분류외항목", parent: "기타" },
        { value: "기타", label: "기타", parent: "기타" },
    ];

    useEffect(() => {
        setSelectedSubcategory(null);
    }, [selectedCategory, setSelectedSubcategory]);

    const filteredOptions2 = options2.filter(option => option.parent === selectedCategory?.value);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: '100%',
            height: '40px',
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontWeight: 600,
            color: "#8C8C8C",
            boxShadow: state.isFocused ? 'none' : provided.boxShadow,
            borderColor: state.isFocused ? "#BDC2C7" : "#BDC2C7",
            '&:hover': {
                borderColor: state.isFocused ? "#BDC2C7" : "#BDC2C7",
            }
        }),
        menu: (provided) => ({
            ...provided,
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontWeight: 600,
            color: "#8C8C8C",
            borderRadius: "5px"
        }),
        option: (provided, state) => ({
            ...provided,
            width: '95%',
            marginLeft: '3px',
            marginRight: '3px',
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontWeight: 600,
            backgroundColor: state.isSelected ? 'rgba(0, 160, 160, 0.2)' : 'white',
            color: state.isSelected ? '#00A0A0' : '#8C8C8C',
            '&:hover': {
                backgroundColor: 'rgba(0, 160, 160, 0.2)',
                color: '#00A0A0'
            }
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menuList: (provided) => ({
            ...provided,
            paddingRight: '5px',
            '::-webkit-scrollbar': {
                width: '5px',
                height: '5px',
                marginRight: '5px',
            },
            '::-webkit-scrollbar-thumb': {
                background: '#D9D9D9',
                borderRadius: '5px',
            },
            '::-webkit-scrollbar-thumb:hover': {
                background: '#8C8C8C',
            },
            '::-webkit-scrollbar-track': {
                background: '#FFF',
                paddingRight: '5px'
            },
        })
    };

    return (
        <div className={styles.advSelect}>
            <div style={{ width: '100%' }}>
                <Select
                    styles={customStyles}
                    value={selectedCategory}
                    onChange={setSelectedCategory}
                    options={options}
                    placeholder="업종"
                />
            </div>
            {/* <div style={{ width: '49%' }}>
                <Select
                    styles={customStyles}
                    value={selectedSubcategory}
                    onChange={setSelectedSubcategory}
                    options={filteredOptions2}
                    placeholder="업종 2"
                />
            </div> */}
        </div>
    );
}

export default Sectors;
