import React, { useEffect, useImperativeHandle, forwardRef } from "react";
import { KakaoKeywordLinkApi } from "../../../../../api/socialLogin/KakaoApi";

const { Kakao } = window;

//  그냥 여기서 Code를 가져올 수 있게 설정해야할 듯
const KakaoLink = forwardRef((props, ref) => {
    const CLIENT_ID = process.env.REACT_APP_KAKAO_RESTAPI_KEY;
    const REDIRECT_URL = process.env.REACT_APP_KAKAO_REDIRECT_URI
    const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/business/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}&response_type=code&scope=biz_account_email,keyword_management&&resource_ids=keyword:*`
    const triggerKakaoLogin = () => {
        window.location.href = KAKAO_AUTH_URL;
    };

    useImperativeHandle(ref, () => ({
        triggerKakaoLogin: triggerKakaoLogin,
    }));

});

export default KakaoLink;
