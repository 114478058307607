import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import styles from "./periodreport.module.scss"

import { WeeklyPeriod } from "./weekly/WeeklyPeriod";
import { MonthlyPeriod } from "./monthly/MonthlyPeriod";

import { selectStartDate, selectEndDate } from '../../../store/dateSlice';


const formatDate = (dateString) => {
    const date = new Date(dateString); // 문자열을 Date 객체로 변환
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`; // YYYYMMDD 형식으로 반환
};


export const PeriodReport = () => {
    const location = useLocation();
    const startDate = formatDate(useSelector(selectStartDate));
    const endDate = formatDate(useSelector(selectEndDate));
    const [selectedBtn, setSelectedBtn] = useState('weekly');
    const reportName = location.state?.reportName;
    const incrementId = location.state?.incrementId;
    const linkedData = location.state?.linkedData;

    // console.log(linkedData);

    const handleButtonClick = (button) => {
        setSelectedBtn(button);
    };

    return (
        <div className={styles.period}>
            <div className={styles.periodHeader}>
                <p>{reportName}</p>
                <div className={styles.periodBtn}>
                    <button 
                        onClick={() => handleButtonClick('weekly')} 
                        className={selectedBtn === 'weekly' ? styles.activeBtn : ''}>
                        주간 예측
                    </button>
                    <button 
                        onClick={() => handleButtonClick('monthly')} 
                        className={selectedBtn === 'monthly' ? styles.activeBtn : ''}>
                        월간 예측
                    </button>
                </div>
            </div>
            <div className={styles.periodBody}>
                {selectedBtn === 'weekly' && <WeeklyPeriod startDate={startDate} endDate={endDate} incrementId={incrementId} linkedData={linkedData} />}
                {selectedBtn === 'monthly' && <MonthlyPeriod startDate={startDate} endDate={endDate} incrementId={incrementId} linkedData={linkedData} />}
            </div>
        </div>
    )
}