import axios from "../../axios";

export const PeriodPredictApi = async ( incrementId, reportType ) => {
    const path = `prediction-model/pred/sales-trend/${incrementId}`

    try {
        const { data } = await axios.get(path, {
            params: {
                report_type: reportType
            }
        });
        return data;
    } catch (error) {
        console.error("예측모델 주간/월간 예측 결과 오류 발생:", error);
        throw error;
    }
};