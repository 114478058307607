import React from "react";

import { ReactComponent as LinkImg } from "../../../../assets/images/datalink/datalink-link.svg"

import styles from "../scss/modal/linksuccessmodal.module.scss"

export const LinkSuccessModal = ({ onClose }) => {

    return (
        <div className={styles.modalBackdrop}>
            <div className={styles.modalContent}>
                <LinkImg />
                <p>연결을 성공했어요.</p>
                <p>데이터 센터에서 확인해주세요!</p>
                <button onClick={onClose}>데이터센터에서 확인하기</button>
            </div>
        </div>
    );
};