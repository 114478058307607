import React from 'react';

import Header from '../../shared/header/Header';
import Sidebar from '../../shared/sidebar/Sidebar';
import { PredictReport } from '../../components/predict/PredictReport';

export const PredictPage = () => {
    return (
        <>
            <Header />
            <Sidebar />
            <PredictReport />
        </>
    );
};

