import React, { useState } from "react";
import Modal from "react-modal";
import styles from "../signupCopy.module.scss";
import { ReactComponent as AgreeBtn } from "../../../assets/images/signup/agreeBtn.svg";
import ModalContent from "./modal/ModalContent";

Modal.setAppElement("#root");

const AgreementForm = ({ checked, onChange }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");

    const handleTotalAgreeChange = () => {
        const isChecked = !checked.totalAgree;
        onChange({
            totalAgree: isChecked,
            termsAgree: isChecked,
            privacyAgree: isChecked,
            adsAgree: isChecked,
        });
    };

    const handleIndividualChange = (e) => {
        const name = e.currentTarget.getAttribute('data-name');
        const isChecked = !checked[name];
        onChange((prevState) => {
            const newState = { ...prevState, [name]: isChecked };
            newState.totalAgree = newState.termsAgree && newState.privacyAgree && newState.adsAgree;
            return newState;
        });
    };

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent("");
    };

    const handleAgree = () => {
        onChange((prevState) => {
            const newState = { ...prevState };
            if (modalContent === "이용약관 동의") {
                newState.termsAgree = true;
            } else if (modalContent === "개인정보 수집 및 이용 동의") {
                newState.privacyAgree = true;
            } else if (modalContent === "광고성 정보 수신 동의") {
                newState.adsAgree = true;
            }
            newState.totalAgree = newState.termsAgree && newState.privacyAgree && newState.adsAgree;
            return newState;
        });
        closeModal();
    };

    return (
        <div className={styles.agreeBox}>
            <div className={styles.totalAgree}>
                <div
                    className={`${styles['custom-checkbox']} ${checked.totalAgree ? styles.checked : ''}`}
                    onClick={handleTotalAgreeChange}
                />
                <label>전체 동의</label>
            </div>
            <div className={styles.termsBox}>
                <div className={styles.termsDiv}>
                    <div
                        className={`${styles['custom-checkbox']} ${checked.termsAgree ? styles.checked : ''}`}
                        onClick={handleIndividualChange}
                        data-name="termsAgree"
                    />
                    <p style={{ color: "#E52626" }}>(필수)</p>
                    <label>이용약관 동의</label>
                    <button onClick={() => openModal("이용약관 동의")}>
                        <AgreeBtn />
                    </button>
                </div>
                <div className={styles.termsDiv}>
                    <div
                        className={`${styles['custom-checkbox']} ${checked.privacyAgree ? styles.checked : ''}`}
                        onClick={handleIndividualChange}
                        data-name="privacyAgree"
                    />
                    <p style={{ color: "#E52626" }}>(필수)</p>
                    <label>개인정보 수집 및 이용 동의</label>
                    <button onClick={() => openModal("개인정보 수집 및 이용 동의")}>
                        <AgreeBtn />
                    </button>
                </div>
                <div className={styles.termsDiv}>
                    <div
                        className={`${styles['custom-checkbox']} ${checked.adsAgree ? styles.checked : ''}`}
                        onClick={handleIndividualChange}
                        data-name="adsAgree"
                    />
                    <p>(선택)</p>
                    <label>광고성 정보 수신 동의</label>
                    <button onClick={() => openModal("광고성 정보 수신 동의")}>
                        <AgreeBtn />
                    </button>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Agreement Modal"
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <div className={styles.leftBox}></div>
                <div className={styles.modalBox}>
                    <h2>{modalContent}</h2>
                    <div className={styles.modalContent}>
                        <ModalContent ModalTitle={modalContent} />
                    </div>
                    <div className={styles.modalBtn}>
                        <button onClick={closeModal}>취소</button>
                        <button onClick={handleAgree}>동의</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AgreementForm;
