import React, { useState, useEffect } from "react";

import styles from "../scss/daavgchart.module.scss";
import { ReactComponent as NaverLabels } from "../../../../../assets/images/report/report-da-naver-labels.svg";
import { ReactComponent as KakaoLabels } from "../../../../../assets/images/report/report-da-kakao-labels.svg";
import { ReactComponent as GoogleLabels } from "../../../../../assets/images/report/report-da-google-labels.svg";

import { DaCostAvgChartApi } from "../../../../../api/reportDA/cost/DaCostAvgChartApi";

import { DaBarLine } from "../../../../utils/chart/DaBarLine";
import Loading from "../../../../utils/loading/Loading";
import { NullBox } from "../../../../utils/nullBox/NullBox";

export const DaAvgChart = ({
    mediaInfoId,
    adAccountsId,
    startDate,
    endDate,
    mediaName,
}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await DaCostAvgChartApi(
                mediaName,
                startDate,
                endDate,
                adAccountsId
            );
            setData(response);
            setLoading(false);
            // console.log(response);
        };
        fetchData();
    }, [startDate, endDate, adAccountsId]);

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    const isDataEmpty = (data) => {
        return data.every(
            (item) =>
                parseFloat(item.tot_conversion_amount) === 0 &&
                item.tot_cost === 0 &&
                item.roas === 0
        );
    };

    return (
        <div className={styles.daAvg}>
            <div className={styles.daAvgTitle}>
                요일별 평균 매출 및 평균 광고 비용
            </div>
            <div className={styles.chartLables}>
                {mediaInfoId === 6 && <NaverLabels />}
                {mediaInfoId === 7 && <KakaoLabels />}
                {mediaInfoId === 100 && <GoogleLabels />}
            </div>
            <div className={styles.daAvgChart}>
                {isDataEmpty(data.data) ? (
                    <NullBox />
                ) : (
                    <DaBarLine data={data} mediaInfoId={mediaInfoId} />
                )}
            </div>
        </div>
    );
};
