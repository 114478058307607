import React from "react";

import styles from "../scss/resultweekly.module.scss"
import { ReactComponent as Labels } from "../../../../../assets/images/predict/pred-singlebarline-labels.svg"

import { PredSingleBarLine } from "../../../../utils/chart/PredChart";

export const ResultBarLine = ({ data }) => {
    const totalData = data.data.flatMap(item => 
        item.data.filter(innerItem => innerItem.media === "total")
    );
    
    return (
        <div className={styles.resultBarLine}>
            <div className={styles.barLineBorder}></div>
            <Labels />
            <PredSingleBarLine data={data} totalData={totalData} width="550px" height="270px" />
        </div>
    )
}