import axios from "../axios";

export const FaceBookRedirectApi = async (payload) => {
    try {
        const { data } = await axios.post(
            'facebook/datalink/',
            payload,
            { headers: { 'Content-Type': 'application/json' } }
        );
        return data;
    } catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error);
        return null;
    }
}
