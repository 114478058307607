import React, { useState, useEffect } from 'react';

import styles from "../scss/datrendchart.module.scss"
import { ReactComponent as KakaoLabels } from "../../../../../assets/images/report/report-trend-kakao-labels.svg"
import { ReactComponent as NaverLabels } from "../../../../../assets/images/report/report-trend-naver-labels.svg"
import { ReactComponent as GoogleLabels } from "../../../../../assets/images/report/report-trend-google-da-labels.svg"

import { DaCostTrendChartApi } from '../../../../../api/reportDA/cost/DaCostTrendChartApi';

import { BarLine } from "../../../../utils/chart/BarLine";
import Loading from '../../../../utils/loading/Loading';
import { NullBox } from '../../../../utils/nullBox/NullBox';


export const DaTrendChart = ({
        mediaInfoId,
        adAccountsId, 
        startDate, 
        endDate,
        selectedValue,
        setSelectedValue,
        reportType,
        mediaName
    }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    // const [selectedValue, setSelectedValue] = useState("day"); // 선택된 값 상태 추가


    const labels = () => {
        if (mediaInfoId === 6) {
            return <NaverLabels />
        } else if (mediaInfoId === 7) {
            return <KakaoLabels />
        } else if (mediaInfoId === 100) {
            return <GoogleLabels />
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await DaCostTrendChartApi(mediaName, startDate, endDate, adAccountsId, selectedValue); // 선택된 값 전달
            setData(response);
            setLoading(false);
            // console.log(response)
        };
        fetchData();
    }, [startDate, endDate, selectedValue, adAccountsId])

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    const isDataEmpty = (data) => {
        return data.every(item => 
            parseFloat(item.tot_conversion_amount) === 0 &&
            item.tot_cost === 0
        );
    };

                
    return (
        <div className={styles.daTrendChart}>
            <div className={styles.daTrendChartTitle}>전환 추세</div>
            <div className={styles.daTrendChartSelect}>
                <div style={{height: "17px"}}>{labels()}</div>
                <select className={styles[`selectBox${mediaInfoId}`]} value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)}>
                    <option value="day">일자별</option>
                    <option value="week">주별</option>
                    <option value="month">월별</option>
                </select>
            </div>
            <div className={styles.daTrendChartChart}>
                {isDataEmpty(data.data) ? (
                    <NullBox />
                ) : (
                    <BarLine data={data} lineLabel="전환 매출액" barLabel="광고비" mediaInfoId={mediaInfoId} reportType={reportType}/>
                )}
            </div>
        </div>
    )
}
