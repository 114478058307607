import React, { useState, useEffect, useContext } from "react";

import styles from "../scss/dacreativetop.module.scss"
import { ReactComponent as UpSort } from "../../../../../assets/images/report/report-da-creative-top-upsort.svg"
import { ReactComponent as DownSort } from "../../../../../assets/images/report/report-da-creative-top-down.svg"

import { SummaryContext } from "../../../summary/DaSummaryProvider";

import { DaCreativeTopApi } from "../../../../../api/reportDA/creative/DaCreativeTopApi";

import Loading from "../../../../utils/loading/Loading";

export const DaCreativeTop = ({
    mediaInfoId,
    startDate,
    endDate,
    adAccountsId,
    mediaName
}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState("tot_conversion_amount DESC");
    const { setSubject, setHighSub } = useContext(SummaryContext);
 

    const headers = ["광고 소재명", "광고 그룹명", "노출수", "클릭수", "클릭률", "전환 매출액", "이전 기간 매출 비교"]

    const handleResponse = (response) => {
        const avgImp = response.data.reduce((acc, item) => acc + item.tot_impression, 0) / response.data.length;
        const avgClick = response.data.reduce((acc, item) => acc + item.tot_click, 0) / response.data.length;
        const avgAmount = response.data.reduce((acc, item) => acc + parseFloat(item.tot_conversion_amount), 0) / response.data.length;

        const maxVarianceItem = response.data.reduce((maxItem, item) => {
            return (item.tot_conversion_amount_variance > (maxItem.tot_conversion_amount_variance || 0)) ? item : maxItem;
        }, {});

        const maxRoas = response.data.reduce((maxItem, item) => {
            return (item.roas > (maxItem.roas || 0)) ? item : maxItem
        })

        setHighSub({
            impression: Math.round(avgImp).toLocaleString(),  
            click: Math.round(avgClick).toLocaleString(), 
            amount: Math.round(avgAmount).toLocaleString(), 
            creative: mediaInfoId === 100 ? maxVarianceItem.ad_name : maxVarianceItem.creative_name,
            prevRoas: maxVarianceItem.tot_conversion_amount_variance, 
            upDown: maxVarianceItem.tot_conversion_amount_variance_operator
        });
        
        setSubject({
            roas: maxRoas.roas,
            creative: mediaInfoId === 100 ?  maxRoas.ad_name : maxRoas.creative_name,
            prevRoas: maxRoas.tot_conversion_amount_variance,
            upDown: maxRoas.tot_conversion_amount_variance_operator
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await DaCreativeTopApi(mediaName, startDate, endDate, adAccountsId, orderBy);
            setData(response);
            setLoading(false);
            handleResponse(response);
            // console.log(response);
        };
        fetchData();
    }, [startDate, endDate, orderBy, adAccountsId])

    if (loading) {
        return (
            <div className={styles.daCreativeTop}>
                {/* <Loading /> */}
            </div>
        );
    }

    if (!data) {
        return null;
    }

    // console.log(data.data.map(item => item.creative_url_name.split(",")[0]));

    const transformedData = data.data.map(item => {
        let urlName = "";
        let creativeName = "";
        switch (mediaInfoId) {
            case 100:
                if (item.ad_type_name === "VIDEO_RESPONSIVE_AD") {
                    urlName = `https://img.youtube.com/vi/${item.creative_url_name}/maxresdefault.jpg`
                } else if (item.ad_type_name === "IMAGE_AD") {
                    urlName = `https://tpc.googlesyndication.com/simgad/${item.creative_url_name}`
                } else if (item.ad_type_name === "RESPONSIVE_DISPLAY_AD") {
                    urlName = `https://tpc.googlesyndication.com/simgad/${item.creative_url_name.split(",")[0]}`
                }
                creativeName = item.ad_name
                break;
            default:
                urlName = item.creative_url_name
                creativeName = item.creative_name
        }
        return {
            "광고 소재명": creativeName,
            "광고 그룹명": item.ad_group_name,
            "노출수": item.tot_impression.toLocaleString(),
            "클릭수": item.tot_click.toLocaleString(),
            "클릭률": item.click_rate,
            "전환 매출액": parseFloat(item.tot_conversion_amount).toLocaleString(),
            "이전 기간 매출 비교": item.tot_conversion_amount_variance,
            "증감률": item.tot_conversion_amount_variance_operator,
            creative_url_name: urlName
        };
    })

    // console.log(transformedData);

    return (
        <div className={styles.daCreativeTop}>
            <p className={styles.daCreativeTopTitle}>광고 소재 성과 TOP3</p>
            <div className={styles.daCreativeTopSelect}>
                <select className={styles[`selectBox${mediaInfoId}`]} onChange={(e) => setOrderBy(e.target.value)}>
                    <option key="전환 매출액" value="tot_conversion_amount DESC">
                        전환 매출액
                    </option>
                    <option key="클릭수" value="tot_click DESC">
                        클릭수
                    </option>
                    <option key="클릭률" value="click_rate DESC">
                        클릭률
                    </option>
                </select>
            </div>
            <div className={styles.daCreativeBox}>
                {transformedData.map((item, index) => {
                    return (
                        <div key={`creative-${index}`} className={styles.daCreativeContainer}>
                            <div className={styles.imgBox}>
                                <img
                                    src={item.creative_url_name}
                                    alt={`Creative ${index}`}
                                    style={{ width: "273px", height: "165px" }}
                                />
                            </div>
                            <div className={styles.daCreativeTable}>
                                {headers.map((header, headerIndex) => {
                                    return (
                                        <div
                                            key={`row-${index}-${headerIndex}`} // 고유 key 추가
                                            className={styles.tableRow}
                                        >
                                            <div className={styles.tableHeader}>
                                                <p>{header}</p>
                                            </div>
                                            <div className={styles.tableBody}>
                                                <p>
                                                    {{
                                                        "노출수": item[header],
                                                        "클릭수": item[header],
                                                        "전환 매출액": item[header],
                                                        "클릭률": `${item[header]}%`,
                                                    }[header] || item[header]}
                                                    {header === "이전 기간 매출 비교" && (
                                                        <>
                                                            {item["증감률"] === "up" ? (
                                                                <>% <UpSort /></>
                                                            ) : (
                                                                <>% <DownSort /></>
                                                            )}
                                                        </>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );    
}