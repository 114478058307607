import React from "react";

import Header from '../../../shared/header/Header';
import Sidebar from '../../../shared/sidebar/Sidebar';

export const ReportFacebookPage = () => {
    return (
        <>
            <Header />
            <Sidebar />
            <h1>ReportFacebookPage</h1>
        </>
    );
};

