import React, { useState, useEffect } from "react";

import styles from "../scss/dafunnelreport.module.scss"

import { DaCostFunnelApi } from "../../../../../api/reportDA/cost/DaCostFunnelApi";

import Loading from "../../../../utils/loading/Loading";
import { InfoIcon } from "../../../../utils/info/InfoIcon";


export const DaFunnelReport = ({
        adAccountsId, 
        startDate,
        endDate,
        mediaName
    }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await DaCostFunnelApi(mediaName, startDate, endDate, adAccountsId);
            setData(response);
            setLoading(false);
            // console.log(response)
        };
        fetchData();
    }, [startDate, endDate, adAccountsId])

    if (loading) {
        return (
            <div className={styles.daFunnel}>
                <Loading />
            </div>
        );
    }

    if (!data) {
        return null;
    }
    
    // const testData = {
    //     "data": {
    //         "tot_click": 1407,
    //         "tot_click_rate": 100,
    //         "tot_click_variance_operator": "down",
    //         "tot_click_variance": 84.77,
    //         "tot_conversion_count": 194,
    //         "tot_conversion_count_rate": 0,
    //         "tot_conversion_count_variance_operator": "up",
    //         "tot_conversion_count_variance": 0,
    //         "tot_cart_count": 1023,
    //         "tot_cart_count_rate": 0,
    //         "tot_cart_count_variance_operator": "up",
    //         "tot_cart_count_variance": 0
    //     }
    // };

    const clickPercentage = (data.data.tot_click / data.data.tot_click) * 100;
    const cartCountPercentage = (data.data.tot_cart_count / data.data.tot_click) * 100;
    const conversionCountPercentage = (data.data.tot_conversion_count / data.data.tot_click) * 100;
    
    const secondStepHeight = `calc(245px * (${cartCountPercentage} / 100))`;
    const thirdStepHeight = `calc(${secondStepHeight} * (${conversionCountPercentage} / 100))`;

    const message = () => {
        return (
            <>
            사이트 방문에서부터 구매 완료까지의 사용자의 전환 과정을 단계별로 보여줍니다.
            </>
        )
    }


    return (
        <div className={styles.daFunnel}>
            <div className={styles.daFunnelTitle}>
                <p>퍼널 리포트</p>
                <InfoIcon message={message()} className="infoTxtBox" />
            </div>
            <div className={styles.scoreCardBox}>
                <div className={styles.funnelScoreCard}>
                    <p className={styles.cardName}>1. 방문수</p>
                    <p className={styles.cardPer}>{clickPercentage.toFixed(2)}%</p>
                    <p className={styles.cardData}>({data.data.tot_click.toLocaleString()})</p>
                </div>
                <div className={styles.funnelScoreCard}>
                    <p className={styles.cardName}>2. 장바구니 추가 수</p>
                    <p className={styles.cardPer}>{cartCountPercentage.toFixed(2)}%</p>
                    <p className={styles.cardData}>({data.data.tot_cart_count.toLocaleString()})</p>
                </div>
                <div className={styles.funnelScoreCard}>
                    <p className={styles.cardName}>3. 구매 완료 수</p>
                    <p className={styles.cardPer}>{conversionCountPercentage.toFixed(2)}%</p>
                    <p className={styles.cardData}>({data.data.tot_conversion_count.toLocaleString()})</p>
                </div>
            </div>
            <div className={styles.funnelChart}>
                <div className={styles.funnelTicks}>
                    <p>100%</p><p>75%</p><p>50%</p><p>25%</p><p>0%</p>
                </div>
                <div className={styles.funnelBox}>
                    <div className={styles.firstStep} style={{clipPath: `polygon(0 0, 100% calc(245px * (${1 - (cartCountPercentage / 100)})), 100% 100% , 0 100%`}}></div>
                    <div className={styles.secondStep} 
                    style={{
                        height: `calc(245px * (${cartCountPercentage} / 100))`, 
                        clipPath: `polygon(0 0, 100% calc(${secondStepHeight} * (${1 - (conversionCountPercentage / 100)})), 100% 100%, 0 100%)`
                      }}
                    >
                    </div>
                    <div className={styles.thirdStep} style={{height: `${thirdStepHeight}`}}></div>
                </div>
            </div>
            <div className={styles.funnelTable}>
                <div className={styles.tableCol}>
                    <div className={styles.tableHeader}>1. 방문수</div>
                    <div className={styles.tableMiddle}>
                        <p>{data.data.tot_click.toLocaleString()}</p>
                        <p>{data.data.tot_click_variance}%</p>
                    </div>
                    <div className={styles.tableBottom}>
                        {data.data.tot_click_variance}%
                        <span style={{ color: data.data.tot_click_variance_operator === "up" ? "blue" : "red" }}>
                            {data.data.tot_click_variance_operator === "up" ? "▲" : "▼" }
                        </span>
                    </div>
                </div>
                <div className={styles.tableCol}>
                    <div className={styles.tableHeader}>2. 장바구니 추가 수</div>
                    <div className={styles.tableMiddle}>
                        <p>{data.data.tot_cart_count.toLocaleString()}</p>
                        <p>{data.data.tot_cart_count_variance}%</p>
                    </div>
                    <div className={styles.tableBottom}>
                        {data.data.tot_cart_count_variance}%
                        <span style={{ color: data.data.tot_cart_count_variance_operator === "up" ? "blue" : "red" }}>
                            {data.data.tot_cart_count_variance_operator === "up" ? "▲" : "▼" }
                        </span>
                    </div>
                </div>
                <div className={styles.tableCol}>
                    <div className={styles.tableHeader}>3. 구매 완료</div>
                    <div className={styles.tableMiddle}>
                        <p>{data.data.tot_conversion_count.toLocaleString()}</p>
                        <p>{data.data.tot_conversion_count_variance}%</p>
                    </div>
                    <div className={styles.tableBottom}>
                        {data.data.tot_conversion_count_variance}%
                        <span style={{ color: data.data.tot_conversion_count_variance_operator === "up" ? "blue" : "red" }}>
                            {data.data.tot_conversion_count_variance_operator === "up" ? "▲" : "▼" }
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}