import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "../../scss/social/ga4trdstep.module.scss"
import { ReactComponent as ModalClose } from "../../../../../assets/images/share/modal-close.svg";
import { ReactComponent as Graph } from "../../../../../assets/images/datalink/datalink-ga4-success.svg"

export const Ga4RequestResult = ({closeModal, handleResultOff}) => {
    const navigate = useNavigate();

    return (
        <div className={styles.modalBackdrop}>
            <div className={styles.modalContent}>
                <div className={styles.modalCloseBtn}>
                    <div onClick={closeModal} style={{ cursor: "pointer" }}>
                        <ModalClose />
                    </div>
                </div>
                <div className={styles.modalText}>
                    <p className={styles.text1}>요청 결과 조회하기</p>
                </div>
                <>
                    <div className={styles.successTxtBox}>
                        <p className={styles.successTxt1}>요청 처리 성공</p>
                        <Graph />
                        <p className={styles.successTxt2}>요청이 승인되었습니다.</p>
                        <p className={styles.successTxt2}>승인 내역은 아래의 메일로 전달드렸으니,</p>
                        <p className={styles.successTxt2}>메일을 확인해주세요.</p>
                    </div>
                </>
                <div className={styles.requestInfo}>
                    <p className={styles.requestTxt1}>요청 정보</p>
                    <div className={styles.requestData}>
                        데이터 이름
                        <p className={styles.requestTxt2}>Demo Account</p>
                    </div>
                </div>
                <div className={styles.requestInfo}>
                    <p className={styles.requestTxt1}>메일</p>
                    <div className={styles.requestData}>
                        abcd@triplehm.com
                    </div>
                </div>
                <div className={styles.btnBox}>
                    <button onClick={handleResultOff} >요청 내역으로 돌아가기</button>
                </div>
            </div>
        </div>
    );
} 