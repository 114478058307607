import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import styles from "./pwchangebox.module.scss"
import { ReactComponent as NoShowPwMark } from "../../assets/images/signup/noShowPwMark.svg";
import { ReactComponent as ShowPwMark } from "../../assets/images/signup/showPwMark.svg";

import { PwchangeApi } from "../../api/pwchange/PwchangeApi";

export const PwchangeBox = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [status, setStatus] = useState(false);
    const location = useLocation();
    const username = location.state?.username;
    const [showPw1, setShowPw1] = useState({
        type: "password",
        visible: false,
    });
    const [showPw2, setShowPw2] = useState({
        type: "password",
        visible: false,
    });

    const handleShowPw1 = (e) => {
        setShowPw1((prevState) => ({
            type: prevState.visible ? "password" : "text",
            visible: !prevState.visible,
        }));
    };

    const handleShowPw2 = (e) => {
        setShowPw2((prevState) => ({
            type: prevState.visible ? "password" : "text",
            visible: !prevState.visible,
        }));
    };

    const handleChangePassword = async () => {
        try {
            const response = await PwchangeApi(newPassword, confirmPassword, username);
            // console.log(response)
            setStatus(true)
        } catch (error) {
            if (error.response) {
                console.log(error.response.data.error);
            } else {
                console.log('비밀번호 변경 중 오류가 발생했습니다.');
            }
        }
    };

    return(
        <div className={styles.box}>
            {status ? (
                <>
                    <p className={styles.pwOkTxt1}>비밀번호 재설정 완료</p>
                    <p className={styles.pwOkTxt2}>비밀번호 변경이 완료되었습니다.</p>
                    <p className={styles.pwOkTxt3}>새로운 비밀번호로 로그인해주세요.</p>
                    <div className={styles.pwOkBtnBox}>
                    <Link to="/"><button>로그인 화면으로</button></Link>
                    </div>
                </>
            ):(
                <>
                <p className={styles.pwchangeTxt1}>비밀번호 재설정하기</p>
                <p className={styles.pwchangeTxt2}>새로운 비밀번호를 입력해주세요.</p>
                <div className={styles.pwBox}>
                    <div className={styles.pwInputBox}>
                        <div className={styles.pwInput}>
                            <input
                                type={showPw1.type}
                                placeholder="비밀번호를 입력해주세요."
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </div>
                        <div
                            className={styles.pwMark}
                            onClick={handleShowPw1}
                        >
                            {showPw1.visible ? (
                                <ShowPwMark />
                            ) : (
                                <NoShowPwMark />
                            )}
                        </div>
                    </div>
                    <div className={styles.pwCheck}>
                        <span>&#10004; 영문, 숫자, 특수 기호 포함</span>
                        <span>&#10004; 8자 이상</span>
                    </div>
                
                    <div className={styles.pwInputBox}>
                        <div className={styles.pwInput}>
                            <input
                                type={showPw2.type}
                                placeholder="비밀번호를 한번 더 입력해주세요."
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                        <div
                            className={styles.pwMark}
                            onClick={handleShowPw2}
                        >
                            {showPw2.visible ? (
                                <ShowPwMark />
                            ) : (
                                <NoShowPwMark />
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.btnBox}>
                    <button onClick={handleChangePassword}>재설정하기</button>
                </div>
                </>
            )}
        </div>
    );
};