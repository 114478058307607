import React, { useState, useEffect, useContext } from 'react'

import styles from '../scss/datrendtable.module.scss'
import {ReactComponent as ExcelDownloadNaver} from '../../../../../assets/images/report/report-excel-down-naver.svg'
import {ReactComponent as ExcelDownloadKakao} from '../../../../../assets/images/report/report-excel-down-kakao.svg'
import {ReactComponent as ExcelDownloadGoogle} from '../../../../../assets/images/report/report-excel-down-google-da.svg'

import { SummaryContext } from '../../../summary/DaSummaryProvider'

import { DaCostTrendTableApi, DaCostTrendTableTotalApi, DaCostTrendTableDownloadApi } from '../../../../../api/reportDA/cost/DaCostTrendTableApi'

import { ReportTable } from '../../../../utils/table/ReportTable'
import Loading from '../../../../utils/loading/Loading'

export const DaTrendTable = ({
        mediaInfoId, 
        adAccountsId, 
        startDate, 
        endDate,
        selectedValue,
        reportType,
        setResultData,
        mediaName
    }) => {
    const [data, setData] = useState(null);
    const [total, setTotal] = useState(null);
    const [loading, setLoading] = useState(true);
    const pageSize = 10;
    const [row, setRow] = useState(null);
    const [orderBy, setOrderBy] = useState("ad_date ASC");
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedButtons, setSelectedButtons] = useState({
        '클릭률': false,
        '클릭당 비용': false,
        '전환율': false,
        '전환당 비용': false
    });
    const [prevData, setPrevData] = useState({ startDate: null, endDate: null, adAccountsID: null });
    const { setConversion, setConvAmount } = useContext(SummaryContext)

    const handleResponse = (response) => {
        // console.log(response)
        setConversion(parseInt(response.data.tot_conversion_count, 10).toLocaleString());
        setConvAmount(parseInt(response.data.tot_conversion_amount, 10).toLocaleString());
    }

    useEffect(() => {
        const fetchData = async () => {
            const [dayPerformanceResponse] = await Promise.all([
                DaCostTrendTableApi(mediaName, startDate, endDate, adAccountsId, pageSize, row, orderBy, selectedValue)
            ]);
            setData(dayPerformanceResponse);
            // console.log(dayPerformanceResponse)
            
            if (startDate !== prevData.startDate || endDate !== prevData.endDate || adAccountsId !== prevData.adAccountsID) {
                const totalPerformanceResponse = await DaCostTrendTableTotalApi(mediaName, startDate, endDate, adAccountsId, selectedValue);
                setTotal(totalPerformanceResponse);
                setPrevData({ startDate, endDate, adAccountsId });
                handleResponse(totalPerformanceResponse);
                setResultData({conversion: parseInt(totalPerformanceResponse.data.tot_conversion_count, 10), conversionAmount: parseInt(totalPerformanceResponse.data.tot_conversion_amount, 10) })
                // console.log(totalPerformanceResponse)
            }
            
            setLoading(false);
        };
        fetchData();
    }, [startDate, endDate, row, orderBy, adAccountsId]);

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    const transformedData = data.data.map(item => ({
        "일자": item.ad_date_dayweek,
        "광고비": item.tot_cost,
        "노출수": item.tot_impression,
        "클릭수": item.tot_click,
        "클릭률": item.click_rate,
        "클릭당 비용": item.cost_click,
        "전환 매출액": parseInt(item.tot_conversion_amount, 10),
        "전환율": item.conversion_rate,
        "전환수": parseInt(item.tot_conversion_count, 10),
        "전환당 비용": parseInt(item.conversion_cost, 10),
        "광고수익률": item.roas,
    }))

    const transformedTotalData = () => {
        return {
            "일자": "총합",
            "광고비": total.data.tot_cost,
            "노출수": total.data.tot_impression,
            "클릭수": total.data.tot_click,
            "클릭률": total.data.click_rate,
            "클릭당 비용": total.data.cost_click,
            "전환 매출액": parseInt(total.data.tot_conversion_amount, 10),
            "전환율": total.data.conversion_rate,
            "전환수": parseInt(total.data.tot_conversion_count, 10),
            "전환당 비용": parseInt(total.data.conversion_cost),
            "광고수익률": total.data.roas,
        };
    };

    const toggleButton = (buttonName) => {
        setSelectedButtons(prev => ({
            ...prev,
            [buttonName]: !prev[buttonName]
        }));
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRow((page - 1) * pageSize);
    };

    const getExcelDownloadIcon = () => {
        switch (mediaInfoId) {
            case 5:
                return <ExcelDownloadNaver />;
            case 89:
                return <ExcelDownloadKakao />;
            case 100:
                return <ExcelDownloadGoogle />;
            case 6:
                return <ExcelDownloadNaver />;
            case 7:
                return <ExcelDownloadKakao />;
            default:
                return ; // 기본값
        }
    };
    
    const handleDownload = async () => {
        let fields = [];
    
        for (const [key, value] of Object.entries(selectedButtons)) {
            if (value) {
                switch (key) {
                    case '클릭률':
                        fields.push('click_rate');
                        break;
                    case '클릭당 비용':
                        fields.push('cost_click');
                        break;
                    case '전환율':
                        fields.push('conversion_rate');
                        break;
                    default:
                        break;
                }
            }
        }
    
        // 고정으로 전달할 필드 추가
        fields.push('tot_cost', 'tot_impression', 'tot_click', 'tot_conversion_count', 'tot_conversion_amount', 'roas');
    
        try {
            const response = await DaCostTrendTableDownloadApi(
                mediaName,
                startDate,
                endDate,
                adAccountsId,
                fields.join(','),
                orderBy,
                'blob',
                "day"
            );

            if (!response.headers) {
                throw new Error("응답에 헤더가 포함되어 있지 않습니다.");
            }

            const disposition = response.headers['content-disposition'];
            let fileName = 'download.xlsx';

            if (disposition && disposition.includes('filename=')) {
                const fileNameMatch = disposition.match(/filename[^;=\n]*=(['"]?)([^'"]*)\1/);
                if (fileNameMatch != null && fileNameMatch.length > 2) {
                    fileName = `${decodeURIComponent(fileNameMatch[2])}.xlsx`;
                }
            }

            
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
    
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("다운로드 중 오류 발생:", error);
        }
    };

    return (
        <div className={styles.daTrendTable}>
            <div className={styles.dataSelectBox}>
                <div className={styles[`dataSelectButton${mediaInfoId}`]}>
                    {Object.keys(selectedButtons).map((buttonName) => (
                        <button
                            key={buttonName}
                            className={selectedButtons[buttonName] ? styles.active : ''}
                            onClick={() => toggleButton(buttonName)}
                        >
                            {buttonName}
                        </button>
                    ))}
                </div>
                <button className={styles.excelDownload} onClick={handleDownload}>
                    {getExcelDownloadIcon()}
                </button>
            </div>
            <ReportTable 
                defaultHeaders={['일자','광고비','노출수','클릭수', '전환 매출액', '전환수', '광고수익률']} 
                headers={selectedButtons} 
                items={transformedData} 
                totalItems={transformedTotalData()} 
                pageData={data.page}
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                mediaInfoId={mediaInfoId}
                setOrderBy={setOrderBy}
                defaultOrderBy={"ad_date ASC"}
                reportType={reportType}
            />
        </div>
    )
}
