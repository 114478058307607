import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { useSelector } from "react-redux";


const FaceBookLink = forwardRef((props, ref) => {
    const [clicked, setClicked] = useState(false);

    const FACEBOOK_CLIENT_ID = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
    const FACEBOOK_CLIENT_SECRET = process.env.REACT_APP_FACEBOOK_CLIENT_SECRET;
    const FACEBOOK_REDIRECT_URI = process.env.REACT_APP_FACEBOOK_REDIRECT_URI;
    const FACEBOOK_TOKEN_URL = process.env.REACT_APP_FACEBOOK_TOKEN_URL;
    const FACEBOOK_AUTH_URL = `https://www.facebook.com/v20.0/dialog/oauth?
    client_id=${FACEBOOK_CLIENT_ID}&redirect_uri=${FACEBOOK_REDIRECT_URI}&state=${Math.random()
    .toString(36)
    .substring(3, 14)}&scope=public_profile`;

    const FBhandleClick = () => {
        setClicked(true);
        window.location.href = FACEBOOK_AUTH_URL;
    };

    useImperativeHandle(ref, () => ({
        triggerFBLogin: FBhandleClick,
    }));

    return null;
});

export default FaceBookLink;
