import axios from "../axios"

export const MyReportApi = async (username) => {
    try {
        const { data } = await axios.get("report/name-check", {
            params: {
                username: username
            }
        });
        return data
    } catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error)
        return null;
    }
}

export const MyPredictApi = async () => {
    try {
        const { data } = await axios.get("prediction-model/reports/");
        return data
    } catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error)
        return null;
    }
}
