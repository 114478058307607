import React from "react";

import styles from "../scss/performancemediacard.module.scss";

export const PerformanceMediaCard = ({ mediaData, percent, changeCost, tableData, linkedData }) => {
    // console.log(percent, changeCost, tableData)
    const today = new Date();
    const formatter = new Intl.DateTimeFormat('ko-KR', { year: 'numeric', month: '2-digit', day: '2-digit' });

    const startDate = formatter.format(today).replace(/\./g, '').replace(/ /g, ''); // 오늘 날짜
    const endDate = formatter
        .format(new Date(today.setDate(today.getDate() + 7))) // 오늘 날짜 + 7일
        .replace(/\./g, '') // . 제거
        .replace(/ /g, ''); // 공백 제거

    const processMediaData = (mediaArray, mediaEng, mediaNm, tableData) => {
        const prev = mediaArray.slice(0, -1).reduce((acc, curr) => {
            for (const key in curr) {
                if (key !== "media") {
                    acc[key] = (acc[key] || 0) + curr[key];
                }
            }
            return acc;
        }, {});
    
        const pred = mediaArray[mediaArray.length - 1];
    
        // percent와 changeCost 추가
        const percentValue = percent[mediaEng] || 0; // percent 값
        const changeCostValue = changeCost[mediaNm.toLowerCase()] || 0; // changeCost 값 (소문자 key)

        const tableDataEntry = tableData.data.find(
            (item) => item.media === mediaNm.toLowerCase()
        );
        const mediaCost = tableDataEntry ? tableDataEntry.total_cost : 0;
    
        return { prev, pred, percent: percentValue, changeCost: changeCostValue, mediaCost };
    };
    
    // 네이버, 카카오, 구글, 메타 결과 생성
    const naverResult = processMediaData(mediaData.naver, "네이버", "naver", tableData);
    const kakaoResult = processMediaData(mediaData.kakao, "카카오", "kakao", tableData);
    const googleResult = processMediaData(mediaData.google, "구글광고", "google", tableData);
    const metaResult = processMediaData(mediaData.meta, "메타", "meta", tableData);
    
    // 결과 객체
    const result = {
        "네이버 - 파워링크": naverResult,
        "카카오 - 카카오 키워드": kakaoResult,
        "구글 - 검색 광고": googleResult,
        "메타 - 디스플레이 광고": metaResult,
    };

    // console.log(result);

    const transformedDatae = (data) => [
        {name: '노출수', value: `${data.total_impression.toLocaleString()}`, change: data.total_impression_variance, operator: data.total_impression_variance_operator},
        {name: '클릭수', value: `${data.total_click.toLocaleString()}`, change: data.total_click_variance, operator: data.total_click_variance_operator},
        {name: '전환수', value: `${data.total_conversion_cnt.toLocaleString()}`, change: data.total_conversion_cnt_variance, operator: data.total_conversion_cnt_variance_operator},
        {name: '전환 매출액', value: `₩${data.total_conversion_amount.toLocaleString()}`, change: data.total_conversion_amount_variance, operator: data.total_conversion_amount_variance_operator},
        {name: '광고수익률', value: `${data.total_roas.toLocaleString()}%`, change: data.total_roas_variance, operator:data.total_roas_variance_operator},
    ];

    const transformedTotale = (data) => [
        { 
            name: '이전 광고비', 
            value: data.mediaCost ? `₩${data.mediaCost.toLocaleString()}` : '0', 
        },
        { 
            name: '변동률', 
            value: data.percent
                ? (parseFloat(data.percent) < 0 ? `${data.percent}%` : `+${data.percent}%`) // 음수는 그대로, 양수는 + 추가
                : '0%', 
        },
        { 
            name: '변경된 광고비', 
            value: data.changeCost ? `₩${data.changeCost.toLocaleString()}` : '0', 
        },
    ];
    
    const transformedResult = Object.fromEntries(
        Object.entries(result).map(([key, value]) => [
            key,
            {
                transformedData: value.pred ? transformedDatae(value.pred) : [],
                transformedTotal: transformedTotale(value)
            },
        ])
    );

    // console.log(transformedResult);
    
    const getChangeClassName = (operator) => {
        if (operator === "up") return styles.positive;
        if (operator === "down") return styles.negative;
        return '';
    };

    const mediaMapping = {
        5: "네이버 - 파워링크",
        89: "카카오 - 카카오 키워드",
        100: "구글 - 검색 광고",
    };

    const mediaNamesInLinkedData = linkedData.map(item => mediaMapping[item.media_info_code]).filter(Boolean);
    
    return (
        <div className={styles.perfMediaCard}>
            {Object.entries(transformedResult).map(([mediaName, mediaData], index) => {
                const isMeta = mediaName === "메타 - 디스플레이 광고";
                const hasData = !isMeta && mediaNamesInLinkedData.includes(mediaName);

                return (
                    <div 
                        key={index} 
                        className={isMeta || !hasData ? styles.noDataBox : styles.cardBox}
                    >
                        <p className={styles.cardTxt1}>{mediaName}</p>
                        {hasData ? (
                            <>
                                <div className={styles.mediaCard}>
                                {mediaData.transformedTotal.map((card, index) => (
                                    <div key={index} className={card.name === "변동률" ? `${styles.miniCard} ${getChangeClassName(card.value.includes('+') ? "up" : card.value.includes('-') ? "down" : '')}` : styles.scoreCard}>
                                        <div className={styles.scoreCardTitle}>{card.name}</div>
                                        {card.name === "변동률" ? (
                                            <div className={`${styles.scoreCardChange} ${getChangeClassName(card.value.includes('+') ? "up" : card.value.includes('-') ? "down" : '')}`}>
                                                {card.value}
                                            </div>
                                        ):(
                                            <div className={styles.scoreCardValue}>{card.value}</div>
                                        )}
                                    </div>
                                ))}
                                </div>
                                <p className={styles.cardTxt2}>예측 성과 ({startDate} ~ {endDate})</p>
                                <div className={styles.tableCol}>
                                    {mediaData.transformedData.map((table, index) => (
                                        <div key={`${table.name}-${index}`}>
                                            <div className={styles.tableHead}>
                                                <p>{table.name}</p>
                                            </div>
                                            <div className={styles.tableBody}>
                                                <p>{table.value}</p>
                                                <div className={`${styles.scoreCardChange} ${getChangeClassName(table.operator)}`}>
                                                    {table.name === "광고수익률" 
                                                        ? table.operator === "up" 
                                                            ? `+${table.change}%p` 
                                                            : table.operator === "down" 
                                                                ? `-${table.change}%p` 
                                                                : "-" 
                                                        : table.operator === "up" 
                                                            ? `+${table.change}%` 
                                                            : table.operator === "down" 
                                                                ? `-${table.change}%` 
                                                                : "-"
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <>
                            {isMeta ? (
                                <div className={styles.noDataTxt}>
                                    <p>메타 정보 없음</p>
                                    <p>현재 준비중인 기능으로 25년 2월부터 연결 가능합니다.</p>
                                </div>
                            ):( 
                                <div className={styles.noDataTxt}>
                                    <p>정보 없음</p>
                                    <p>해당 매체 데이터를 예측하고 싶으시다면 보고서 생성 시 해당 매체 데이터를 연결해주세요.</p>
                                </div>
                            )}
                            </>
                        )}
                    </div>
                )
            })}
        </div>
    );
}