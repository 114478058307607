import React from "react";

import styles from "../scss/resultmediacard.module.scss";

export const ResultMediaCard = ({ startDate, endDate, data, mediaData, linkedData, reportType, date }) => {
    const processMediaData = (mediaArray) => {
        // const prev = mediaArray.slice(0, -1).reduce((acc, curr) => {
        //     for (const key in curr) {
        //         if (key !== "media") {
        //             acc[key] = (acc[key] || 0) + curr[key];
        //         }
        //     }
        //     return acc;
        // }, {});

        const prev = mediaArray[mediaArray.length - 2];
    
        const pred = mediaArray[mediaArray.length - 1];
        return { prev, pred };
    };
    
    const naverResult = processMediaData(mediaData.naver);
    const kakaoResult = processMediaData(mediaData.kakao);
    const googleResult = processMediaData(mediaData.google);
    const metaResult = processMediaData(mediaData.meta);
    
    // 결과 객체
    const result = {
        "네이버 - 파워링크": naverResult,
        "카카오 - 카카오 키워드": kakaoResult,
        "구글 - 검색 광고": googleResult,
        "메타 - 디스플레이 광고": metaResult,
    };

    const transformedData = (data) => [
        { name: '광고비', value: data.total_cost ? data.total_cost.toLocaleString() : '0' },
        { name: '노출수', value: data.total_impression ? data.total_impression.toLocaleString() : '0' },
        { name: '클릭수', value: data.total_click ? data.total_click.toLocaleString() : '0' },
        { name: '전환수', value: data.total_conversion_cnt ? data.total_conversion_cnt.toLocaleString() : '0' },
        { name: '전환 매출액', value: data.total_conversion_amount ? data.total_conversion_amount.toLocaleString() : '0' },
    ];
    
    const transformedTotal = (data) => [
        { 
            name: '전환수', 
            value: data.total_conversion_cnt ? data.total_conversion_cnt.toLocaleString() : '0', 
            change: data.total_conversion_cnt_variance || 0, 
            operator: data.total_conversion_cnt_variance_operator || 'none' 
        },
        { 
            name: '전환 매출액', 
            value: data.total_conversion_amount ? data.total_conversion_amount.toLocaleString() : '0', 
            change: data.total_conversion_amount_variance || 0, 
            operator: data.total_conversion_amount_variance_operator || 'none' 
        },
        { 
            name: '광고수익률', 
            value: data.total_roas ? `${data.total_roas.toLocaleString()}%` : '0%', 
            change: data.total_roas_variance || 0, 
            operator: data.total_roas_variance_operator || 'none' 
        },
    ];
    
    // 변환 작업 수행
    const transformedResult = Object.fromEntries(
        Object.entries(result).map(([key, value]) => [
            key,
            {
                transformedData: value.prev ? transformedData(value.prev) : [],
                transformedTotal: value.pred ? transformedTotal(value.pred) : [],
            },
        ])
    );
    
    const getChangeClassName = (operator) => {
        if (operator === "up") return styles.positive;
        if (operator === "down") return styles.negative;
        return '';
    };

    const mediaMapping = {
        5: "네이버 - 파워링크",
        89: "카카오 - 카카오 키워드",
        100: "구글 - 검색 광고",
    };

    const mediaNamesInLinkedData = linkedData.map(item => mediaMapping[item.media_info_code]).filter(Boolean);

    return (
        <div className={styles.resultMedia}>
            {Object.entries(transformedResult).map(([mediaName, mediaData], index) => {
                const isMeta = mediaName === "메타 - 디스플레이 광고";
                const hasData = !isMeta && mediaNamesInLinkedData.includes(mediaName);

                return (
                    <div
                        key={index}
                        className={isMeta || !hasData ? styles.noDataBox : styles.cardBox}
                    >
                        <p className={styles.cardTxt1}>{mediaName}</p>
                        <p className={styles.cardTxt2}>
                            {reportType === "weekly" ? "예측 결과(1주일)" : "예측 결과(1개월)"}
                        </p>
                        <div className={styles.mediaCard}>
                            {hasData ? (
                                mediaData.transformedTotal.map((card, idx) => (
                                    <div key={idx} className={styles.scoreCard}>
                                        <div className={styles.scoreCardTitle}>{card.name}</div>
                                        <div className={styles.scoreCardValue}>{card.value}</div>
                                        <div
                                            className={`${styles.scoreCardChange} ${getChangeClassName(
                                                card.operator
                                            )}`}
                                        >
                                            {card.name === "광고수익률" 
                                                ? card.operator === "up"
                                                    ? `+${card.change}%p`
                                                        : card.operator === "down"
                                                            ? `-${card.change}%p`
                                                            : "-"
                                                : card.operator === "up"
                                                    ? `+${card.change}%`
                                                        : card.operator === "down"
                                                            ? `-${card.change}%`
                                                            : "-"
                                                }
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <>
                                    {isMeta ? (
                                        <div className={styles.noDataTxt}>
                                            <p>메타 정보 없음</p>
                                            <p>현재 준비중인 기능으로 25년 2월부터 연결 가능합니다.</p>
                                        </div>
                                    ) : (
                                        <div className={styles.noDataTxt}>
                                            <p>정보 없음</p>
                                            <p>해당 매체 데이터를 예측하고 싶으시다면 보고서 생성 시 해당 매체 데이터를 연결해주세요.</p>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        {date ? (
                            <p className={styles.cardTxt2}>
                                이전 성과 ({date[0].replace(/-/g, '')} ~ {endDate})
                            </p>
                        ) : (
                            <p className={styles.cardTxt2}>
                            이전 성과 ({startDate} ~ {endDate})
                        </p>
                        )}
                        <div className={styles.tableCol}>
                            {mediaData.transformedData.map((table, idx) => (
                                <div key={`${table.name}-${idx}`}>
                                    <div className={styles.tableHead}>
                                        <p>{table.name}</p>
                                    </div>
                                    <div className={styles.tableBody}>
                                        <p>{table.value}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* {isMeta && (
                            <div className={styles.noDataTxt}>
                                <p>메타 정보 없음</p>
                                <p>현재 준비중인 기능으로 25년 2월부터 연결 가능합니다.</p>
                            </div>
                        )} */}
                    </div>
                );
            })}
        </div>
    );
}