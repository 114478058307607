import React, { useState, useEffect, useContext } from 'react';

import styles from '../scss/dagroupsumchart.module.scss';

import { DaGroupSumGaugeApi, DaGroupSumLineApi } from '../../../../../api/reportDA/campaign/DaGroupSumApi';

import { CampaignGauge } from '../../../../utils/chart/CampaignGauge';
import { DaBarChart } from '../../../../utils/chart/DaBarChart';
import Loading from '../../../../utils/loading/Loading';
import { DaGroupSumTable } from './DaGroupSumTable';
import { NullBox } from '../../../../utils/nullBox/NullBox';

import { SummaryContext } from '../../../summary/DaSummaryProvider';


export const DaGroupSumChart = ({mediaInfoId, adAccountsId, startDate, endDate, reportType, mediaName}) => {
    const [data, setData] = useState(null);
    const [gaugeData, setGaugeData] = useState(["0"]);
    const [loading, setLoading] = useState(true);
    const [metricType, setMetricType] = useState('tot_cost');
    const [timeUnit, setTimeUnit] = useState('day');
    const [groupIds, setGroupIds] = useState(["0"]);
    const { setGroup } = useContext(SummaryContext);

    const handleResponse = (response) => {
        const maxRoas = response.data.reduce((maxItem, item) => {
            return (item.roas > (maxItem.roas || 0)) ? item : maxItem
        })

        const minRoas = response.data.reduce((minItem, item) => {
            return (item.roas < (minItem.roas || 0)) ? item : minItem
        });
        
        setGroup({low: minRoas.ad_group_name, high: maxRoas.ad_group_name})
    }

    useEffect(() => {
        const fetchData = async () => {
            const gaugeResponse = await DaGroupSumGaugeApi(mediaName, startDate, endDate, adAccountsId);
            // handleResponse(gaugeResponse);
            setGaugeData(gaugeResponse);
            // console.log(gaugeResponse);
            const groupId = gaugeResponse.data.map(item => item.ad_group_id);
            // console.log(groupId)
            setGroupIds(groupId);
            const lineResponse = await DaGroupSumLineApi(mediaName, startDate, endDate, adAccountsId, groupId, timeUnit);
            setData(lineResponse);
            handleResponse(lineResponse);
            setLoading(false);
            // console.log(gaugeResponse, lineResponse)
        };
        fetchData();
    }, [startDate, endDate, metricType, timeUnit, adAccountsId]);

    if (loading) {
        return (
            <div className={styles.daGroupSum}>
                <Loading />
            </div>
        );
    }

    const isGaugeDataEmpty = (data) => {
        return data.every(item => 
            item.tot_cost === 0 ||
            item.tot_cost == null
        );
    };

    const isLineDataEmpty = (data) => {
        return data.every(item => 
            item.roas === 0 &&
            item.previous_roas === 0
        )
    };

    return (
        <div className={styles.daGroupSum}>
            <div className={styles.daGroupSumTitle}>광고그룹 요약 내용</div>
            <div className={styles.daGroupSumChart}>
                <div>
                    <p>광고그룹 별 광고비</p>
                    {isGaugeDataEmpty(gaugeData.data) ? (
                        <NullBox />
                    ) : (
                        <CampaignGauge data={gaugeData} selectedData={"tot_cost"} mediaInfoId={mediaInfoId} reportType={reportType} />
                    )}
                </div>
                <div>
                    <p>광고그룹 별 광고수익률</p>
                    {isLineDataEmpty(data.data) ? (
                        <NullBox />
                    ) : (
                        <DaBarChart data={data} mediaInfoId={mediaInfoId} label1="광고수익률" label2="이전 광고수익률" reportType={reportType} />
                    )}
                </div>
            </div>
            <DaGroupSumTable mediaInfoId={mediaInfoId} adAccountsId={adAccountsId} startDate={startDate} endDate={endDate} groupIds={groupIds} setGroupIds={setGroupIds} gaugeData={gaugeData} reportType={reportType} />
        </div>
    );
};