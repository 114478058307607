import React, { useState } from "react";

import styles from "../scss/modal/changenamemodal.module.scss";

export const ChangeNameModal = ({ linkDataName, incrementId, onClose, onConfirm }) => {
    const [newName, setNewName] = useState("");

    const handleChange = (e) => {
        setNewName(e.target.value);
    };

    const handleConfirm = () => {
        onConfirm(incrementId, newName);
    };

    return (
        <div className={styles.modalBackdrop}>
            <div className={styles.modalContent}>
                <p>변경할 이름을 입력해주세요.</p>
                <input 
                    value={newName}
                    onChange={handleChange}
                    placeholder={linkDataName}
                />
                <div className={styles.btnBox}>
                    <button className={styles.closeBtn} onClick={onClose}>취소</button>
                    <button className={styles.changeBtn} onClick={handleConfirm}>변경하기</button>
                </div>
            </div>
        </div>
    );
};
