import axios from "../axios"
export const GetMediaLinkedAPI = async (selectedLink) => {
    try {
        const { data } = await axios.get('common/media-link/', 
            {
                params: {
                    selectedLink: selectedLink,
                },
            });
            // console.log("GET data : ",data);
        
        return data; // 가져온 데이터 반환
    } catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error);
        return null;
    }
}

export const UnlinkDataApi = async (incrementId) => {
    try {
        
        const data = await axios.delete("common/media-link/", {
            params: {
                incrementId: incrementId
            }
        });
        // console.log("DELETE data : ", data);
        
        return data.data;
    } catch (error) {
        console.error("연결 해지 중 오류 발생:", error);
        throw error;
    }
};