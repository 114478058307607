import axios, { deleteAccessToken } from "../axios";

export const LogOutApi = async () => {
  try {
    const response = await axios.post("common/logout/");
    deleteAccessToken();
    return response; // 응답 객체 반환
  } catch (error) {
    console.error("로그아웃 처리 중 오류 발생:", error);
    throw error; // 오류 발생 시 예외 던지기
  }
};
