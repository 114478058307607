import axios from "../../axios";

export const DaPageTableApi = async (media, startDate, endDate, accountId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/creatives/detail-creative/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/creatives/detail-creative/`;
    } else {
        path = `${media}/${accountId}/creatives/detail-creative/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
                order_by: "tot_cost DESC"
            }
        });
        return data;
    } catch (error) {
        console.error("지면별 성과 분석 테이블 데이터 오류 발생:", error);
        throw error;
    }
};

export const DaPageTableTotalApi = async (media, startDate, endDate, accountId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/creatives/detail-creative-total/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/creatives/detail-creative-total/`;
    } else {
        path = `${media}/${accountId}/creatives/detail-creative-total/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
            }
        });
        return data;
    } catch (error) {
        console.error("지면별 성과 분석 테이블 총합 데이터 오류 발생:", error);
        throw error;
    }
};