import React, { useState, useEffect } from 'react';

import styles from '../scss/reportdascorecard.module.scss';

import { DaCostScoreCardApi } from '../../../../../api/reportDA/cost/DaCostScoreCardApi';

import { ScoreCard } from '../../../../utils/card/ScoreCard';
import Loading from '../../../../utils/loading/Loading';


export const ReportDaScoreCard = ({adAccountsId, startDate, endDate, mediaName}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await DaCostScoreCardApi(mediaName, startDate, endDate, adAccountsId);
            setData(response);
            setLoading(false);
            // console.log(response);
        };
        fetchData();
    }, [startDate, endDate, adAccountsId])

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    const transformedData = [
        {name: '총 광고비용', value: `${data.data.tot_cost.toLocaleString()}원`, change: data.data.tot_cost_variance, operator: data.data.tot_cost_variance_operator},
        {name: '노출당 비용', value: `${data.data.impression_cost.toLocaleString()}원`, change: data.data.impression_cost_variance, operator: data.data.impression_cost_variance_operator},
        {name: '클릭당 비용', value: `${data.data.cost_click.toLocaleString()}원`, change: data.data.cost_click_variance, operator: data.data.cost_click_variance_operator},
        {name: '전환당 비용', value: `${parseInt(data.data.conversion_cost, 10).toLocaleString()}원`, change: data.data.conversion_cost_variance, operator: data.data.conversion_cost_variance_operator},
        {name: '전환 매출액', value: `${parseInt(data.data.tot_conversion_amount, 10).toLocaleString()}원`, change: data.data.tot_conversion_amount_variance, operator: data.data.tot_conversion_amount_variance_operator},
    ]

    // const testData = [
    //     {name: '총 광고비용', value: "12313원", change: "12.12%", operator: "up"},
    //     {name: '노출당 비용', value: "2039원", change: "12.12%", operator: "down"},
    //     {name: '클릭당 비용', value: "2301원", change: "12.12%", operator: "up"},
    //     {name: '전환당 비용', value: "2122원", change: "12.12%", operator: "down"},
    //     {name: '전환 매출액', value: "2432원", change: "12.12%", operator: "up"},
    // ]

    return (
        <div className={styles.reportScoreCard}>
            <ScoreCard data={transformedData}/>
        </div>
    );
}