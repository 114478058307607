import React from "react";

import styles from "../scss/googledevicechart.module.scss"

export const GoogleDeviceChart = ({transformedData}) => {
    const graphData = [
        { label: "광고비", key: "광고비 비율" },
        { label: "전환 매출액", key: "전환 매출액 비율" },
        { label: "노출수", key: "노출수 비율" },
        { label: "클릭수", key: "클릭수 비율" },
    ];

    const devices = ["휴대전화", "태블릿", "컴퓨터", "TV 화면"];
    const filteredData = transformedData.slice(1);

    // 디바이스가 있는지 확인하고, 없으면 기본값 추가
    const deviceList = devices.map(device => {
        const data = filteredData.find(item => item.디바이스 === device);
        return data ? data : { "디바이스": device, "광고비": 0, "광고비 비율": "0", "노출수": 0, "노출수 비율": "0", "전환 매출액": 0, "전환 매출액 비율": 0, "전환수": 0, "클릭수": 0, "클릭수 비율": 0 };
    });

    return (
        <div className={styles.daDeviceChart}>
            <div className={styles.deviceGraphBox}>
                {graphData.map((graph, graphIndex) => (
                    <div
                        key={`graph-${graphIndex}`} // 고유 key 설정
                        className={styles.deviceGraphContainer}
                    >
                        <p className={styles.graphText}>{graph.label}</p>
                        <div className={styles.graphGauge}>
                            {deviceList.map((data, deviceIndex) => (
                                <div
                                    key={`gauge-${graphIndex}-${deviceIndex}`} // 고유 key 설정
                                    className={styles[`gauge${deviceIndex + 1}`]}
                                    style={{ width: `${data[graph.key]}%` }}
                                ></div>
                            ))}
                        </div>
                        <div className={styles.labelBox}>
                            {deviceList.map((data, deviceIndex) => (
                                <p
                                    key={`label-${graphIndex}-${deviceIndex}`} // 고유 key 설정
                                    className={styles[`squareLabel${deviceIndex + 1}`]}
                                >
                                    {data[graph.key]}%
                                </p>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}