import axios from "../../axios";

export const AdvCostApi = async ( incrementId, mediaCost ) => {
    const path = `prediction-model/pred-cost/${incrementId}`

    try {
        const { data } = await axios.get(path, {
            params: {
                naver_cost: mediaCost.naver,
                kakao_cost: mediaCost.kakao,
                google_cost: mediaCost.google
            }
        });
        return data;
    } catch (error) {
        console.error("예측모델 광고비 입력 예측 결과 오류 발생:", error);
        throw error;
    }
};