import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";

import styles from "../scss/predcondition.module.scss";
import { ReactComponent as Naver } from "../../../../assets/images/share/naver.svg";
import { ReactComponent as Kakao } from "../../../../assets/images/share/kakao.svg";
import { ReactComponent as Google } from "../../../../assets/images/share/google.svg";
import { ReactComponent as Meta } from "../../../../assets/images/share/meta.svg";

import { AdvCostApi } from "../../../../api/predict/advcost/AdvCostApi";

import { InfoIcon } from "../../../utils/info/InfoIcon";
import Loading from "../../../utils/loading/Loading";

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg
                width="16" // 화살표 너비
                height="10" // 화살표 높이
                viewBox="0 0 16 10"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0 0L8 10L16 0"
                    fill="#00A0A0" // 화살표 내부 색상
                />
            </svg>
        </components.DropdownIndicator>
    );
};


export const PredCondition = ({ 
        incrementId, 
        tableData, 
        loading, 
        setAdvData, 
        setAdvLoading, 
        target, 
        setTarget, 
        percent,
        setPercent,
        changeCost,
        setChangeCost,
        linkedData
    }) => {
    const [totalCost, setTotalCost] = useState(0);

    useEffect(() => {
        if (!loading) {
            const initialTotal = updatedMediaBox.reduce((sum, media) => sum + media.cost, 0);
            setTotalCost(initialTotal); // 기본 totalCost 설정
        }
    }, [tableData, loading]);
    
    if (loading) {
        return (
            <div className={styles.predCondition}>
                <Loading />
            </div>
        )
    }

    const targetOptions = [
        { label: "전환수", value: "conv_count" },
        { label: "상품 매출", value: "conv_amount" },
        { label: "광고수익률", value: "roas" },
    ];

    const handleChange = (value) => {
        setTarget(value);
    };

    const mediaBox = [
        {name: "네이버", mark: <Naver />},
        {name: "카카오", mark: <Kakao />},
        {name: "구글광고", mark: <Google />},
        {name: "메타", mark: <Meta />},
    ]

    const mediaMapping = {
        "네이버": "naver",
        "카카오": "kakao",
        "구글광고": "google",
        "메타": "meta",
        5: "네이버",
        89: "카카오",
        100: "구글광고"
    };

    const mediaNamesInLinkedData = linkedData.map(item => mediaMapping[item.media_info_code]).filter(Boolean);
    
    // `mediaBox`에 `cost` 추가
    const updatedMediaBox = mediaBox.map((media) => {
        // `media.name`을 `mediaMapping`에서 변환하고, 해당 값을 기준으로 `data`에서 찾는다
        const matchedData = tableData.data.find(
            (item) => item.media === mediaMapping[media.name]
        );
        
        return {
            ...media, // 기존 mediaBox의 내용 유지
            cost: matchedData ? matchedData.total_cost : 0, // 값이 없으면 0
        };
    });

    // 변경 비율 선택 시 핸들러
    const handlePercentChange = (mediaName, value) => {
        const changeLabelToPercent = {
            "decrease_thr": -30,
            "decrease_two": -20,
            "decrease_one": -10,
            "zero": 0,
            "increase_one": 10,
            "increase_two": 20,
            "increase_thr": 30,
            "increase_four": 40,
            "increase_five": 50,
        };
    
        const changePercentage = changeLabelToPercent[value];
    
        setPercent((prev) => {
            const updatedPercent = {
                ...prev,
                [mediaName]: changePercentage,
            };
    
            // 상태 업데이트 후 동기적으로 변경 광고비 계산
            const updatedChangeCost = { ...changeCost };
            updatedMediaBox.forEach((media) => {
                const percentage = updatedPercent[media.name] || 0;
                updatedChangeCost[mediaMapping[media.name]] = Math.round(
                    media.cost * (1 + percentage / 100)
                );
            });
    
            setChangeCost(updatedChangeCost);
    
            // 즉시 총합 계산 및 업데이트
            const total = updatedMediaBox.reduce((sum, media) => {
                const percentage = updatedPercent[media.name] || 0;
                const adjustedCost = media.cost * (1 + percentage / 100);
                return sum + adjustedCost;
            }, 0);
            setTotalCost(total);
    
            return updatedPercent;
        });
    };
    
    // "예측하기" 버튼 클릭 시 동작
    const handlePredict = () => {
        // console.log("저장된 변경 광고비:", changeCost);
        setAdvData(null);
        setAdvLoading(1);
        const fetchData = async() => {
            try {
                const response = await AdvCostApi(incrementId, changeCost);
                setAdvData(response);
                setAdvLoading(2);
                // console.log(response);

                // 스크롤을 가장 아래로 내리기
                window.scrollTo({
                    top: document.body.scrollHeight, // 문서의 전체 높이
                    behavior: 'smooth' // 부드러운 스크롤
                });
            } catch (error) {
                setAdvLoading(0);
                alert("광고비 변경을 진행해주세요.");
            }
        };

        fetchData();
    };


    const changePer = [
        { label: "-30%", value: "decrease_thr" },
        { label: "-20%", value: "decrease_two" },
        { label: "-10%", value: "decrease_one" },
        { label: "0", value: "zero" },
        { label: "+10%", value: "increase_one" },
        { label: "+20%", value: "increase_two" },
        { label: "+30%", value: "increase_thr" },
        { label: "+40%", value: "increase_four" },
        { label: "+50%", value: "increase_five" },
    ]

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: '94px',
            height: '28px',
            minHeight: '28px',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontWeight: 800,
            color: "#8C8C8C",
            boxShadow: state.isFocused ? 'none' : provided.boxShadow,
            borderColor: state.isFocused ? "#BDC2C7" : "#BDC2C7",
            '&:hover': {
                borderColor: state.isFocused ? "#BDC2C7" : "#BDC2C7",
            },
            display: 'flex', // Flexbox 적용
            justifyContent: 'space-between', // 숫자와 화살표를 양쪽 끝으로 정렬
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0 4px', // 숫자 주변 간격 조정
            justifyContent: 'flex-end', // 숫자 위치를 화살표 방향으로 이동
        }),
        dropdownIndicator: (base) => ({
            ...base,
            padding: "8px 6px", // 화살표 주변 여백
        }),
        indicatorSeparator: () => ({
            display: 'none', // 화살표 왼쪽 선 제거
        }),
        menu: (provided) => ({
            ...provided,
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontWeight: 800,
            color: "#8C8C8C",
            borderRadius: "5px",
        }),
        option: (provided, state) => ({
            ...provided,
            width: '95%',
            marginLeft: '3px',
            marginRight: '3px',
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontWeight: 800,
            backgroundColor: state.isSelected ? 'rgba(0, 160, 160, 0.2)' : 'white',
            color: state.isSelected ? '#00A0A0' : '#8C8C8C',
            '&:hover': {
                backgroundColor: 'rgba(0, 160, 160, 0.2)',
                color: '#00A0A0',
            },
        }),
        menuList: (provided) => ({
            ...provided,
            paddingRight: '5px',
            '::-webkit-scrollbar': {
                width: '5px',
                height: '5px',
                marginRight: '5px',
            },
            '::-webkit-scrollbar-thumb': {
                background: '#D9D9D9',
                borderRadius: '5px',
            },
            '::-webkit-scrollbar-thumb:hover': {
                background: '#8C8C8C',
            },
            '::-webkit-scrollbar-track': {
                background: '#FFF',
                paddingRight: '5px',
            },
        }),
    };

    const firstMsg = () => {
        return (
            <>
            광고 매체에서 달성하고자 하는 목표(전환수, 매출, ROAS) 를 선택합니다.
            </>
        )
    }

    const secondMsg = () => {
        return (
            <>
            다음 주에 사용할 광고비를 얼마나 변경할지 설정합니다.
            </>
        )
    }


    return (
        <div className={styles.predCondition}>
            <h3>예측 조건 설정</h3>
            <h5>다음 주 광고 예산을 매체별로 조정하고, 목표 전환을 설정하여 광고 성과를 예측해 보세요.</h5>
            <div className={styles.conditionBox}>
                <div className={styles.adTarget}>
                    <div className={styles.infoBox}>
                        <p>1. 광고 목표를 선택해주세요.</p>
                        <InfoIcon message={firstMsg()} className="infoTxtBox2" margin="210px" />
                    </div>
                    <div className={styles.radioGroup}>
                        {targetOptions.map((option) => (
                            <label key={option.value} className={styles.radioOption}>
                                <input
                                    type="radio"
                                    value={option.value}
                                    checked={target === option.value}
                                    onChange={() => handleChange(option.value)}
                                />
                                <span className={styles.radioCustom}></span>
                                <span className={styles.radioLabel}>{option.label}</span>
                            </label>
                        ))}
                    </div>
                </div>
                <div className={styles.adCostChange}>
                    <div className={styles.infoBox}>
                            <p>2. 광고비 변경</p>
                            <InfoIcon message={secondMsg()} className="infoTxtBox2" margin="110px" />
                    </div>
                    <div className={styles.mediaBox}>
                        <div className={styles.mediaContainer}>
                            {updatedMediaBox.map((media) => {
                                const hasData = media.name !== "메타" && mediaNamesInLinkedData.includes(media.name);

                                return (
                                    <div className={media.name === "메타" || !hasData ? styles.noDataBox : styles.changeBox}>
                                        <div className={styles.mediaImg}>{media.mark}</div>
                                        <p className={styles.mediaName}>{media.name} 광고비</p>
                                        <p className={styles.prevCost}>{media.cost.toLocaleString()}</p>
                                        
                                        {hasData ? (
                                            <>
                                                <Select
                                                    options={changePer}
                                                    value={changePer.find(
                                                        (option) => option.value === (percent[media.name] || "zero")
                                                    )}
                                                    onChange={(selectedOption) =>
                                                        handlePercentChange(media.name, selectedOption.value)
                                                    }
                                                    styles={customStyles}
                                                    placeholder="변경 비율 선택"
                                                    components={{ DropdownIndicator }}
                                                />
                                            </>
                                        ):( 
                                            <>
                                                {media.name === "메타" ? (
                                                    <div className={styles.noDataTxt}>
                                                        <p>메타 정보 없음</p>
                                                        <p>현재 준비중인 기능으로 25년 2월부터 연결 가능합니다.</p>
                                                    </div>
                                                ) : (
                                                    <div className={styles.noDataTxt}>
                                                        <p>정보 없음</p>
                                                        <p>해당 매체 데이터를 예측하고 싶으시다면 보고서 생성 시 해당 매체 데이터를 연결해주세요.</p>
                                                    </div>
                                                )} 
                                                
                                            </>
                                        )}
                                    </div>
                                )
                        })}
                        </div>
                        <div className={styles.totalBox}>
                            <p className={styles.totalBoxTxt}>변경된 광고비 총합</p>
                            <p className={styles.totalCost}>{Math.round(totalCost).toLocaleString()}원</p>
                            <p className={styles.totalBoxTxt}>광고비 증감률</p>
                            <p className={styles.totalCost}>
                                {totalCost === 0
                                    ? "-"
                                    : `${((totalCost / updatedMediaBox.reduce((sum, media) => sum + media.cost, 0) - 1) * 100).toFixed(2)}%`}
                            </p>
                            <button className={styles.predBtn} onClick={handlePredict}>예측하기</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}