import axios from "../axios"

export const EmailCertApi = async (username) => {
    // console.log(username)
    try {
        const { data } = await axios.get("common/email-cert", {
            params: {
                username: username
            },
            // validateStatus: (status) => {
            //     // HTTP 상태 코드가 2xx가 아닌 경우 에러로 처리
            //     return status >= 200 && status < 300 && data !== null;
            // },
        });

        if (data === null || data === undefined) {
            throw new Error("서버에서 유효하지 않은 응답을 반환했습니다.");
        }

        return data
    } catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error)
        throw error;
    }
}