import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import styles from "./reportcreate.module.scss";
import { ReactComponent as Naver } from "../../../assets/images/share/naver.svg";
import { ReactComponent as Kakao } from "../../../assets/images/share/kakao.svg";
import { ReactComponent as Ga4 } from "../../../assets/images/home/home-report-ga4.svg";
import { ReactComponent as Google } from "../../../assets/images/share/google.svg";
import { ReactComponent as Facebook } from "../../../assets/images/share/facebook.svg";

import { AdAccountsApi } from "../../../api/dataCenter/AdAccountsApi";

import { ReportList } from "../list/ReportList";
import { ReportDetail } from "../detail/ReportDetail";

export const ReportCreate = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const username = useSelector((state) => state.user.username);
    const [openDetails, setOpenDetails] = useState("default");
    const [isReportOpen, setIsReportOpen] = useState([false, false, false, false, false]);
    const [media, setMedia] = useState(null);
    const [type, setType] = useState(null);
    const [isMediaMatched, setIsMediaMatched] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const response= await AdAccountsApi(username);
            setData(response);
            setLoading(false);
            // console.log(response);
        };

        fetchData();
    }, [username]);

    if (loading) {
        return <></>
    }

    const reportData = [
        // {
        //     icons: [<Ga4 />, <Naver />, <Kakao />, <Facebook />, <Google />],
        //     title: "매체 통합 보고서",
        //     description: "매체 통합 네이버 광고 성과 데이터 분석 대시보드에 대한 간략한 설명을 작성해줍니다.",
        //     dataLink: "GA4 데이터를 연결해주세요.",
        //     dataLink2: "매체 데이터를 연결해주세요.",
        //     value: "total"
        // },
        {
            icons: [<Naver />],
            title: "네이버 검색 광고 보고서",
            warning: "* 쇼핑검색 데이터는 제공하지 않습니다.",
            description: "네이버 검색 광고 성과 데이터를 분석하여 광고 성과를 한눈에 파악하고, 최적의 광고 전략을 수립하여 광고 비용을 효율적으로 관리할 수 있습니다. 이를 위해 차트, 표, 보고서의 핵심 요약 정보가 제공됩니다. ",
            dataLink: "Naver 데이터를 연결해주세요.",
            value: "naver_sa",
            type: "sa",
            code: 5
        },
        {
            icons: [<Kakao />],
            title: "카카오 키워드 보고서",
            description: "카카오 키워드 광고 성과 데이터를 분석하여 광고 성과를 한눈에 파악하고, 최적의 광고 전략을 수립하여광고 비용을 효율적으로 관리할 수 있습니다. 이를 위해 차트, 표, 보고서의 핵심 요약 정보가 제공됩니다.",
            dataLink: "Kakao 데이터를 연결해주세요.",
            value: "kakao_keyword",
            type: "sa",
            code: 89
        },
        {
            icons: [<Google />],
            title: "구글 검색 광고 보고서",
            description: "구글 검색 광고 성과 데이터를 분석하여 광고 성과를 한눈에 파악하고, 최적의 광고 전략을 수립하여광고 비용을 효율적으로 관리할 수 있습니다. 이를 위해 차트, 표, 보고서의 핵심 요약 정보가 제공됩니다.",
            dataLink: "Google 데이터를 연결해주세요.",
            value: "google_sa",
            type: "sa",
            code: 100
        },
        {
            icons: [<Kakao />],
            title: "카카오 모먼트 보고서",
            description: "카카오 모먼트 광고 성과 데이터를 분석하여 광고 성과를 한눈에 파악하고, 최적의 광고 전략을 수립하여광고 비용을 효율적으로 관리할 수 있습니다. 이를 위해 차트, 표, 보고서의 핵심 요약 정보가 제공됩니다.",
            dataLink: "Kakao 데이터를 연결해주세요.",
            value: "kakao_moment",
            type: "da",
            code: 7
        },
        {
            icons: [<Google />],
            title: "구글 광고 소재 보고서",
            description: "구글 광고 소재의 성과 데이터를 분석하여 새로운 소재를 제작하거나 기존 소재를 개선하는 데 활용할 수 있습니다. 이를 위해 차트, 표, 소재, 보고서의 핵심 요약 정보가 제공됩니다.",
            dataLink: "Google 데이터를 연결해주세요.",
            value: "google_da",
            type: "da",
            code: 100
        },
        // {
        //     icons: [<Facebook />],
        //     title: "메타 광고 소재 보고서",
        //     description: "메타 광고 소재의 성과 데이터를 분석하여 새로운 소재를 제작하거나 기존 소재를 개선하는 데 활용할 수 있습니다. 이를 위해 차트, 표, 소재, 보고서의 핵심 요약 정보가 제공됩니다.",
        //     dataLink: "Facebook 데이터를 연결해주세요.",
        //     value: "meta",
        //     type: "da"
        // },
    ];

    const handleDetailClick = (value, index, type, code) => {
        setMedia(value)
        setType(type)
        setOpenDetails((prevState) => (prevState === value ? "default" : value));
        setIsReportOpen((prevState) => {
            const newState = Array(reportData.length).fill(false); // 모든 보고서를 접기 상태로 초기화
            newState[index] = !prevState[index]; // 클릭된 보고서의 상태 토글
            return newState;
        });

        const matched = data.data?.some((item) => item.media_info_code === code);
        setIsMediaMatched(matched);
    };

    return (
        <div className={styles.main}>
            <h2 className={styles.title}>보고서 선택하기</h2>
            <div className={styles.container}>
                <ReportList reportData={reportData} handleDetailClick={handleDetailClick} isReportOpen={isReportOpen} media={media} type={type} isMediaMatched={isMediaMatched} />
                <ReportDetail openDetails={openDetails} />
            </div>
        </div>
    );
};
