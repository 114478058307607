import React from "react";

import Header from '../../../shared/header/Header';
import Sidebar from '../../../shared/sidebar/Sidebar';

import { ReportDA } from "../../../components/report/da/ReportDA";

export const ReportGoogleDA = () => {
    return (
        <>
            <Header />
            <Sidebar />
            <ReportDA media={"google"} />
        </>
    );
};

