import React, { useState, useEffect } from 'react';

import styles from '../scss/reportscorecard.module.scss';

import { ClickScoreCardApi } from '../../../../../api/report/click/ClickScoreCardApi';

import { ScoreCard } from '../../../../utils/card/ScoreCard';
import Loading from '../../../../utils/loading/Loading';

export const ReportScoreCard = ({adAccountsId, startDate, endDate, mediaName}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await ClickScoreCardApi(mediaName, startDate, endDate, adAccountsId);
            setData(response);
            setLoading(false);
        };
        fetchData();
    }, [startDate, endDate, adAccountsId])

    if (loading) {
        return (
            <div className={styles.reportScoreCard}>
                <Loading />
            </div>
        );
    }

    if (!data) {
        return null;
    }

    const transformedData = [
        {name: '노출수', value: `${data.data.tot_impression.toLocaleString()}회`, change: data.data.tot_impression_variance, operator: data.data.tot_impression_variance_operator},
        {name: '클릭수', value: `${data.data.tot_click.toLocaleString()}번`, change: data.data.tot_click_variance, operator: data.data.tot_click_variance_operator},
        {name: '클릭률', value: `${data.data.click_rate}%`, change: data.data.click_rate_variance, operator: data.data.click_rate_variance_operator},
        {name: '전환수', value: `${parseFloat(data.data.tot_conversion_count).toLocaleString()}회`, change: data.data.tot_conversion_count_variance, operator: data.data.tot_conversion_count_variance_operator},
        {name: '전환 매출액', value: `${parseFloat(data.data.tot_conversion_amount).toLocaleString()}원`, change: data.data.tot_conversion_amount_variance, operator: data.data.tot_conversion_amount_variance_operator},
    ]

    return (
        <div className={styles.reportScoreCard}>
            <ScoreCard data={transformedData}/>
        </div>
    );
}