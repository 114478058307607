import "./App.css";
import Router from "./shared/Router";
import Gtm from "./shared/Gtm";
import Title from "./shared/Title";

function App() {
  return (
    <>
      <Router />
      <Gtm gtmId="GTM-PLVPVM4D" />
      <Title />
    </>
  );
}

export default App;
