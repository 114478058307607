import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./emailverify.module.scss"
import {ReactComponent as Logo} from "../../assets/images/share/pwfind-logo.svg"

export const EmailVerify = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const username = location.state?.username;

    const handleBtn = () => {
        navigate("/");
    }

    return(
        <div className={styles.evMain}>
            <div className={styles.evHeader}>
                <Logo />
            </div>
            <div className={styles.evBody}>
                <p className={styles.evTxt1}>회원가입 이메일 인증</p>
                <p className={styles.evTxt2}>인증메일이 아래의 이메일 주소로 전송되었습니다.</p>
                <p className={styles.evTxt3}>24시간 이내로 인증을 완료해주세요.</p>
                <div className={styles.evIdBox}>
                    {username}
                </div>
                <div className={styles.evBtnBox}>
                    <button onClick={handleBtn}>로그인 화면으로</button>
                </div>
            </div>
        </div>
    );
};