import React, { useState, useEffect } from "react";

import styles from "../scss/dadevicechart.module.scss"
import { ReactComponent as KakaoLabels } from "../../../../../assets/images/report/report-da-device-kakao-lables.svg"
import { ReactComponent as GoogleLabels } from "../../../../../assets/images/report/report-da-device-google-labels.svg"

import { DaCostDeviceGraphApi, DaCostDeviceTotalApi } from "../../../../../api/reportDA/cost/DaCostDeviceApi";

import { ReportDaTable } from "../../../../utils/table/ReportDaTable";
import Loading from "../../../../utils/loading/Loading";
import { KakaoDeviceChart } from "./KakaoDeviceChart";
import { GoogleDeviceChart } from "./GoogleDeviceChart";


export const DaDeviceChart = ({
        mediaInfoId, 
        adAccountsId, 
        startDate,
        endDate,
        mediaName
    }) => {
    const [data, setData] = useState(null);
    const [total, setTotal] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            const response = await DaCostDeviceGraphApi(mediaName, startDate, endDate, adAccountsId);
            const totResponse = await DaCostDeviceTotalApi(mediaName, startDate, endDate, adAccountsId);
            setData(response);
            setTotal(totResponse);
            setLoading(false);
            // console.log(response)
        };
        fetchData();
    }, [startDate, endDate, adAccountsId])

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data || data.data.length === 0) {
        return null;
    }

    const totalCost = total.data.tot_cost;
    const totalImp = total.data.tot_impression;
    const totalClick = total.data.tot_click;
    const totalConvAmount = total.data.tot_conversion_amount;

    const headers = ["디바이스", "광고비", "노출수", "클릭수", "전환수", "전환 매출액"];

    const transformedDevice = data.data.reduce((acc, item) => {
        if (item.device_type === "Android" || item.device_type === "iOS") {
            const existing = acc.find(d => d.device_type === "Mobile");
            if (existing) {
                existing.tot_cost += item.tot_cost;
                existing.tot_impression += item.tot_impression;
                existing.tot_click += item.tot_click;
                existing.tot_conversion_count += item.tot_conversion_count;
                existing.tot_conversion_amount += item.tot_conversion_amount;
            } else {
                acc.push({
                    ...item,
                    device_type: "Mobile",
                });
            }
        } else {
            acc.push(item);
        }
        return acc;
    }, []);

    transformedDevice.push(total.data);

    const transformedData = transformedDevice.map(item => {
        let deviceName = "";
        if (item.device_type === "PC" || item.device === "DESKTOP") {
            deviceName = "컴퓨터"
        } else if (item.device_type === "Mobile" || item.device === "MOBILE") {
            deviceName = "휴대전화"
        } else if (item.device === "TABLET") {
            deviceName = "태블릿"
        } else if (item.device === "CONNECTED_TV") {
            deviceName = "TV 화면"
        } else if (item.device_type === "기타") {
            deviceName = "기타"
        }else if (item.device === "OTHER") {
            return null
        } else {
            deviceName = "총합"
        };

        return {
            "디바이스": deviceName,
            "광고비": item.tot_cost,
            "노출수": item.tot_impression,
            "클릭수": item.tot_click,
            "전환수": parseInt(item.tot_conversion_count, 10),
            "전환 매출액": parseInt(item.tot_conversion_amount, 10),
            "광고비 비율": isNaN((item.tot_cost / totalCost) * 100) ? 0 : ((item.tot_cost / totalCost) * 100).toFixed(1),
            "노출수 비율": isNaN((item.tot_impression / totalImp) * 100) ? 0 : ((item.tot_impression / totalImp) * 100).toFixed(1),
            "클릭수 비율": isNaN((item.tot_click / totalClick) * 100) ? 0 : ((item.tot_click / totalClick) * 100).toFixed(1),
            "전환 매출액 비율": isNaN((item.tot_conversion_amount / totalConvAmount) * 100) ? 0 : ((item.tot_conversion_amount / totalConvAmount) * 100).toFixed(1)
        };
    })
    .filter(item => item !== null)
    .sort((a, b) => {
        const order = ["총합", "휴대전화", "컴퓨터", "태블릿", "TV 화면", "기타"];
        return order.indexOf(a["디바이스"]) - order.indexOf(b["디바이스"]);
    });

    return (
        <div className={styles.daDevice}>
            <div className={styles.daDeviceTitle}>디바이스별 성과 요약</div>
            <div className={styles.daDeviceLables}>
                {mediaInfoId === 7 && <KakaoLabels />}
                {mediaInfoId === 100 && <GoogleLabels />}
            </div>
            {mediaInfoId === 7 && <KakaoDeviceChart transformedData={transformedData} />}
            {mediaInfoId === 100 && <GoogleDeviceChart transformedData={transformedData} />}
            <ReportDaTable 
                headers={headers} 
                items={transformedData} 
                mediaInfoId={mediaInfoId}
            />
        </div>
    )
}