import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import styles from "./advcostreport.module.scss"

import { selectStartDate, selectEndDate } from '../../../store/dateSlice';

import { AdvCostPrev } from "./prev/AdvCostPrev";
import { PredCondition } from "./condition/PredCondition";
import { PredScoreCard } from "./analysis/PredScoreCard";
import { PredImpClick } from "./analysis/PredImpClick";
import { PredAdvResult } from "./analysis/PredAdvResult";
import { MediaPerformance } from "./performance/MediaPerformance";
import Loading from "../../utils/loading/Loading";


const formatDate = (dateString) => {
    const date = new Date(dateString); // 문자열을 Date 객체로 변환
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`; // YYYYMMDD 형식으로 반환
};

export const AdvCostReport = () => {
    const location = useLocation();
    const startDate = formatDate(useSelector(selectStartDate));
    const endDate = formatDate(useSelector(selectEndDate));
    const reportName = location.state?.reportName;
    const incrementId = location.state?.incrementId;
    const linkedData = location.state?.linkedData;
    const [target, setTarget] = useState("conv_count");
    const [tableData, setTableData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [advData, setAdvData] = useState(null);
    const [advLoading, setAdvLoading] = useState(0);
    const [percent, setPercent] = useState({});
    const [changeCost, setChangeCost] = useState({naver: null, kakao: null, google: null, meta: null});

    return (
        <div className={styles.advCost}>
            <div className={styles.advCostHeader}>
                <p>{reportName}</p>
            </div>
            <div className={styles.advCostBody}>
                <p className={styles.advCostDate}>{startDate} ~ {endDate}</p>
                <AdvCostPrev startDate={startDate} endDate={endDate} incrementId={incrementId} tableData={tableData} setTableData={setTableData} loading={loading} setLoading={setLoading} />
                <PredCondition incrementId={incrementId} tableData={tableData} loading={loading} setAdvData={setAdvData} setAdvLoading={setAdvLoading} target={target} setTarget={setTarget} percent={percent} setPercent={setPercent} changeCost={changeCost} setChangeCost={setChangeCost} linkedData={linkedData} />
                {advData && advLoading === 2 ? (
                    <>
                        <p className={styles.advCostDate} style={{marginBottom: "25px"}}>예측 결과 분석</p>
                        <PredScoreCard startDate={startDate} endDate={endDate} advData={advData} />
                        <div className={styles.advCostChart}>
                            <PredImpClick advData={advData} />
                            <PredAdvResult advData={advData} target={target} />
                        </div>
                        <MediaPerformance startDate={startDate} endDate={endDate} incrementId={incrementId} advData={advData} percent={percent} changeCost={changeCost} tableData={tableData} linkedData={linkedData} />
                    </>
                    ) : advData === null && advLoading === 1 || advLoading === 2 ? (
                        <div className={styles.advCostChart}>
                            <Loading />
                        </div>
                    ) : advData === null && advLoading === 0 ? (
                        <></> // 빈 JSX 반환
                    ) : (
                        <></>
                )}
            </div>
        </div>
    )
}