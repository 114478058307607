import React, { useEffect, useImperativeHandle, forwardRef } from "react";
import { GoogleLoginApi } from "../../../api/socialLogin/GoogleLoginApi";

const GoogleLogin = forwardRef((props, ref) => {
    const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID;
    const GOOGLE_REDIRECT_URI = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URI;

    const code = new URL(window.location.href).searchParams.get('code');
    const getToken = async () => {
        if (code) {
            try {
                const response = await GoogleLoginApi(code);
                // console.log(response);
            } catch (err) {
                console.log(err);
            }
        }
    };

    useEffect(() => {
        if (code) {
            getToken();
        }
    }, [code]);

    const GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${GOOGLE_REDIRECT_URI}&response_type=code&state=${Math.random().toString(36).substring(3,14)}&scope=email%20profile%20openid&access_type=offline`;

    const triggerGoogleLogin = () => {
        window.location.href = GOOGLE_AUTH_URL;
    };
    
    useImperativeHandle(ref, () => ({
        triggerGoogleLogin,
    }));

    return null;
});

export default GoogleLogin;
