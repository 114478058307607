import React from "react";

import styles from "./reportcreatepage.module.scss"

import Header from "../../../shared/header/Header";
import Sidebar from "../../../shared/sidebar/Sidebar";
import { ReportCreate } from "../../../components/report/create/ReportCreate";

export const ReportCreatePage = () => {
    return (
        <>
            <Header />
            <Sidebar />
            <ReportCreate />
        </>
    );
};
