import React, { useState, useEffect, useContext } from 'react';

import styles from '../scss/dacampaignsumchart.module.scss';

import { DaCampaignSumGaugeApi, DaCampaignSumLineApi } from '../../../../../api/reportDA/campaign/DaCampaignSumApi';

import { CampaignGauge } from '../../../../utils/chart/CampaignGauge';
import { DaBarChart } from '../../../../utils/chart/DaBarChart';
import Loading from '../../../../utils/loading/Loading';
import { DaCampaignSumTable } from './DaCampaignSumTable';
import { NullBox } from '../../../../utils/nullBox/NullBox';

import { SummaryContext } from '../../../summary/DaSummaryProvider';


export const DaCampaignSumChart = ({mediaInfoId, adAccountsId, startDate, endDate, reportType, mediaName}) => {
    const [data, setData] = useState(null);
    const [gaugeData, setGaugeData] = useState(["0"]);
    const [loading, setLoading] = useState(true);
    const [metricType, setMetricType] = useState('tot_impression');
    const [timeUnit, setTimeUnit] = useState('day');
    const [campaignIds, setCampaignIds] = useState(["0"]);
    const { setCampaign } = useContext(SummaryContext);

    const handleResponse = (response) => {
        const maxRoas = response.data.reduce((maxItem, item) => {
            return (item.roas > (maxItem.roas || 0)) ? item : maxItem
        })

        const minRoas = response.data.reduce((minItem, item) => {
            return (item.roas < (minItem.roas || 0)) ? item : minItem
        });

        setCampaign({low: minRoas.campaign_name, high: maxRoas.campaign_name})
    }

    useEffect(() => {
        const fetchData = async () => {
            const gaugeResponse = await DaCampaignSumGaugeApi(mediaName, startDate, endDate, adAccountsId);
            setGaugeData(gaugeResponse);
            // console.log(gaugeResponse);
            const campaignId = gaugeResponse.data.map(item => item.campaign_id);
            setCampaignIds(campaignId);
            const lineResponse = await DaCampaignSumLineApi(mediaName, startDate, endDate, adAccountsId, campaignId, timeUnit);
            setData(lineResponse);
            // console.log(lineResponse);
            handleResponse(lineResponse);
            setLoading(false);
        };
        fetchData();
    }, [startDate, endDate, metricType, timeUnit, adAccountsId]);

    if (loading) {
        return (
            <div className={styles.daCampaignSum}>
                <Loading />
            </div>
        );
    }

    const isGaugeDataEmpty = (data) => {
        return data.every(item => 
            item.tot_cost === 0 ||
            item.tot_cost == null
        );
    };

    const isLineDataEmpty = (data) => {
        return data.every(item => 
            item.roas === 0 &&
            item.previous_roas === 0
        )
    };

    return (
        <div className={styles.daCampaignSum}>
            <div className={styles.daCampaignSumTitle}>캠페인 요약 내용</div>
            <div className={styles.daCampaignSumChart}>
                <div>
                    <p>캠페인 별 광고비</p>
                    {isGaugeDataEmpty(gaugeData.data) ? (
                        <NullBox />
                    ) : (
                        <CampaignGauge data={gaugeData} selectedData={"tot_cost"} mediaInfoId={mediaInfoId} reportType={reportType} />
                    )}
                </div>
                <div>
                    <p>캠페인 별 광고수익률</p>
                    {isLineDataEmpty(data.data) ? (
                        <NullBox />
                    ) : (
                        <DaBarChart data={data} mediaInfoId={mediaInfoId} label1="광고수익률" label2="이전 광고수익률" reportType={reportType} />
                    )}
                </div>
            </div>
            <DaCampaignSumTable mediaInfoId={mediaInfoId} adAccountsId={adAccountsId} startDate={startDate} endDate={endDate} campaignIds={campaignIds} setCampaignIds={setCampaignIds} gaugeData={gaugeData} reportType={reportType} />
        </div>
    );
};