import React from "react";
import "./modal.scss";
const Modal = ({ show, handleClose, logoutEvent }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="logout-modal-backdrop" onClick={handleClose}>
      <div className="logout-modal-content">
        <div className="logout-modal-content-title">
          <h2>로그아웃</h2>
        </div>
        <div className="logout-modal-content-description">
          <p>로그아웃 하시겠습니까?</p>
        </div>
        <div className="logout-modal-button-contatiner">
          <button className="logout-modal-cancle">취소</button>
          <button className="logout-modal-ok" onClick={logoutEvent}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
