import React, { useRef } from "react";

import styles from "./preview.module.scss";
import { ReactComponent as RightBtn } from "../../../assets/images/home/home-preview-rightBtn.svg"
import { ReactComponent as LeftBtn } from "../../../assets/images/home/home-preview-leftBtn.svg"


import { NaverSA } from "./content/NaverSA";
import { NaverGFA } from "./content/NaverGFA.";
import { MetaContent } from "./content/Meta";
import { KakaoMoment } from "./content/KakaoMoment";
import { KakaoKeyword } from "./content/KakaoKeyword";
import { GoogleSA } from "./content/GoogleSA";
import { GoogleDA } from "./content/GoogleDA";
import { GoogleA4 } from "./content/GoogleA4";
import { Predict } from "./content/Predict";
import { Attribution } from "./content/Attribution";
import { Segment } from "./content/Segment";
import { Rfm } from "./content/Rfm";
import { TotalGa4 } from "./content/TotalGa4";
import { TotalMedia } from "./content/TotalMedia";

import { Report } from "./report/Report";


export const PreView = ({mediaData}) => {
    const previewBoxRef = useRef(null);

    const scrollLeft = () => {
        if (previewBoxRef.current) {
            previewBoxRef.current.scrollBy({
                left: -240, // 스크롤할 거리 (픽셀 단위)
                behavior: 'smooth' // 스크롤 애니메이션
            });
        }
    };

    const scrollRight = () => {
        if (previewBoxRef.current) {
            previewBoxRef.current.scrollBy({
                left: 240, // 스크롤할 거리 (픽셀 단위)
                behavior: 'smooth' // 스크롤 애니메이션
            });
        }
    };

    const handlePreviewClick = () => {
        window.location.href = '/report';
    }

    return (
        <>
            <div className={styles.main}>
                <p className={styles.previewTxt}>데이터 분석 보고서 미리보기</p>
                <div className={styles.previewControls}>
                    <div onClick={scrollLeft} className={styles.scrollButton}><LeftBtn /></div>
                    <div className={styles.previewBox} ref={previewBoxRef}>
                        <NaverSA handlePreviewClick={handlePreviewClick} />
                        <KakaoKeyword handlePreviewClick={handlePreviewClick} />
                        <GoogleSA handlePreviewClick={handlePreviewClick} />
                        <KakaoMoment handlePreviewClick={handlePreviewClick} />
                        <GoogleDA handlePreviewClick={handlePreviewClick} />
                        {/* <Predict handlePreviewClick={handlePreviewClick} /> */}
                        {/* <NaverGFA handlePreviewClick={handlePreviewClick} /> */}
                        {/* <TotalGa4 handlePreviewClick={handlePreviewClick} /> */}
                        {/* <TotalMedia handlePreviewClick={handlePreviewClick} /> */}
                        {/* <Segment handlePreviewClick={handlePreviewClick} /> */}
                        {/* <GoogleA4 handlePreviewClick={handlePreviewClick} /> */}
                        {/* <MetaContent handlePreviewClick={handlePreviewClick} /> */}
                        {/* <Attribution handlePreviewClick={handlePreviewClick} /> */}
                        {/* <Rfm /> */}
                    </div>
                    <div onClick={scrollRight} className={styles.scrollButton}><RightBtn /></div>
                </div>
                <Report mediaData={mediaData} handlePreviewClick={handlePreviewClick} />
            </div>
        </>
    );
}
