import React from 'react';

import Header from '../../shared/header/Header';
import Sidebar from '../../shared/sidebar/Sidebar';
import { PeriodReport } from '../../components/predict/period/PeriodReport';

export const PeriodPage = () => {
    return (
        <>
            <Header />
            <Sidebar />
            <PeriodReport />
        </>
    );
};

