import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';

import UserSlice from "./UserSlice";
import SelectedSlice from "./SelectedSlice";
import dateSlice from "./dateSlice";
import PathSlice from "./PathSlice";
import SocialSlice from "./SocialSlice";

const preloadedState = {
    user: {
        username: "",
        approve: "",
    },
    date: {
        startDate: "",
        endDate: ""
    },
    selected: {
        setSelected: "",
        resetSelected: "",
        setMenu: "",
        resetMenu: ""
    },
    path: {
        path: "",
        resetPath: ""
    },
    social: {
        Code: "",
    }
};

const reducers = combineReducers({
    user: UserSlice.reducer,
    selected: SelectedSlice.reducer,
    date: dateSlice,
    path: PathSlice.reducer,
    social: SocialSlice.reducer
})

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user','selected','date','path','social']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
        },
    }),
});

export default store;
