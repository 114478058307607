import React, { useState } from "react";

import styles from "./modal.module.scss";

export const ModifyModal = ({
    reportName,
    incrementId,
    onClose,
    onConfirm,
}) => {
    const [newName, setNewName] = useState("");

    const handleChange = (e) => {
        setNewName(e.target.value);
    };

    const handleConfirm = () => {
        onConfirm(incrementId, newName);
    };

    return (
        <div
            className={styles.modifyModal}
            onClick={(e) => e.stopPropagation()}
        >
            <p>이름 수정하기</p>
            <input
                value={newName}
                onChange={handleChange}
                placeholder={reportName}
            />
            <div className={styles.modifyBtnBox}>
                <button className={styles.modifyCloseBtn} onClick={onClose}>
                    취소
                </button>
                <button
                    className={styles.modifyChangeBtn}
                    onClick={handleConfirm}
                >
                    적용
                </button>
            </div>
        </div>
    );
};
