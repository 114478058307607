import axios from "../../axios";

export const DaCostFunnelApi = async ( media, startDate, endDate, accountId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/costs/funnel-report/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/costs/funnel-report/`;
    } else {
        path = `${media}/${accountId}/costs/funnel-report/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate
            }
        });
        return data;
    } catch (error) {
        console.error("DA 비용 퍼널 리포트 오류 발생:", error);
        throw error;
    }
};