import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import styles from "./mydata.module.scss"
import {ReactComponent as Graph} from "../../../../assets/images/share/menu-graph.svg"
import { ReactComponent as DataLinkGa4 } from "../../../../assets/images/datalink/datalink-ga4.svg"
import { ReactComponent as DataLinkGoogle } from "../../../../assets/images/share/google.svg"
import { ReactComponent as DataLinkNaver } from "../../../../assets/images/share/naver.svg"
import { ReactComponent as DataLinkKakao } from "../../../../assets/images/share/kakao.svg"
import { ReactComponent as DataLinkFacebook } from "../../../../assets/images/share/facebook.svg"

import { AdAccountsApi } from "../../../../api/dataCenter/AdAccountsApi";
import Loading from "../../../utils/loading/Loading";

export const MyData = ({setMediaData}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const username = useSelector((state) => state.user.username);

    useEffect(() => {
        const fetchData = async () => {
            const response = await AdAccountsApi(username);
            setData(response);
            setLoading(false);
            setMediaData(response);
            // console.log(response)
        };

        fetchData();
    }, [username]);

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    const renderImage = (media_info_code) => {
        switch (media_info_code) {
            case 5:
                return <DataLinkNaver />;
            case 6:
                return <DataLinkNaver />;
            case 7:
                return <DataLinkKakao />;
            case 10:
                return <DataLinkFacebook />;
            case 89:
                return <DataLinkKakao />;
            case 100:
                return <DataLinkGoogle />;
            case 101:
                return <DataLinkGa4 />;
            default:
                return <></>;
        }
    };

    const renderMediaName = (media_info_code) => {
        switch (media_info_code) {
            case 5:
                return "네이버 검색광고";
            case 6:
                return "네이버 GFA";
            case 7:
                return "카카오모먼트";
            case 10:
                return "페이스북";
            case 89:
                return "카카오키워드";
            case 100:
                return "구글 광고";
            case 101:
                return "구글 애널리틱스 4";
            default:
                return "";
        }
    };
    
    return (
        <>
            <p className={styles.myDataTxt}>내가 연결한 데이터</p>
            {data.data.length > 0 ? (
                <div className={styles.myDataBoxConnected}>
                    {data.data.map((item) => (
                        <div key={item.increment_id} className={styles.dataItem}>
                            {renderImage(item.media_info_code)}
                            <div className={styles.myDataTxtBox}>
                                <p className={styles.reportName}>{item.link_data_nm}</p>
                                <p className={styles.mediaName}>{renderMediaName(item.media_info_code)}</p>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className={styles.myDataBox}>
                    <Graph />
                    <p className={styles.myDataBoxTxt}>데이터를 연결해주세요.</p>
                </div>
            )}
            <div className={styles.myDataBtn}>
                <button>
                    <Link to={'/datalink'}>데이터 센터로 이동하기</Link>
                </button>
            </div>
        </>
    );
};