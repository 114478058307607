import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "../../scss/social/ga4redirect.module.scss"
import { ReactComponent as ModalClose } from "../../../../../assets/images/share/modal-close.svg";

import { Ga4OneStep } from "./Ga4OneStep";
import { Ga4TwoStep } from "./Ga4TwoStep";
import { Ga4TrdStep } from "./Ga4TrdStep";
import { Ga4RequestList } from "./Ga4RequestList";
import Home from "../../../../../page/home/Home";

export const Ga4Redirect = () => {
    const [modalOpen, setModalOpen] = useState(true);
    const [step, setStep] = useState(0);
    const navigate = useNavigate();

    const closeModal = () => {
        setModalOpen(false);
        navigate("/datalink");
    };

    const handleNextStep = () => {
        const nextStep = step + 1;
        setStep(nextStep);
    };

    const handlePrevStep = () => {
        const prevStep = step - 1;
        setStep(prevStep);
    };

    const handleHomeStep = () => {
        setStep(0)
    };

    return (
        <>
            <Home />
            {modalOpen && (
                <>
                    {step === 0 && <Ga4RequestList closeModal={closeModal} handleNextStep={handleNextStep} handleHomeStep={handleHomeStep} />}
                    {step === 1 && <Ga4OneStep closeModal={closeModal} handleNextStep={handleNextStep} />}
                    {step === 2 && <Ga4TwoStep closeModal={closeModal} handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} />}
                    {step === 3 && <Ga4TrdStep closeModal={closeModal} handleHomeStep={handleHomeStep} />}
                </>
            )}
        </>
    );
};
