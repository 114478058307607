import React from 'react';

import Header from '../../../shared/header/Header';
import Sidebar from '../../../shared/sidebar/Sidebar';

import { ReportSA } from '../../../components/report/sa/ReportSA';

export const ReportKakaoSA = () => {
    return (
        <>
            <Header />
            <Sidebar />
            <ReportSA media={"kakao"} />
        </>
    );
};

