import React from "react";

import styles from "../scss/mediaperformance.module.scss"
import { ReactComponent as Labels } from "../../../../assets/images/predict/pred-adv-line-labels.svg"

import { PredLine } from "../../../utils/chart/PredChart";

export const PerformanceLine = ({ data, mediaData }) => {
    return (
        <div className={styles.PerformanceLine}>
            <div className={styles.lineLabels}>
                <Labels />
            </div>
            <PredLine data={data} mediaData={mediaData} width="1120px" height="320px" pointRadius="2" />
            <div className={styles.lineBorder}></div>
        </div>
    )
}