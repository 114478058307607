import "./header.scss";
import { ReactComponent as UserMark } from "../../assets/images/share/user.svg";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as LogoMark } from "../../assets/images/share/adinsightLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import { setSelected, resetSelected, resetMenu } from "../../store/SelectedSlice";
import { clearUser } from "../../store/UserSlice";
import { resetPath } from "../../store/PathSlice";
import { resetCode } from "../../store/SocialSlice";

import Modal from "./Modal";
import { LogOutApi } from "../../api/accounts/logout";
import { deleteAccessToken } from "../../api/axios";

const Header = () => {
  const user = useSelector((state) => state.user);
  // username 수정
  return (
    <>
      {user.username !== "" ? <LoggedInView user={user} /> : <LoggedOutView />}
    </>
  );
};

const LoggedInView = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const username = user.username.split('@')[0];
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const dropdownClicked = () => {
    setIsOpen(!isOpen);
  };
  const [showModal, setShowModal] = useState(false); // 모달 상태
  const dropdownItemClickEvent = (page) => {
    if (page === "logout") {
      setShowModal(true);
    }
    if (page === "mypage") {
      navigate("/mypage");
    }
  };

  const dispatch = useDispatch();

  const logoutEvent = async () => {
    // 로그아웃 시 로컬 스토리지에있는 access toekn들 삭제 후 다시 저장
    // SameSite 이슈 해결 후 쿠키방식으로 변경 예정
    // navigate("/");
    try {
      const response = await LogOutApi();
      // console.log(`RESPONSE : ${response}`);
      if (response && response.status === 200) {
        dispatch(clearUser());
        dispatch(resetPath());
        dispatch(resetMenu());
        dispatch(resetSelected());
        dispatch(resetCode());
        deleteAccessToken();
        navigate("/");
      } else {
        console.log("LOGOUT FAIL!", response);
      }
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <nav className="main-header">
      <div className="header-text-box">
        <div className="header-text-1">
          <Link to="/home" onClick={() => dispatch(setSelected("ga4Data"))}>
            <LogoMark />
          </Link>
        </div>
        <p className="header-text-2">데이터 분석 솔루션</p>
      </div>
      <div className="user-box" onClick={dropdownClicked}>
        <div className="user-image">
          <UserMark />
        </div>
        <div className="user-text">{username}</div>
        {isOpen && (
          <div className="dropdown" ref={dropdownRef}>
            <ul className="dropdown-ul">
              <li
                className="dropdown-item myPage"
                onClick={() => dropdownItemClickEvent("mypage")}
              >
                마이페이지
              </li>
              <li
                className="dropdown-item logout"
                onClick={() => dropdownItemClickEvent("logout")}
              >
                로그아웃
              </li>
            </ul>
          </div>
        )}
      </div>
      
      <Modal
        show={showModal}
        handleClose={() => setShowModal(false)}
        logoutEvent={logoutEvent}
      />
    </nav>
  );
};

export const LoggedOutView = () => {
  return (
    <nav className="main-header">
      <div className="header-text-box">
        <div className="header-text-1">
          <Link to="/">
            <LogoMark />
          </Link>
        </div>
        <p className="header-text-2">데이터 분석 솔루션</p>
      </div>
    </nav>
  );
};

export default Header;
