import React, { useState } from "react";

import styles from "../weekly/weeklyperiod.module.scss"

import { PrevWeekly } from "../weekly/prev/PrevWeekly";
import { ResultWeekly } from "../weekly/result/ResultWeekly";

const formatDate = (dateString) => {
    const date = dateString.replace(/-/g, '');
    return date;
}

export const MonthlyPeriod = ({ startDate, endDate, incrementId, linkedData }) => {
    const [date, setDate] = useState(null);
    const [loading, setLoading] = useState(true);

    return (
        <div className={styles.weekly}>
            {date && (
                <p className={styles.weeklyTitle}> {formatDate(date[0])} ~ {endDate} </p>
            )}
            <PrevWeekly incrementId={incrementId} reportType="monthly" setDate={setDate} setLoading={setLoading} />
            <p className={styles.weeklyTitle}>월간 예측 결과</p>
            <ResultWeekly startDate={startDate} endDate={endDate} incrementId={incrementId} reportType="monthly" linkedData={linkedData} date={date} />
        </div>
    )
}