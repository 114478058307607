import React, { useState } from "react";

import styles from "./modal.module.scss";

export const DeleteModal = ({
    reportName,
    incrementId,
    onClose,
    onConfirm,
}) => {
    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const isMatch = inputValue === reportName;

    return (
        <div
            className={styles.modalBackdrop}
            onClick={(e) => e.stopPropagation()}
        >
            <div className={styles.modalContent}>
                <p>해당 보고서를 삭제하시겠습니까?</p>
                <input
                    placeholder={reportName}
                    value={inputValue}
                    onChange={handleInputChange}
                />
                <div className={styles.btnBox}>
                    <button className={styles.closeBtn} onClick={onClose}>
                        취소
                    </button>
                    <button
                        className={
                            isMatch
                                ? styles.unlinkBtnActive
                                : styles.unlinkBtnDisabled
                        }
                        onClick={() => isMatch && onConfirm(incrementId)}
                        disabled={!isMatch}
                    >
                        삭제
                    </button>
                </div>
            </div>
        </div>
    );
};
