import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    path: 'ga4Data'
};

const PathSlice = createSlice({
    name: 'path',
    initialState,
    reducers: {
        path: (state, action) => {
            state.path = action.payload;
        },
        resetPath: (state) => {
            state.path = '';
        },
    },
});

export const { path, resetPath } = PathSlice.actions;

export default PathSlice;
