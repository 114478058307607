import React, { useState, useEffect } from "react";

import { DaPageTableApi, DaPageTableTotalApi } from "../../../../../api/reportDA/creative/DaPageTableApi";

import Loading from "../../../../utils/loading/Loading";
import { PredTable } from "../../../../utils/table/PredTable";


export const PrevWeekTable = ({data}) => {
    const headers = ["매체", "상품", "광고비", "노출수", "클릭수", "클릭률", "전환수", "전환 매출액", "광고수익률"]

    const transformedData = data.map(item => ({
        "매체": item.media,
        "상품": item.landing_type,
        "광고비": item.total_cost,
        "노출수": item.total_impression,
        "클릭수": item.total_click,
        "클릭률": item.ctr,
        "전환수": item.total_conversion_cnt,
        "전환 매출액": item.total_conversion_amount,
        "광고수익률": item.roas,
    }));


    return (
        <>
            <PredTable 
                headers={headers} 
                items={transformedData} 
                // mediaInfoId={mediaInfoId}
            />
        </>
    );
}