import axios from "../../axios";

export const DaCampTypeApi = async ( media, startDate, endDate, accountId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/campaign-type-result-analysis/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/campaign-type-analysis/`;
    } else {
        path = `${media}/${accountId}/campaigns/campaign-type-analysis/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
            }
        });
        return data;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 캠페인 유형별 성과 분석 오류 발생:", error);
        throw error;
    }
};

export const DaCampTypeTotalApi = async ( media, startDate, endDate, accountId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/campaign-type-result-analysis-total/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/campaign-type-analysis-total/`;
    } else {
        path = `${media}/${accountId}/campaigns/campaign-type-analysis-total/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate
            }
        });
        return data;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 캠페인 유형별 성과 분석 총합 오류 발생:", error);
        throw error;
    }
};