import axios, { deleteAccessToken } from "../axios";

export const checkTokenAPI = async () => {
  try {
    const response = await axios.get("common/check-token/");
    return response; // 응답 객체 반환
  } catch (error) {
    const status = error.response.status;
    if (status !== 200) {
      deleteAccessToken();
      console.error("토큰 체크중 에러", error);
      throw error; // 오류 발생 시 예외 던지기
    }
  }
};
