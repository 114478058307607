import React, { useState, useEffect, useContext } from 'react';

import styles from '../scss/topkeyword.module.scss';

import { SummaryContext } from '../../../summary/SummaryProvider';

import Loading from '../../../../utils/loading/Loading';

import { KeywordTopTenApi } from '../../../../../api/report/keyword/KeywordTopTenApi';

export const TopKeyword = ({adAccountsId, startDate, endDate, mediaName}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { setTopKeyword } = useContext(SummaryContext);

    const handleResponse = (response) => {
        // response 값이 있을 때만 실행
        if (!response || !response.data || response.data.length === 0) return;
        // console.log(response)
        // 가장 높은 conversion_count, conversion_amount, roas를 가진 keyword_name 추출
        const maxConvCount = response.data.reduce((maxItem, item) => {
            return (parseInt(item.tot_conversion_count, 10) > (parseInt(maxItem.tot_conversion_count, 10) || 0)) ? item : maxItem
        });
        const highestConversionAmount = response.data.reduce((maxItem, item) => {
            return (parseInt(item.tot_conversion_amount, 10) > (parseInt(maxItem.tot_conversion_amount, 10) || 0)) ? item : maxItem
        });
        const maxRoas = response.data.reduce((maxItem, item) => {
            return (item.roas > (maxItem.roas || 0)) ? item : maxItem
        });

        setTopKeyword({convAmount: highestConversionAmount.keyword_name, convCount: maxConvCount.keyword_name, roas: maxRoas.keyword_name})
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await KeywordTopTenApi(mediaName, startDate, endDate, adAccountsId);
            // console.log(response)
            setData(response);
            setLoading(false);
            handleResponse(response);
        };
        fetchData();
    }, [startDate, endDate, adAccountsId])

    if (loading) {
        return (
            <div className={styles.topKeyword}>
                <Loading />
            </div>
        );
    }

    if (!data || (Array.isArray(data.data) && data.data.length === 0)) {
        return null;
    }

    const transformedData = data.data.map(item => ({
        "키워드": item.keyword_name,
        "PC/MO": item.device_type,
        "전환수": parseFloat(item.tot_conversion_count),
        "전환매출액": parseFloat(item.tot_conversion_amount),
        "광고수익률": item.roas_variance_operator === 'up' 
            ? `${item.roas.toFixed(2)}%(▲${item.roas_variance.toFixed(0)}%p)` 
            : item.roas_variance_operator === 'down' 
            ? `${item.roas.toFixed(2)}%(▼${item.roas_variance.toFixed(0)}%p)` 
            : `${item.roas.toFixed(2)}%(${item.roas_variance.toFixed(0)}%p)`,
        "roas_variance_operator": item.roas_variance_operator
    }));

    const maxConversion = Math.max(...transformedData.map(item => item.전환수));
    const maxRevenue = Math.max(...transformedData.map(item => item.전환매출액));

    return (
        <div className={styles.topKeyword}>
            <div className={styles.topKeywordTitle}>매출 상위 10개 키워드</div>
            <div className={styles.topKeywordTable}>
                <div className={styles.topKeywordTableHeader}>
                    {Object.keys(transformedData[0]).filter(key => key !== 'roas_variance_operator').map((key, index) => ( // roas_variance_operator 제외
                        <div key={index} className={styles.headerCell}>{key}</div>
                    ))}
                </div>
                <div className={styles.topKeywordTableBody}>
                    {transformedData.map((item, rowIndex) => (
                        <div key={rowIndex} className={styles.tableRow}>
                            {Object.entries(item).filter(([key]) => key !== 'roas_variance_operator').map(([key, value], cellIndex) => ( // roas_variance_operator 제외
                                <div key={cellIndex} className={styles.tableCell}>
                                    {(key === '전환수') ? (
                                        <div className={styles.cellContent}>
                                            <span>{value.toLocaleString()}</span>
                                            <div 
                                                className={styles.bar}
                                                style={{
                                                    width: `${(value / (key === '전환수' ? maxConversion : maxRevenue)) * 100}%`,
                                                    backgroundColor: key === '전환수' ? '#D1EFDE' : '#D6EBF9'
                                                }}
                                            />
                                        </div>
                                    ) : (key === '전환매출액') ? (
                                        <div className={styles.cellContent}>
                                            <span>₩{value.toLocaleString()}</span>
                                            <div 
                                                className={styles.bar}
                                                style={{
                                                    width: `${(value / (key === '전환수' ? maxConversion : maxRevenue)) * 100}%`,
                                                    backgroundColor: key === '전환수' ? '#D1EFDE' : '#D6EBF9'
                                                }}
                                            />
                                        </div>
                                    ) : key === '광고수익률' ? (
                                        <span style={{ color: item.roas_variance_operator === 'up' ? 'blue' : item.roas_variance_operator === 'down' ? 'red' : 'gray', fontSize: "11px" }}>
                                            {value}
                                        </span>
                                    ) : (
                                        <>
                                            <p>{value}</p>
                                            <span className={styles.tooltipTxt}>{value}</span>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
