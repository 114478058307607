import React from 'react';

import Header from '../../shared/header/Header';
import Sidebar from '../../shared/sidebar/Sidebar';
import { AdvCostReport } from '../../components/predict/advcost/AdvCostReport';

export const AdvCostPage = () => {
    return (
        <>
            <Header />
            <Sidebar />
            <AdvCostReport />
        </>
    );
};

