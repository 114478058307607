import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "../../scss/social/ga4twostep.module.scss"
import { ReactComponent as ModalClose } from "../../../../../assets/images/share/modal-close.svg";
import { ReactComponent as Step } from "../../../../../assets/images/datalink/datalink-ga4-steptwo.svg"

export const Ga4TwoStep = ({closeModal, handleNextStep, handlePrevStep}) => {

    return (
        <div className={styles.modalBackdrop}>
            <div className={styles.modalContent}>
                <div className={styles.modalCloseBtn}>
                    <div onClick={closeModal} style={{ cursor: "pointer" }}>
                        <ModalClose />
                    </div>
                </div>
                <div className={styles.modalText}>
                    <p className={styles.text1}>데이터 선택하기</p>
                    <p className={styles.text2}>GA4 데이터 연결을 요청해주세요.</p>
                </div>
                <div className={styles.stepBox}>
                    <Step />
                    <div className={styles.stepTxtBox}>
                        <p className={styles.stepTxt1}>데이터 수집 동의</p>
                        <p className={styles.stepTxt2}>GA4 편집자 권한 부여 및 정보 입력</p>
                    </div>
                </div>
                <div className={styles.infoInputBox}>
                    <p className={styles.infoTxt1}>GA4 편집자 권한 부여 및 정보 입력</p>
                    <p className={styles.infotxt2}>아래의 가이드를 참고하여 GA4 편집자 권한을 부여 후 필요한 정보를 입력해주세요.</p>
                    <div className={styles.guideBtn}>
                        <button>
                            <div>가이드 확인하기</div>
                            <p>▶</p>
                        </button>
                    </div>
                    <div className={styles.propertyInputBox}>
                        <p className={styles.propertyInputTxt}>GA4 속성 아이디 입력 및 편집자 권한 확인</p>
                        <div className={styles.propertyInput}>
                            <input
                                placeholder="GA4 속성 아이디를 입력해주세요."
                            />
                            <button>권한 확인하기</button>
                        </div>
                    </div>
                    <div className={styles.nameInputBox}>
                        <p className={styles.nameInputTxt}>데이터 이름 입력</p>
                        <div className={styles.nameInput}>
                            <input
                                placeholder="저장할 이름을 입력해주세요."
                            />
                        </div>
                    </div>
                    <div className={styles.btnBox}>
                        <button className={styles.prevBtn} onClick={handlePrevStep}>이전</button>
                        <button className={styles.nextBtn} onClick={handleNextStep}>제출</button>
                    </div>




                </div>
            </div>
        </div>
    );
}