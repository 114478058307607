import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "../../scss/social/ga4trdstep.module.scss"
import { ReactComponent as ModalClose } from "../../../../../assets/images/share/modal-close.svg";
import { ReactComponent as Graph } from "../../../../../assets/images/datalink/datalink-ga4-success.svg"

export const Ga4TrdStep = ({closeModal, handleHomeStep}) => {
    const navigate = useNavigate();

    return (
        <div className={styles.modalBackdrop}>
            <div className={styles.modalContent}>
                <div className={styles.modalCloseBtn}>
                    <div onClick={closeModal} style={{ cursor: "pointer" }}>
                        <ModalClose />
                    </div>
                </div>
                <div className={styles.modalText}>
                    <p className={styles.text1}>데이터 선택하기</p>
                    <p className={styles.text2}>GA4 데이터 연결을 요청해주세요.</p>
                </div>
                <div className={styles.successTxtBox}>
                    <p className={styles.successTxt1}>요청 완료</p>
                    <Graph />
                    <p className={styles.successTxt2}>정상적으로 연결 요청이 완료되었습니다.</p>
                    <p className={styles.successTxt2}>영업일 기준 1~3일 소요되며,</p>
                    <p className={styles.successTxt2}>요청 내역에서 결과를 확인하실 수 있습니다.</p>
                </div>
                <div className={styles.requestInfo}>
                    <p className={styles.requestTxt1}>요청 정보</p>
                    <div className={styles.requestData}>
                        데이터 이름
                        <p className={styles.requestTxt2}>Demo Account</p>
                    </div>
                </div>
                <div className={styles.requestInfo}>
                    <p className={styles.requestTxt1}>메일</p>
                    <div className={styles.requestData}>
                        abcd@triplehm.com
                    </div>
                </div>
                <div className={styles.btnBox}>
                    <button onClick={handleHomeStep} >요청 내역으로 돌아가기</button>
                </div>
            </div>
        </div>
    );
} 