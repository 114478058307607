import axios from "../axios";

export const DeleteReportApi = async (incrementId) => {
    try {
        const { data } = await axios.delete("report/create/", {
            params: {
                incrementId: incrementId
            }
        });
        return data;
    } catch (error) {
        console.error("연결 해지 중 오류 발생:", error);
        throw error;
    }
};

export const DeletePredictApi = async (incrementId) => {
    const path = `prediction-model/reports/${incrementId}/`
    try {
        const { data } = await axios.delete(path);
        return data;
    } catch (error) {
        console.error("연결 해지 중 오류 발생:", error);
        throw error;
    }
};