import axios from "../../axios";

export const DaCostDeviceGraphApi = async ( media, startDate, endDate, accountId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/costs/device`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/costs/device/`;
    } else {
        path = `${media}/${accountId}/costs/device/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate
            }
        });
        return data;
    } catch (error) {
        console.error("DA 디바이스별 성과 요약 그래프 오류 발생:", error);
        throw error;
    }
};

export const DaCostDeviceTotalApi = async ( media, startDate, endDate, accountId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/costs/device-total/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/costs/device-total/`;
    } else {
        path = `${media}/${accountId}/costs/device-total/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate
            }
        });
        return data;
    } catch (error) {
        console.error("DA 디바이스별 성과 요약 총합 오류 발생:", error);
        throw error;
    }
};