import axios from "../../axios";

export const DaCostAvgChartApi = async ( media, startDate, endDate, accountId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/costs/dayweek-graph`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/costs/dayweek-graph/`;
    } else {
        path = `${media}/${accountId}/costs/dayweek-graph/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate
            }
        });
        return data;
    } catch (error) {
        console.error("DA 요일별 평균 매출 및 평균 광고 비용 오류 발생:", error);
        throw error;
    }
};