import axios from "../../axios";

export const ClickDayPerformanceDataApi = async (media, startDate, endDate, accountId, page, row, orderBy ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/keywords/${accountId}/clicks/ad-result/`;
    } else if (media === 'googleads') {
        path = `${media}/sa/${accountId}/clicks/ad-result/`;
    } else {
        path = `${media}/${accountId}/clicks/ad-result/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
                page_size: page,
                row_num: row,
                order_by: orderBy
            }
        });
        return data;
    } catch (error) {
        console.error("일일성과 데이터 오류 발생:", error);
        throw error;
    }
};

export const ClickDayPerformanceTotalApi = async (media, startDate, endDate, accountId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/keywords/${accountId}/clicks/ad-result-total/`;
    } else if (media === 'googleads') {
        path = `${media}/sa/${accountId}/clicks/ad-result-total/`;
    } else {
        path = `${media}/${accountId}/clicks/ad-result-total/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
            }
        });
        return data;
    } catch (error) {
        console.error("일일성과 총합 데이터 오류 발생:", error);
        throw error;
    }
};

export const ClickDayPerformanceDownloadApi = async (media, startDate, endDate, accountId, fields, orderBy, responseType) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/keywords/${accountId}/clicks/ad-result-download/`;
    } else if (media === 'googleads') {
        path = `${media}/sa/${accountId}/clicks/ad-result-download/`;
    } else {
        path = `${media}/${accountId}/clicks/ad-result-download/`;
    }
    try {
        const response = await axios.get(path, {
            responseType: responseType,
            params: {
                start_dt: startDate,
                end_dt: endDate,
                fields: fields,
                order_by: orderBy
            }
        });
        return response;
    } catch (error) {
        console.error("일일성과 엑셀 다운로드 오류 발생:", error);
        throw error;
    }
};