import axios from "../axios";

export const ChangeNameApi = async (incrementId, newName) => {
    try {
        const { data }  = await axios.patch("/common/ad-accounts/", { 
            incrementId: incrementId,
            link_data_nm: newName
        });
        return data;
    } catch (error) {
        throw error;
    }
};