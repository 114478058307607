import axios from "../../axios";

export const KeywordTrendApi = async (media, startDate, endDate, accountId) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/keywords/${accountId}/keywords/word-cloud/`;
    } else if (media === 'googleads') {
        path = `${media}/sa/${accountId}/keywords/word-cloud/`;
    } else {
        path = `${media}/${accountId}/keywords/word-cloud/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate
            }
        });
        return data;
    } catch (error) {
        console.error("연결 해지 중 오류 발생:", error);
        throw error;
    }
};