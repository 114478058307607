import React from "react";
import { useSearchParams } from "react-router-dom";

import { MediaGuide } from "../../components/guide/MediaGuide";

export const GuidePage = () => {
    const [searchParams] = useSearchParams();
    const selectedLink = searchParams.get("link");

    return (
        <>
            <MediaGuide selectedLink={selectedLink} />
        </>
    )
}