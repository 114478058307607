import React, { useState, useEffect } from 'react';

import styles from '../scss/clicktrend.module.scss';
import { ReactComponent as KakaoImpLabels } from "../../../../../assets/images/report/report-trend-imp-kakao-labels.svg";
import { ReactComponent as KakaoClickLabels } from "../../../../../assets/images/report/report-trend-click-kakao-labels.svg";
import { ReactComponent as NaverImpLabels } from "../../../../../assets/images/report/report-trend-imp-naver-labels.svg";
import { ReactComponent as NaverClickLabels } from "../../../../../assets/images/report/report-trend-click-naver-labels.svg";
import { ReactComponent as GoogleImpLabels } from "../../../../../assets/images/report/report-trend-imp-google-labels.svg";
import { ReactComponent as GoogleClickLabels } from "../../../../../assets/images/report/report-trend-click-google-labels.svg";

import { ClickTrendApi } from '../../../../../api/report/click/ClickTrendApi';

import { BarLine } from '../../../../utils/chart/BarLine';
import Loading from '../../../../utils/loading/Loading';
import { NullBox } from '../../../../utils/nullBox/NullBox';

export const ClickTrend = ({mediaInfoId, adAccountsId, startDate, endDate, mediaName}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState("day");
    const [selectedData, setSelectedData] = useState("tot_impression");

    const labels = () => {
        if (mediaInfoId === 5 && selectedData === "tot_impression") {
            return <NaverImpLabels />
        } else if (mediaInfoId === 5 && selectedData === "tot_click") {
            return <NaverClickLabels />
        } else if (mediaInfoId === 89 && selectedData === "tot_impression") {
            return <KakaoImpLabels />
        } else if (mediaInfoId === 89 && selectedData === "tot_click") {
            return <KakaoClickLabels />
        } else if (mediaInfoId === 100 && selectedData === "tot_impression") {
            return <GoogleImpLabels />
        } else if (mediaInfoId === 100 && selectedData === "tot_click") {
            return <GoogleClickLabels />
        }
    };

    const barLabel = () => {
        if (selectedData === "tot_impression") {
            return "노출수"
        } else if (selectedData === "tot_click") {
            return "클릭수"
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await ClickTrendApi(mediaName, startDate, endDate, adAccountsId, selectedValue);
            setData(response);
            setLoading(false);
            // console.log(response)
        };
        fetchData();
    }, [startDate, endDate, selectedValue, adAccountsId])

    if (loading) {
        return (
            <div className={styles.clickTrend}>
                <Loading />
            </div>
        );
    }

    const isDataEmpty = (data) => {
        return data.every(item => 
            item.tot_conversion_amount === 0 &&
            item.tot_click === 0 &&
            item.tot_impression === 0
        );
    };

    return (
        <div className={styles.clickTrend}>
            <div className={styles.clickTrendTitle}>노출/클릭 추세</div>
            <div className={styles.clickTrendSelect}>
                <div className={styles.trendBtn}>
                    <button 
                        className={selectedData === "tot_impression" ? styles[`activeTrend${mediaInfoId}`] : styles[`inactiveTrend${mediaInfoId}`]}
                        onClick={() => setSelectedData("tot_impression")}
                    >
                        노출수
                    </button>
                    <button 
                        className={selectedData === "tot_click" ? styles[`activeTrend${mediaInfoId}`] : styles[`inactiveTrend${mediaInfoId}`]}
                        onClick={() => setSelectedData("tot_click")}
                    >
                        클릭수
                    </button>
                </div>
                <select className={styles[`selectBox${mediaInfoId}`]} value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)}>
                    <option value="day">일자별</option>
                    <option value="week">주별</option>
                    <option value="month">월별</option>
                </select>
            </div>
            <div className={styles.chartLabels}>{labels()}</div>
            <div className={styles.clickTrendChart}>
                {isDataEmpty(data.data) ?  (
                    <NullBox />
                ) : (
                    <BarLine data={data} lineLabel="전환 매출액" barLabel={barLabel()} mediaInfoId={mediaInfoId} value={selectedData} />
                )}
            </div>
        </div>
    );
}