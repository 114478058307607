import React from "react";

import styles from "../scss/resultweekly.module.scss"
import { ReactComponent as Labels } from "../../../../../assets/images/predict/pred-result-line-labels.svg"

import { PredLine } from "../../../../utils/chart/PredChart";

export const ResultLine = ({ data, mediaData }) => {

    return (
        <div className={styles.resultBarLine}>
            <div className={styles.lineBorder}></div>
            <Labels />
            <PredLine data={data} mediaData={mediaData} width="550px" height="270px" pointRadius="2" />
        </div>
    )
}