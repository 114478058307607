import axios from "../axios"

export const AdAccountsApi = async (username) => {
    try {
        const { data } = await axios.get("common/ad-accounts", {
            params: {
                username
            }
        });
        return data
    } catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error)
        return null;
    }
}
