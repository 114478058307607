import React from 'react'

import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    PointElement,
} from "chart.js";
 
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    PointElement,
);

export const YaxisBarChart = ({ data, barLabel, mediaInfoId }) => {
    const barColor = () => {
        if (mediaInfoId === 5) {
            return "rgba(0, 176, 80, 1)";
        } else if (mediaInfoId === 89) {
            return "rgba(255, 180, 18, 1)";
        } else if (mediaInfoId === 100) {
            return "rgba(0, 114, 240, 1)";
        }
        return ;
    };

    const options = {
        indexAxis: 'y',  // 이 옵션을 추가하여 가로 막대 차트로 변경
        maintainAspectRatio: false,
        scales: {
            x: {
                beginAtZero: true,
                title: {
                    display: false,
                    text: '전환 금액'
                },
                ticks: {
                    callback: function(value) {
                        return (value / 1000).toLocaleString() + 'K';
                    },
                    font: {
                        family: "Pretendard",
                        size: 12,
                        weight: "bold"
                    },
                    color: '#000'
                }
            },
            y: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 12,
                        weight: "bold"
                    },
                    color: '#000'
                }
            },
        },
        plugins: {
            legend: {
                display: true,
                    position: "top",
                    align: "start",
                    margin: {
                        bottom: 10,
                    },
                    labels: {
                        // usePointStyle: true,
                        boxWidth: 20,
                        color: '#000',
                        font: {
                            family: "Pretendard",
                            size: 12,
                            weight: "bold",
                        },
                    },
            },
            datalabels: {
                display: false,
            },
        },
    }

    const chartData = {
        labels: data.map(item => `${item.day}요일`),
        datasets: [
            {
                type: "bar",
                label: barLabel,
                data: data.map(item => item.value),
                backgroundColor: barColor,
            }
        ]
    }

    return (
        <Bar data={chartData} options={options} />
    )
}