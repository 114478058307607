import React, { useState, useEffect } from "react";

import styles from "../scss/prevweekly.module.scss"
import { ReactComponent as Labels } from "../../../../../assets/images/predict/pred-prev-labels.svg"

import { PrevSalesTrendApi, PrevMediaMetricsApi } from "../../../../../api/predict/period/PrevWeeklyApi";

import { PredPieChart } from "../../../../utils/chart/DaPieChart";
import { PredBarLine } from "../../../../utils/chart/PredChart";
import { PrevWeekTable } from "./PrevWeekTable";
import Loading from "../../../../utils/loading/Loading";



export const PrevWeekly = ({ incrementId, reportType, setDate }) => {
    const [chartData, setChartData] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async() => {
            const chartResponse = await PrevSalesTrendApi(incrementId, reportType);
            const tableResponse = await PrevMediaMetricsApi(incrementId, reportType);
            setChartData(chartResponse);
            setTableData(tableResponse);
            setDate(chartResponse.data.map(item => item.ad_date));
            setLoading(false);
            console.log(chartResponse);
        };

        fetchData();
    }, [incrementId, reportType]);

    if (loading) {
        return (
            <div className={styles.prevWeekly}>
                <Loading />
            </div>
        )
    }

    const transformedData = tableData.data.map(item => {
        let landingType = "";
        let newMedia = item.media;

        switch (item.media) {
            case "google":
                landingType = "검색 광고";
                newMedia = "구글";
                break;
            case "naver":
                landingType = "파워 링크";
                newMedia = "네이버";
                break;
            case "kakao":
                landingType = "카카오 키워드";
                newMedia = "카카오";
                break;
            case "meta":
                landingType = "디스플레이 광고";
                newMedia = "메타";
                break;
            default:
                landingType = "알 수 없음";
        }
        return {
            ...item,
            media: newMedia,
            landing_type: landingType,
        };
    }).sort((a, b) => {
        const order = ["네이버", "카카오", "구글", "메타"];
        return order.indexOf(a.media) - order.indexOf(b.media);
    });

    // console.log(transformedData)

    return (
        <div className={styles.prevWeekly}>
            <div className={styles.prevWeeklyChart}>
                <div className={styles.prevWeeklyPie}>
                    <p className={styles.chartTxt}>지난주 광고비</p>
                    <PredPieChart data={transformedData} />
                </div>
                <div className={styles.prevWeeklyBarLine}>
                    <p className={styles.chartTxt}>광고비 매출 추이</p>
                    <div style={{width: "600px"}}><Labels /></div>
                    <PredBarLine data={chartData.data}/>
                </div>
            </div>
            <PrevWeekTable data={transformedData}/>
        </div>
    )
}