import React from "react";

import styles from "./mediaguide.module.scss";
import { ReactComponent as NaverSAGuide } from "../../assets/images/guide/naver-sa-guide.svg";
import { ReactComponent as KakaoKeywordGuide } from "../../assets/images/guide/kakao-keyword-guide.svg";
import { ReactComponent as KakaoMomentGuide } from "../../assets/images/guide/kakao-moment-guide.svg";
import { ReactComponent as GoogleSAGuide } from "../../assets/images/guide/google-sa-guide.svg";

export const MediaGuide = ({ selectedLink }) => {
    // console.log(selectedLink)
    return (
        <div className={styles.mediaGuide}>
            {selectedLink === "네이버 검색광고" && <NaverSAGuide /> }
            {selectedLink === "카카오키워드" && <KakaoKeywordGuide />}
            {selectedLink === "카카오모먼트" && <KakaoMomentGuide /> }
            {selectedLink === "구글광고" && <GoogleSAGuide />}
        </div>
    )
}
