import axios from "../axios"

export const GoogleLoginApi = async (credential) => {
    try {
        const { data } = await axios.post('googleads/login/',
            { credential },
            { headers: { 'Content-Type': 'application/json' } }
        );
        return data; // 가져온 데이터 반환
    } catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error);
        throw error;
    }
}