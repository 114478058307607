import React, { useEffect } from "react";

import styles from "./report.module.scss"
import { ReactComponent as Naver } from "../../../../assets/images/share/naver.svg";
import { ReactComponent as Kakao } from "../../../../assets/images/share/kakao.svg";
import { ReactComponent as Ga4 } from "../../../../assets/images/home/home-report-ga4.svg";
import { ReactComponent as Google } from "../../../../assets/images/share/google.svg";
import { ReactComponent as Facebook } from "../../../../assets/images/share/facebook.svg";

export const Report = ({mediaData, handlePreviewClick}) => {
    if (!mediaData || !mediaData.data) return null;

    const render = (media) => {
        if (media === 5) {
            return (
                <div className={styles.recommendContent}>
                    <div className={styles.recommendImg}><Naver /></div>
                    <p className={styles.recommendTxt1}>네이버 검색 광고 보고서</p>
                    <p className={styles.recommendTxt2}>--&gt;</p>
                </div>
            )
        } else if (media === 6) {
            return (
                <div className={styles.recommendContent}>
                    <div className={styles.recommendImg}><Naver /></div>
                    <p className={styles.recommendTxt1}>네이버 GFA 보고서</p>
                    <p className={styles.recommendTxt2}>--&gt;</p>
                </div>
            )
        } else if (media === 7) {
            return (
                <div className={styles.recommendContent}>
                    <div className={styles.recommendImg}><Kakao /></div>
                    <p className={styles.recommendTxt1}>카카오 모먼트 보고서</p>
                    <p className={styles.recommendTxt2}>--&gt;</p>
                </div>
            )
        } else if (media === 89) {
            return (
                <div className={styles.recommendContent}>
                    <div className={styles.recommendImg}><Kakao /></div>
                    <p className={styles.recommendTxt1}>카카오 키워드 보고서</p>
                    <p className={styles.recommendTxt2}>--&gt;</p>
                </div>
            )
        } else if (media === 100) {
            return (
                <div className={styles.recommendContent}>
                    <div className={styles.recommendImg}><Google /></div>
                    <p className={styles.recommendTxt1}>구글 검색 광고 보고서</p>
                    <p className={styles.recommendTxt2}>--&gt;</p>
                </div>
            )
        }
        return (
            <></>
        );
    }

    // 랜덤으로 3개의 항목을 선택
    const getRandomReports = (data) => {
        if (data.length === 0) {
            const mediaCodes = [
                {media_info_code : 5},
                {media_info_code : 6},
                {media_info_code : 7},
                {media_info_code : 89},
                {media_info_code : 100},
            ];
            // 랜덤으로 3개 선택
            return mediaCodes.sort(() => 0.5 - Math.random()).slice(0, 3);
        }
    
        // media_info_code 중복 제거
        const uniqueData = [];
        const seenCodes = new Set();
    
        data.forEach((item) => {
            if (!seenCodes.has(item.media_info_code)) {
                uniqueData.push(item); // 고유한 media_info_code를 가진 항목만 추가
                seenCodes.add(item.media_info_code);
            }
        });
    
        if (uniqueData.length <= 3) return uniqueData;
    
        // 고유 데이터에서 랜덤 3개 선택
        const shuffled = uniqueData.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, 3);
    };

    const handleCreateReport = (mediaList) => {
        const reportSet = new Set();

        mediaList.forEach((media) => {
            if (media === 5 || media === 6) {
                reportSet.add("네이버");
            } else if (media === 7) {
                reportSet.add("카카오 모먼트");
            } else if (media === 89) {
                reportSet.add("카카오 키워드");
            } else if (media === 100) {
                reportSet.add("구글 SA 광고");
                reportSet.add("구글 DA 광고");
            } 
        });

        // if (mediaList.some(media => [5, 6, 7, 89].includes(media))) {
        //     reportSet.add("매체 통합");
        // }

        return reportSet.size;
    };

    const randomMediaData = getRandomReports(mediaData.data);

    // console.log(randomMediaData)

    return (
        <div className={styles.report}>
            <div className={styles.currentBox}>
                <p className={styles.currentTxt}>보고서 현황</p>
                <div className={styles.totalReport}>
                    <p className={styles.reportTxt1}>전체 보고서 개수</p>
                    <p className={styles.reportTxt2}>5</p>
                </div>
                <div className={styles.createReport}>
                    <p className={styles.createTxt1}>생성 가능한 보고서 개수</p>
                    <p className={styles.createTxt2}>
                        {handleCreateReport(mediaData.data.map(item => item.media_info_code))}
                    </p>
                </div>
            </div>
            <div className={styles.recommendBox}>
                <p className={styles.recommendTxt}>추천 보고서</p>
                <div className={styles.recommendContainer}>
                    {randomMediaData.map((item) => (
                        <div onClick={handlePreviewClick} key={item.increment_id} >
                            {render(item.media_info_code)}
                        </div>
                    ))}
                    {/* <div className={styles.recommendContent}>
                        <div className={styles.recommendImg}>
                            <Ga4 />
                            <Naver />
                            <Facebook />
                            <Kakao />
                            <Google />
                        </div>
                        <p className={styles.recommendTxt3}>매체 통합 보고서</p>
                        <p className={styles.recommendTxt2}>--&gt;</p>
                    </div> */}
                </div>
            </div>
        </div>
    );
}