import React from "react";
import Pagination from "react-js-pagination";
import './pagination.scss';

const CustomPagination = ({ total, page, views, handlePageChange, mediaInfoId, reportType }) => {
    const postPerPage = views; // views 값을 사용하여 페이지당 포스트 수 설정
    const indexOfLastPost = page * postPerPage;
    const indexOfFirstPost = indexOfLastPost - postPerPage;

    const mediaClass = mediaInfoId === 5 ? 'naver' : 
                       mediaInfoId === 89 ? 'kakao' : 
                       mediaInfoId === 100 && reportType === "da" ? 'googleDa' :
                       mediaInfoId === 100 ? 'google' : 
                       mediaInfoId === 6 ? 'gfa' :
                       mediaInfoId === 7 ? 'moment' : '';

    return (
        <div className={mediaClass}>
            <Pagination 
                activePage={page}
                itemsCountPerPage={postPerPage}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                prevPageText={"<"}
                nextPageText={">"}
                onChange={handlePageChange}
            />
        </div>
    );
};

export default CustomPagination;
