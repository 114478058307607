import React from "react";

import styles from "../scss/predscorecard.module.scss";

import { InfoIcon } from "../../../utils/info/InfoIcon";


export const PredScoreCard = ({ startDate, endDate, advData }) => {
    // const today = new Date();
    // const formatter = new Intl.DateTimeFormat('ko-KR', { year: 'numeric', month: '2-digit', day: '2-digit' });
    // const startDate = formatter.format(today).replace(/\./g, '').replace(/ /g, ''); // 오늘 날짜
    // const endDate = formatter
    //     .format(new Date(today.setDate(today.getDate() + 7))) // 오늘 날짜 + 7일
    //     .replace(/\./g, '') // . 제거
    //     .replace(/ /g, ''); // 공백 제거
        
    const pred = advData.data[advData.data.length - 1];
    const data = pred.data[pred.data.length - 1];

    const transformedData = [
        {name: '광고비', value: `${data.total_cost.toLocaleString()}`, change: data.total_cost_variance, operator: data.total_cost_variance_operator},
        {name: '노출수', value: `${data.total_impression.toLocaleString()}`, change: data.total_impression_variance, operator: data.total_impression_variance_operator},
        {name: '클릭수', value: `${data.total_click.toLocaleString()}`, change: data.total_click_variance, operator: data.total_click_variance_operator},
        {name: '클릭률', value: `${data.total_ctr.toLocaleString()}%`, change: data.total_ctr_variance, operator: data.total_ctr_variance_operator},
        {name: '전환수', value: `${data.total_conversion_cnt.toLocaleString()}`, change: data.total_conversion_cnt_variance, operator: data.total_conversion_cnt_variance_operator},
        {name: '전환 매출액', value: `${data.total_conversion_amount.toLocaleString()}`, change: data.total_conversion_amount_variance, operator: data.total_conversion_amount_variance_operator},
        {name: '광고수익률', value: `${data.total_roas.toLocaleString()}%`, change: data.total_roas_variance, operator: data.total_roas_variance_operator}
    ]

    const getChangeClassName = (operator) => {
        if (operator === "up") return styles.positive;
        if (operator === "down") return styles.negative;
        return '';
    }

    const message = () => {
        return (
            <>
            조정된 광고비를 기준으로 예측된 다음주 전체 광고 성과입니다.
            </>
        )
    }

    return (
        <>
            <div className={styles.predTitle}>
                <p>{startDate} ~ {endDate} 예측 결과 총합</p>
                <InfoIcon message={message()} className="infoTxtBox2" margin="370px" />
            </div>
            <div className={styles.predScoreCard}>
            {transformedData.map((item, index) => (
                <div key={index} className={styles.scoreCard}>
                    <div className={styles.scoreCardTitle}>{item.name}</div>
                    <div className={styles.scoreCardValue}>{item.value}</div>
                    <div className={`${styles.scoreCardChange} ${getChangeClassName(item.operator)}`}>
                        {item.name === "광고수익률" 
                            ? item.operator === "up" 
                                ? `+${item.change}%p` 
                                : item.operator === "down" 
                                    ? `-${item.change}%p` 
                                    : "-" 
                            : item.operator === "up" 
                                ? `+${item.change}%` 
                                : item.operator === "down" 
                                    ? `-${item.change}%` 
                                    : "-"
                        }
                    </div>
                </div>
            ))}
            </div>
        </>
    );
}