import axios from "../axios";

export const KakaoRedirectApi = async (payload) => {
    const { data } = await axios.post(
        'kakao/datalink/',
        payload,
        { headers: { 'Content-Type': 'application/json' } }
    );
    return data;
}
