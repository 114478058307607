import React, { createContext, useState } from "react";

export const SummaryContext = createContext();

export const DaSummaryProvider = ({ children }) => {
    // 총 전환수 (cost 페이지)
    const [conversion, setConversion] = useState(null);
    // 총 전환 매출액 (cost 페이지)
    const [convAmount, setConvAmount] = useState(null);
    // 평균 전환 매출액이 가장 높은 요일 (cost 페이지)
    const [day, setDay] = useState({convAmount: null})
    // 광고 수익률이 가장 높, 낮은 캠페인 (campaign 페이지)
    const [campaign, setCampaign] = useState({low: null, high: null})
    // 광고 수익률이 가장 높, 낮운 광고 그룹 (campaign 페이지)
    const [group, setGroup] = useState({low: null, high: null})
    // 광고 수익률이 가장 높은 소재, 소재의 광고 수익률, 이전 기간 대비 광고 수익률
    const [subject, setSubject] = useState({roas: null, creative: null, prevRoas: null, upDown: null})
    // 광고 소재 성과 3위까지의 평균 노출수, 평균 클릭수, 평균 매출
    const [highSub, setHighSub] = useState({impression: null, click: null, amount: null, creative:null, prevRoas: null, upDown: null})
    // 광고 소재 성과 하위 3위까지의 평균 노출수, 평균 클릭수, 평균 매출
    const [lowSub, setLowSub] = useState({impression: null, click: null, amount: null, prevRoas: null, upDown: null})
    // 광고 수익률이 가장 높은 지면
    const [page, setPage] = useState({roas: null})
    // 감소, 상승

    return (
        <SummaryContext.Provider value={{
            conversion, setConversion,
            convAmount, setConvAmount,
            day, setDay,
            campaign, setCampaign,
            group, setGroup,
            subject, setSubject,
            highSub, setHighSub,
            lowSub, setLowSub,
            page, setPage
        }}>
            {children}
        </SummaryContext.Provider>
    )
}