import React from "react";

import styles from "../scss/mediaperformance.module.scss"

import { PerformanceLine } from "./PerformanceLine";
import { PerformanceMediaCard } from "./PerformanceMediaCard";

import { InfoIcon } from "../../../utils/info/InfoIcon";


export const MediaPerformance = ({ startDate, endDate, advData, percent, changeCost, tableData, linkedData }) => {
    const message = () => {
        return (
            <>
            광고 매체별로 예측된 매출액입니다.
            </>
        )
    }

    const mediaData = {
        naver: advData.data.flatMap(item => 
            item.data.filter(innerItem => innerItem.media === "naver")
        ),
        kakao: advData.data.flatMap(item => 
            item.data.filter(innerItem => innerItem.media === "kakao")
        ),
        google: advData.data.flatMap(item => 
            item.data.filter(innerItem => innerItem.media === "google")
        ),
        meta: advData.data.flatMap(item => 
            item.data.filter(innerItem => innerItem.media === "meta")
        ),
        total: advData.data.flatMap(item => 
            item.data.filter(innerItem => innerItem.media === "total")
        )
    };

    return (
        <div className={styles.performance}>
            <div className={styles.performanceTxt}>
                <p>매체별 성과</p>
                <InfoIcon message={message()} className="infoTxtBox" />
            </div>
            <PerformanceLine data={advData} mediaData={mediaData} />
            <PerformanceMediaCard mediaData={mediaData} percent={percent} changeCost={changeCost} tableData={tableData} linkedData={linkedData} />
        </div>
    )
}








