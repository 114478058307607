import React, { useState } from "react";
import Modal from "react-modal";

import styles from "../signupCopy.module.scss"
import { ReactComponent as SingupLogo } from "../../../assets/images/signup/signup-logo.svg";
import { ReactComponent as AdfLogo1 } from "../../../assets/images/signup/adf-info-1.svg";
import { ReactComponent as AdfLogo2 } from "../../../assets/images/signup/adf-info-2.svg";
import { ReactComponent as AdfLogo3 } from "../../../assets/images/signup/adf-info-3.svg";

import ModalContent from "../inputBox/modal/ModalContent";

export const PrivacyPolicy = () => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    return (
        <div className={styles.mainBox}>
            <div className={styles.leftBox}>
                <div className={styles.adfInfo1}>
                    <div>
                        <SingupLogo />
                        <h3>에서</h3>
                    </div>
                    <h3>다양한 종류의 데이터를 쉽고 정확하게 분석해 보세요!</h3>
                </div>
                <div className={styles.adfInfo2}>
                    <AdfLogo1 />
                    <div>
                        <h3>다양한 매체의 데이터를 한 번에 확인할 수 있어요.</h3>
                        <h5>
                            여러 매체의 데이터를 통합적으로 분석하여 광고 성과를 더욱
                            정확하게 파악할 수 있습니다.
                        </h5>
                    </div>
                </div>
                <div className={styles.adfInfo3}>
                    <AdfLogo2 />
                    <div>
                        <h3>수많은 데이터를 기반으로 한 예측 모델을 경험해 보세요.</h3>
                        <h5>
                            광고성과를 예측하여 최적의 광고 전략을 수립할 수 있도록
                            도와줍니다.
                        </h5>
                    </div>
                </div>
                <div className={styles.adfInfo4}>
                    <AdfLogo3 />
                    <div>
                        <h3>시각적 자료를 활용해 더욱 직관적인 데이터를 제공합니다.</h3>
                        <h5>
                            데이터를 한눈에 파악할 수 있어, 광고 전략을 더욱 쉽게 수립할 수
                            있습니다.
                        </h5>
                    </div>
                </div>
            </div>
            <div className={styles.signBox}>
                <Modal
                    isOpen={isModalOpen}
                    contentLabel="Agreement Modal"
                    className={styles.modal}
                    overlayClassName={styles.overlay}
                >
                    <div className={styles.leftBox}></div>
                    <div className={styles.modalBox}>
                        <h2>개인정보 수집 및 이용 동의</h2>
                        <div className={styles.modalContent}>
                            <ModalContent ModalTitle="개인정보 수집 및 이용 동의" />
                        </div>
                        <div className={styles.modalBtn}>
                            <button>취소</button>
                            <button>동의</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}