import React, { useState, useEffect } from "react";

import styles from "../scss/advcostprev.module.scss"
import { ReactComponent as Labels } from "../../../../assets/images/predict/pred-prev-labels.svg"

import { PrevSalesTrendApi, PrevMediaMetricsApi } from "../../../../api/predict/period/PrevWeeklyApi";

import { PredPieChart } from "../../../utils/chart/DaPieChart";
import { PredBarLine } from "../../../utils/chart/PredChart";
import { PrevWeekTable } from "../../period/weekly/prev/PrevWeekTable";
import Loading from "../../../utils/loading/Loading";



export const AdvCostPrev = ({ incrementId, tableData, setTableData, loading, setLoading }) => {
    const [chartData, setChartData] = useState(null);
    // const [tableData, setTableData] = useState(null);

    useEffect(() => {
        const fetchData = async() => {
            const chartResponse = await PrevSalesTrendApi(incrementId, "weekly");
            const tableResponse = await PrevMediaMetricsApi(incrementId, "weekly");
            setChartData(chartResponse);
            setTableData(tableResponse);
            setLoading(false);
            // console.log(tableResponse);
        };

        fetchData();
    }, [incrementId]);

    if (loading) {
        return (
            <div className={styles.advCostPrev}>
                <Loading />
            </div>
        )
    }

    const transformedData = tableData.data.map(item => {
        let landingType = "";
        let newMedia = item.media;

        switch (item.media) {
            case "google":
                landingType = "검색 광고";
                newMedia = "구글";
                break;
            case "naver":
                landingType = "파워 링크";
                newMedia = "네이버";
                break;
            case "kakao":
                landingType = "카카오 키워드";
                newMedia = "카카오";
                break;
            case "meta":
                landingType = "디스플레이 광고";
                newMedia = "메타";
                break;
            default:
                landingType = "알 수 없음";
        }
        return {
            ...item,
            media: newMedia,
            landing_type: landingType,
        };
    }).sort((a, b) => {
        const order = ["네이버", "카카오", "구글", "메타"];
        return order.indexOf(a.media) - order.indexOf(b.media);
    });

    return (
        <div className={styles.advCostPrev}>
            <p className={styles.advCostPrevTitle}>이전 일주일 성과</p>
            <div className={styles.advCostPrevChart}>
                <div className={styles.advCostPrevPie}>
                    <p className={styles.chartTxt}>지난주 광고비</p>
                    <PredPieChart data={transformedData} mediaInfoId={999} />
                </div>
                <div className={styles.advCostPrevBarLine}>
                    <p className={styles.chartTxt}>광고비 매출 추이</p>
                    <div style={{width: "600px"}}><Labels /></div>
                    <PredBarLine data={chartData.data}/>
                </div>
            </div>
            <PrevWeekTable data={transformedData}/>
        </div>
    )
}