import axios from "../../axios";

export const DaCostTrendChartApi = async ( media, startDate, endDate, accountId, selectedValue ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/costs/trand-graph/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/costs/trand-graph/`;
    } else {
        path = `${media}/${accountId}/costs/trand-graph/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                period_type: selectedValue,
                end_dt: endDate
            }
        });
        return data;
    } catch (error) {
        console.error("비용 추세 오류 발생:", error);
        throw error;
    }
};