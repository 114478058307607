import React from 'react'

import { Bar, Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    PointElement,
    LineElement,
} from "chart.js";
 
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    PointElement,
    LineElement
);


export const PredBarLine = ({ data }) => {
    const totalConvAmount = data.map(item => {
        const total_conversion_amount = item.data.reduce((sum, current) => sum + current.total_conversion_amount, 0);
        return {
            total_conversion_amount: total_conversion_amount,
        };
    });

    const naverAmounts = data.flatMap(item => 
        item.data
            .filter(innerItem => innerItem.media === "naver")
            .map(innerItem => innerItem.total_cost)
    );

    const kakaoAmounts = data.flatMap(item => 
        item.data
            .filter(innerItem => innerItem.media === "kakao")
            .map(innerItem => innerItem.total_cost)
    );

    const googleAmounts = data.flatMap(item => 
        item.data
            .filter(innerItem => innerItem.media === "google")
            .map(innerItem => innerItem.total_cost)
    );

    const metaAmounts = data.flatMap(item => 
        item.data
            .filter(innerItem => innerItem.media === "meta")
            .map(innerItem => innerItem.total_cost)
    );

    // console.log(data)

    const chartData = {
        labels: data.map(item => item.ad_date),
        datasets: [
            {
                type: 'line',
                label: '총 수익',
                data: totalConvAmount.map(item => item.total_conversion_amount),
                borderColor: 'rgba(30, 0 ,203, 1)',
                backgroundColor: 'rgba(30, 0 ,203, 1)',
                yAxisID: 'y2',
                pointStyle: 'circle',
                pointRadius: 5,
                pointHoverRadius: 7,
                tension: 0,
            },
            {
                type: 'bar',
                label: '네이버',
                data: naverAmounts,
                backgroundColor: 'rgba(0, 176, 80, 0.8)',
                yAxisID: 'y1',
                stack: 'stack1',
                barPercentage: 1,
                categoryPercentage: 0.6,
            },
            {
                type: 'bar',
                label: '카카오',
                data: kakaoAmounts,
                backgroundColor: 'rgba(254, 194, 32, 1)',
                yAxisID: 'y1',
                stack: 'stack1',
                barPercentage: 1,
                categoryPercentage: 0.6,
            },
            {
                type: 'bar',
                label: '구글',
                data: googleAmounts,
                backgroundColor: 'rgba(214, 73, 87, 1)',
                yAxisID: 'y1',
                stack: 'stack1',
                barPercentage: 1,
                categoryPercentage: 0.6,
            },
            {
                type: 'bar',
                label: '메타',
                data: metaAmounts,
                backgroundColor: 'rgba(0, 114, 240, 1)',
                yAxisID: 'y1',
                stack: 'stack1',
                barPercentage: 1,
                categoryPercentage: 0.6,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y1: {
                type: 'linear',
                position: 'left',
                ticks: {
                    callback: (value) => `${value / 10000}만`, // 백만 단위로 표시
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "600"
                    },
                    color: '#000'
                },
                grid: {
                    display: false
                }
            },
            y2: {
                type: 'linear',
                position: 'right',
                beginAtZero: true,
                grid: {
                    drawOnChartArea: false,
                    display: false
                },
                ticks: {
                    callback: (value) => `${value}`, // 수익률 (값 그대로 표시)
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "600"
                    },
                    color: '#000'
                },
            },
            x: {
                stacked: true,
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "600"
                    },
                    color: '#000'
                }
            },
        },
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            datalabels: {
                display: false,
            },
        },
    };

    return (
        <div style={{ width: '748px', height: '200px' }}>
            <Bar data={chartData} options={options} />
        </div>
    );
};


export const PredSingleBarLine = ({ data, totalData, target, width, height }) => {
    const options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: true
                },
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "600"
                    },
                    color: '#000'
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: target === "conv_count" ? "전환수" : "전환 매출액", // 왼쪽 y축 라벨
                    font: {
                        family: "Pretendard",
                        size: 12,
                        weight: "600",
                    },
                    color: "#000", // 라벨 색상
                },
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "600"
                    },
                    color: '#000'
                },
                grid: {
                    drawOnChartArea: true,
                },
            },
            y2: {
                type: 'linear',
                display: true,
                position: 'right',
                title: {
                    display: true,
                    text: "광고수익률", // 오른쪽 y축 라벨
                    font: {
                        family: "Pretendard",
                        size: 12,
                        weight: "600",
                    },
                    color: "#000", // 라벨 색상
                },
                ticks: {
                    display: false,
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "600"
                    },
                    color: '#000'
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
            },
            layout: {
                padding: {
                    
                }
            },
            plugins: {
                legend: {
                    display: false,
                    position: "top",
                    align: "start",
                    margin: {
                        bottom: 10,
                    },
                    labels: {
                        // usePointStyle: true,
                        display: false,
                        boxWidth: 40,
                        boxHeight: 0,
                        color: '#000',
                        font: {
                            family: "Pretendard",
                            size: 12,
                            weight: "600",
                        },
                    },
                },
                datalabels: {
                    display: false,
                },
                responsive: true,
                maintainAspectRatio: false,
        },
    };

    const chartData = {
        labels: data.data.map(item => item.ad_date),
        datasets: [
            {
                yAxisID: "y",
                type: "line",
                label: target === "conv_count" ? "전환수" : "전환 매출액",
                data: target === "conv_count" 
                    ? totalData.map(item => item.total_conversion_cnt) 
                    : totalData.map(item => item.total_conversion_amount),
                
                backgroundColor: "rgba(0, 160, 160, 1)",
                borderColor: "rgba(0, 160, 160, 1)",
                borderWidth: 2.5,
                fill: false,
                pointRadius: 2,
                pointBackgroundColor: "rgba(0, 160, 160, 1)",
                pointBorderColor: "rgba(0, 160, 160, 1)",
            },
            {
                yAxisID: "y2",
                type: "bar",
                label: "광고수익률",
                data: totalData.map(item => item.total_roas),
                backgroundColor: "rgba(254, 194, 32, 1)",
                borderColor: "rgba(254, 194, 32, 1)",
                barPercentage: 1,
                categoryPercentage: 0.4,
            },
        ]
    };


    return (
        <div style={{width: width, height: height}}>
            <Bar data={chartData} options={options} />
        </div>
    )
}


export const PredLine = ({ data, mediaData, width, height, pointRadius }) => {
    const borderColor = (media) => {
        if (media === "네이버") {
            return "rgba(0, 176, 80, 1)";
        } else if (media === "카카오") {
            return "rgba(254, 194, 32, 1)";
        } else if (media === "구글") {
            return "rgba(214, 73, 87, 1)";
        } else if (media === "메타") {
            return "rgba(0, 114, 240, 1)";
        }
        return ;
    };
    
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: "top",
                align: "center",
                margin: {
                    bottom: 10,
                },
                labels: {
                    // usePointStyle: true,
                    boxWidth: 30,
                    boxHeight: 0,
                    color: '#000',
                    font: {
                        family: "Pretendard",
                        size: 12,
                        weight: "600",
                    },
                },
            },
            datalabels: {
                display: false,
            },
            responsive: true,
            maintainAspectRatio: false,
        },
        scales: {
            x: {
                grid: {
                    display: true,
                },
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 10,
                        weight: "600"
                    },
                    color: '#000',
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "600"
                    },
                    color: '#000'
                },
                grid: {
                    drawOnChartArea: true,
                },
            },
        },
    };

    // console.log(mediaData)
    
    const chartData = {
        labels: data.data.map(item => item.ad_date),
        datasets: [
            {
                type: 'line',
                label: '네이버',
                data: mediaData.naver.map(item => item.total_conversion_amount),
                backgroundColor: 'rgba(0, 176, 80, 0.8)',
                borderColor: 'rgba(0, 176, 80, 0.8)',
                borderWidth: 2,
                pointRadius: pointRadius,
            },
            {
                type: 'line',
                label: '카카오',
                data: mediaData.kakao.map(item => item.total_conversion_amount),
                backgroundColor: 'rgba(254, 194, 32, 1)',
                borderColor: 'rgba(254, 194, 32, 1)',
                borderWidth: 2,
                pointRadius: pointRadius,
            },
            {
                type: 'line',
                label: '구글',
                data: mediaData.google.map(item => item.total_conversion_amount),
                backgroundColor: 'rgba(214, 73, 87, 1)',
                borderColor: 'rgba(214, 73, 87, 1)',
                borderWidth: 2,
                pointRadius: pointRadius,
            },
            // {
            //     type: 'line',
            //     label: '메타',
            //     data: mediaData.meta.map(item => item.total_conversion_amount),
            //     backgroundColor: 'rgba(0, 114, 240, 1)',
            //     borderWidth: 2,
            //     pointRadius: pointRadius,
            // },
        ],
    };

    return (
        <div style={{width: width, height: height}}>
            <Line options={options} data={chartData} />
        </div>
    )
}

export const PredResultLine = ({ data }) => {
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: "top",
                align: "center",
                margin: {
                    bottom: 10,
                },
                labels: {
                    // usePointStyle: true,
                    boxWidth: 30,
                    boxHeight: 0,
                    color: '#000',
                    font: {
                        family: "Pretendard",
                        size: 12,
                        weight: "600",
                    },
                },
            },
            datalabels: {
                display: false,
            },
            responsive: true,
            maintainAspectRatio: false,
        },
        scales: {
            x: {
                grid: {
                    display: true,
                },
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 10,
                        weight: "600"
                    },
                    color: '#000',
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "600"
                    },
                    color: '#000'
                },
                grid: {
                    drawOnChartArea: true,
                },
            },
            y2: {
                type: 'linear',
                display: false,
                position: 'right',
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "600"
                    },
                    color: '#000'
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    };

    // console.log(data[0].data.map(item => item.date))
    const labelData = data.data.map(item => item.data[item.data.length - 1])
    const chartData = {
        labels: data.data.map(item => item.ad_date),
        datasets: [
            {
                label: "노출수",
                data: labelData.map((item) => item.total_impression),
                borderColor: "#00A0A0",
                backgroundColor: "#00A0A0",
                tension: 0,
                yAxisID: 'y',
            },
            {
                label: "클릭수",
                data: labelData.map((item) => item.total_click),
                borderColor: "#FEC220",
                backgroundColor: "#FEC220",
                tension: 0,
                yAxisID: 'y2',
            },
        ],
    };

    return (
        <div style={{width: "560px", height: "320px"}}>
            <Line options={options} data={chartData} />
        </div>
    )
}