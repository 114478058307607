import React from "react";

import styles from "../scss/resultscorecard.module.scss";


export const ResultScoreCard = ({ startDate, endDate, data, total, date, reportType }) => {
    // const prev = total.slice(0, -1).reduce((acc, curr) => {
    //     for (const key in curr) {
    //         if (key !== "media") {
    //             acc[key] = (acc[key] || 0) + curr[key];
    //         }
    //     }
    //     return acc;
    // }, {});

    const prev = total[total.length - 2];

    const pred = total[total.length - 1];

    const transformedData = [
        {name: '광고비', value: `${prev.total_cost.toLocaleString()}`},
        {name: '노출수', value: `${prev.total_impression.toLocaleString()}`},
        {name: '클릭수', value: `${prev.total_click.toLocaleString()}`},
        {name: '전환수', value: `${prev.total_conversion_cnt.toLocaleString()}`},
        {name: '전환 매출액', value: `${prev.total_conversion_amount.toLocaleString()}`},
    ]

    const transformedTotal = [
        {name: '전환수', value: `${pred.total_conversion_cnt.toLocaleString()}`, change: pred.total_conversion_cnt_variance, operator: pred.total_conversion_cnt_variance_operator},
        {name: '전환 매출액', value: `${pred.total_conversion_amount.toLocaleString()}`, change: pred.total_conversion_amount_variance, operator: pred.total_conversion_amount_variance_operator},
        {name: '광고수익률', value: `${pred.total_roas.toLocaleString()}%`, change: pred.total_roas_variance, operator: pred.total_roas_variance_operator}
    ]

    const getChangeClassName = (operator) => {
        if (operator === "up") return styles.positive;
        if (operator === "down") return styles.negative;
        return '';
    }

    return (
        <div className={styles.resultScoreCard}>
            <div className={styles.totalScore}>
                {date ? (
                    <p className={styles.scoreTxt}> {date[0].replace(/-/g, '')} ~ {endDate} 성과 총합</p>
                ) : (
                    <p className={styles.scoreTxt}> {startDate} ~ {endDate} 성과 총합 </p>
                )}
                <div className={styles.totalScoreCard}>
                    {transformedData.map((item, index) => (
                        <div key={index} className={styles.scoreCard}>
                            <div className={styles.scoreCardTitle}>{item.name}</div>
                            <div className={styles.scoreCardValue}>{item.value}</div>
                            {/* <div className={`${styles.scoreCardChange} ${getChangeClassName(item.operator)}`}>
                                {item.operator === "up" ? `+${item.change}%` : item.operator === "down" ? `-${item.change}%` : "-"}
                            </div> */}
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.predScore}>
                {reportType === "weekly" ? (
                    <p className={styles.predScoreTxt}>예측 결과 총합 (일주일)</p>
                ) : (
                    <p className={styles.predScoreTxt}>예측 결과 총합 (일개월)</p>
                )}
                <div className={styles.predScoreCard}>
                    {transformedTotal.map((item, index) => (
                        <div key={index} className={styles.predCard}>
                            <div className={styles.scoreCardTitle}>{item.name}</div>
                            <div className={styles.scoreCardValue}>{item.value}</div>
                            <div className={`${styles.scoreCardChange} ${getChangeClassName(item.operator)}`}>
                                {item.name === "광고수익률" 
                                    ? item.operator === "up" 
                                        ? `+${item.change}%p` 
                                        : item.operator === "down" 
                                            ? `-${item.change}%p` 
                                            : "-" 
                                    : item.operator === "up" 
                                        ? `+${item.change}%` 
                                        : item.operator === "down" 
                                            ? `-${item.change}%` 
                                            : "-"
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}