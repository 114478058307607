import axios from "../axios"

export const NaverCheckAccount = async (naverFromData) => {
    try {
        const response = await axios.post('naver/check_account/',
            { naverFromData },
            { headers: { 'Content-Type': 'application/json' } }
        );
        
        return response; // 가져온 데이터 반환
    } catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error);
        return error.response;
    };
}