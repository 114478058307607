import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from "react-redux";

const Gtm = ({gtmId}) => {
    const user = useSelector((state) => state.user);

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                user_id: user.username,
            },
        });

        TagManager.initialize({gtmId});
    }, [gtmId]);

    return <></>;
};

export default Gtm;