import React, { useState, useEffect, useContext } from 'react';

import styles from '../scss/deviceimpression.module.scss';

import { SummaryContext } from '../../../summary/SummaryProvider';

import { ClickDeviceImpressionApi } from '../../../../../api/report/click/ClickDeviceImpressionApi';

import { DevicePieChart } from '../../../../utils/chart/DevicePieChart';
import Loading from '../../../../utils/loading/Loading';
import { NullBox } from '../../../../utils/nullBox/NullBox';

import { NullData } from '../../../../../hooks/NullData';


export const DeviceImpression = ({mediaInfoId, adAccountsId, startDate, endDate, mediaName}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState("impression");
    const { setDeviceCompClick } = useContext(SummaryContext);

    const compareClickrate = (data) => {
        let moData = "";
        let pcData = "";
        if (mediaInfoId === 100) {
            moData = data.find(item => item.device_type === "MOBILE");
            pcData = data.find(item => item.device_type === "DESKTOP");
        } else {
            moData = data.find(item => item.device_type === "MO");
            pcData = data.find(item => item.device_type === "PC");
        }

        if (moData && pcData) {
            const moClickRate = moData.click_rate;
            const pcClickRate = pcData.click_rate;

            const diffClickRate = ((moClickRate - pcClickRate) / pcClickRate) * 100; // 비율 계산

            if (diffClickRate > 0) {
                setDeviceCompClick( {clickRate: ["MO", diffClickRate.toFixed(2)]});
            } else if (diffClickRate < 0) {
                setDeviceCompClick( {clickRate: ["PC", Math.abs(diffClickRate.toFixed(2))]});
            }
        }
        return null; // 조건에 맞지 않으면 null 반환
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await ClickDeviceImpressionApi(mediaName, startDate, endDate, adAccountsId);
            setData(response);
            setLoading(false);

            compareClickrate(response.data);     
        };
        fetchData();
    }, [startDate, endDate, selectedValue, adAccountsId]);

    if (loading) {
        return (
            <div className={styles.deviceImpression}>
                <Loading />
            </div>
        );
    }

    const color1 = () => {
        if (mediaInfoId === 5) {
            return 'rgba(0, 176, 80, 1)';
        } else if (mediaInfoId === 89) {
            return 'rgba(255, 180, 18, 1)';
        } else if (mediaInfoId === 100) {
            return 'rgba(0, 114, 240, 1)';
        }
        return ;
    };

    const color2 = () => {
        if (mediaInfoId === 5) {
            return "rgba(0, 176, 80, 0.2)";
        } else if (mediaInfoId === 89) {
            return "rgba(255, 180, 18, 0.2)";
        } else if (mediaInfoId === 100) {
            return "rgba(0, 114, 240, 0.2)";
        }
        return ;
    };

    const color3 = () => {
        if (mediaInfoId === 100) {
            return "#76B7FF"
        }
        return ;
    }

    const chartData = data.data.map(item => ({
        device: item.device_type,
        value: selectedValue === "click" ? item.tot_click :
               selectedValue === "click_rate" ? item.click_rate :
               item.tot_impression
    }));

    const noData = NullData(chartData);

    return (
        <div className={styles.deviceImpression}>
            <div className={styles.deviceImpressionTitle}>
                <p>PC/MO 별 노출/클릭</p>
                <div className={styles.deviceImpressionSelect}>
                    <select className={styles[`selectBox${mediaInfoId}`]} onChange={(e) => setSelectedValue(e.target.value)} value={selectedValue}>
                        <option value="impression">노출수</option>
                        <option value="click">클릭수</option>
                        {/* <option value="click_rate">클릭률</option> */}
                    </select>
                </div>
            </div>
            <div className={styles.deviceImpressionContent}>
                {noData ? (
                    <NullBox />
                ) : (
                    <DevicePieChart data={chartData} color1={color1()} color2={color2()} color3={color3()} selectedValue={selectedValue} />
                )}
            </div>
        </div>
    )
}