import React, { useState, useEffect, useContext } from 'react';

import styles from "../scss/detailedanalysis.module.scss";

import { 
    CostCampaignDataApi,
    CostCampaignTotalApi,
    CostGroupDataApi,
    CostGroupTotalApi,
    CostKeywordDataApi,
    CostKeywordTotalApi
} from '../../../../../api/report/cost/CostDetailAnalysisApi';

import { CampaignAnalysis } from './CampaignAnalysis';
import { GroupAnalysis } from './GroupAnalysis';
import { KeywordAnalysis } from './KeywordAnalysis';

import { SummaryContext } from '../../../summary/SummaryProvider';

export const DetailedAnalysis = ({mediaInfoId, adAccountsId, startDate, endDate, mediaName}) => {
    const [campaginData, setCampaignData] = useState(null);
    const [campaginTotal, setCampaignTotal] = useState(null);
    const [groupData, setGroupData] = useState(null);
    const [groupTotal, setGroupTotal] = useState(null);
    const [keywordData, setKeywordData] = useState(null);
    const [keywordTotal, setKeywordTotal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [adLevel, setadLevel] = useState('캠페인');
    const [prevData, setPrevData] = useState({ startDate: null, endDate: null, adAccountsId: null });
    const [buttonClicked, setButtonClicked] = useState(null);
    const [row, setRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [pageChange, setPageChange] = useState(null);
    const [campainNm, setCampaignNm] = useState(null);
    const [groupNm, setGroupNm] = useState(null);
    const { setTopCampaign, setHighKeyword } = useContext(SummaryContext);

    useEffect(() => {
        setRow(0);
        setCurrentPage(1);
    }, [adLevel]);

    const handleResponse = (response, convCostDESC, convCostASC) => {
        if (response && convCostDESC && convCostASC) {
            // 데이터가 비어 있지 않은지 확인
            if (response.data && response.data.length > 0) {
                const minRoas = response.data.reduce((minItem, item) => {
                    return (item.cost_click < minItem.cost_click) ? item : minItem;
                }, { cost_click: Infinity }); // 초기값 설정
                setTopCampaign({ roas: minRoas.campaign_name });
            } else {
                setTopCampaign({ roas: null }); // 기본값 설정
            }
    
            // ASC와 DESC 데이터 처리
            const lowKeywords = (convCostASC.data && convCostASC.data.length > 0)
                ? convCostASC.data.slice(0, 1).map(item => item.keyword_name)
                : ["No Keywords"];
            const highKeywords = (convCostDESC.data && convCostDESC.data.length > 0)
                ? convCostDESC.data.slice(0, 1).map(item => item.keyword_name)
                : ["No Keywords"];
            setHighKeyword({ highKeyword: highKeywords, lowKeyword: lowKeywords });
        }
    };


    const campaignFetchData = async (orderBy) => {
        const [detailAnalysisResponse] = await Promise.all([
            CostCampaignDataApi(mediaName, startDate, endDate, adAccountsId, pageSize, row, orderBy)
        ]);
        setCampaignData(detailAnalysisResponse);
        
        if (startDate !== prevData.startDate || endDate !== prevData.endDate || adAccountsId !== prevData.adAccountsId) {
            const totalDetailAnalysisResponse = await CostCampaignTotalApi(mediaName, startDate, endDate, adAccountsId);
            setCampaignTotal(totalDetailAnalysisResponse);
            setGroupTotal(totalDetailAnalysisResponse);
            setKeywordTotal(totalDetailAnalysisResponse);
            setPrevData({ startDate, endDate, adAccountsId });
        }
        
        setLoading(false);

        const convCostDESC = await CostKeywordDataApi(mediaName, startDate, endDate, adAccountsId, pageSize, row, "cost_click DESC");
        const convCostASC = await CostKeywordDataApi(mediaName, startDate, endDate, adAccountsId, pageSize, row, "cost_click ASC");

        handleResponse(detailAnalysisResponse, convCostDESC, convCostASC);
        
    };

    const groupFetchData = async (orderBy, campaignId) => {
        const [detailAnalysisResponse] = await Promise.all([
            CostGroupDataApi(mediaName, startDate, endDate, adAccountsId, pageSize, row, orderBy, campaignId)
        ]);
        setGroupData(detailAnalysisResponse);
        
        if (campaignId || buttonClicked === "버튼클릭") {
            const totalDetailAnalysisResponse = await CostGroupTotalApi(mediaName, startDate, endDate, adAccountsId, campaignId);
            setGroupTotal(totalDetailAnalysisResponse);
            setPrevData({ startDate, endDate, adAccountsId });
        }
        
        setLoading(false);
    };

    const keywordFetchData = async (orderBy, groupId) => {
        const [detailAnalysisResponse] = await Promise.all([
            CostKeywordDataApi(mediaName, startDate, endDate, adAccountsId, pageSize, row, orderBy, groupId)
        ]);
        setKeywordData(detailAnalysisResponse);
        
        if (groupId || buttonClicked === "버튼클릭") {
            const totalDetailAnalysisResponse = await CostKeywordTotalApi(mediaName, startDate, endDate, adAccountsId, groupId);
            setKeywordTotal(totalDetailAnalysisResponse);
            setPrevData({ startDate, endDate, adAccountsId });
        }
        
        setLoading(false);
    };

    const handleButtonClick = (buttonName) => {
        setadLevel(buttonName);
        setButtonClicked("버튼클릭");
        setCampaignNm(null);
        setGroupNm(null);
    }; 

    const handleCampaignSelect = (orderBy, campaignId) => {
        setadLevel("광고그룹");
        setButtonClicked("캠페인 선택");
        setCampaignNm(campaignId);
    };

    const handleGroupSelect = (orderBy, groupId) => {
        setadLevel("키워드");
        setButtonClicked("그룹 선택");
        setGroupNm(groupId);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRow((page - 1) * pageSize);
        setPageChange("click");
    };

    return (
        <div className={styles.detailedAnalysis}>
            <div className={styles.detailedAnalysisTitle}>비용 상세 분석</div>
            <div className={styles.detailedAnalysisButton}>
                <button 
                    className={adLevel === '캠페인' ? styles[`selected${mediaInfoId}`] : styles[`noSelected${mediaInfoId}`]}
                    onClick={() => handleButtonClick('캠페인')}
                >
                    캠페인
                </button>
                <button 
                    className={adLevel === '광고그룹' ? styles[`selected${mediaInfoId}`] : styles[`noSelected${mediaInfoId}`]}
                    onClick={() => handleButtonClick('광고그룹')}
                >
                    광고그룹
                </button>
                <button 
                    className={adLevel === '키워드' ? styles[`selected${mediaInfoId}`] : styles[`noSelected${mediaInfoId}`]}
                    onClick={() => handleButtonClick('키워드')}
                >
                    키워드
                </button>
            </div>
            {adLevel === "캠페인" && 
                <CampaignAnalysis
                    mediaInfoId={mediaInfoId} 
                    adAccountsId={adAccountsId} 
                    startDate={startDate} 
                    endDate={endDate} 
                    adLevel={adLevel}
                    setadLevel={setadLevel}
                    fetchData={campaignFetchData}
                    loading={loading}
                    data={campaginData}
                    total={campaginTotal}
                    buttonClicked={buttonClicked}
                    setButtonClicked={setButtonClicked}
                    handleCampaignSelect={handleCampaignSelect}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    pageChange={pageChange}
                    setPageChange={setPageChange}
                    mediaName={mediaName}
                />
            }
            {adLevel === "광고그룹" && 
                <GroupAnalysis
                    mediaInfoId={mediaInfoId} 
                    adAccountsId={adAccountsId} 
                    startDate={startDate} 
                    endDate={endDate} 
                    adLevel={adLevel}
                    setadLevel={setadLevel}
                    fetchData={groupFetchData}
                    loading={loading}
                    data={groupData}
                    total={groupTotal}
                    buttonClicked={buttonClicked}
                    setButtonClicked={setButtonClicked}
                    handleGroupSelect={handleGroupSelect}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    pageChange={pageChange}
                    setPageChange={setPageChange}
                    campainNm={campainNm}
                    mediaName={mediaName}
                />
            }
            {adLevel === "키워드" && 
                <KeywordAnalysis
                    mediaInfoId={mediaInfoId} 
                    adAccountsId={adAccountsId} 
                    startDate={startDate} 
                    endDate={endDate} 
                    adLevel={adLevel}
                    fetchData={keywordFetchData}
                    loading={loading}
                    data={keywordData}
                    total={keywordTotal}
                    buttonClicked={buttonClicked}
                    setButtonClicked={setButtonClicked}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    pageChange={pageChange}
                    setPageChange={setPageChange}
                    groupNm={groupNm}
                    mediaName={mediaName}
                />
            }
        </div>
    );
};
