import React, { useState } from "react";

import styles from "./infoicon.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleQuestion} from "@fortawesome/free-regular-svg-icons";


export const InfoIcon = ({ message, className, margin }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleClick = () => {
        setIsClicked(!isClicked);
    };


    return (
        <>
        {className === "infoTxtBox" ? (
            <div className={styles[className]}>
                <span 
                    className={`${styles.tooltip} ${isHovered || isClicked ? styles.visible : ''}`}
                >
                    {message}
                </span>
                <FontAwesomeIcon 
                    icon={faCircleQuestion} 
                    onMouseEnter={handleMouseEnter} 
                    onMouseLeave={handleMouseLeave} 
                    onClick={handleClick}
                    style={{cursor:"pointer"}}
                />
            </div>
        ) : (
            <div className={styles[className]} style={{marginLeft : margin}} >
                <FontAwesomeIcon 
                    icon={faCircleQuestion} 
                    onMouseEnter={handleMouseEnter} 
                    onMouseLeave={handleMouseLeave} 
                    onClick={handleClick}
                    style={{cursor:"pointer"}}
                />
                <span 
                    className={`${styles.tooltip} ${isHovered || isClicked ? styles.visible : ''}`}
                >
                    {message}
                </span>
            </div>
        )}
        
        </>
    )
}