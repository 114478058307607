import React, { useState, useEffect } from 'react';

import styles from '../scss/costtrend.module.scss';
import { ReactComponent as KakaoLabels } from "../../../../../assets/images/report/report-trend-kakao-labels.svg"
import { ReactComponent as NaverLabels } from "../../../../../assets/images/report/report-trend-naver-labels.svg"

import { CostTrendApi } from '../../../../../api/report/cost/CostTrendApi';

import { BarLine } from '../../../../utils/chart/BarLine';
import Loading from '../../../../utils/loading/Loading';
import { NullBox } from '../../../../utils/nullBox/NullBox';


export const CostTrend = ({mediaInfoId, adAccountsId, startDate, endDate, mediaName}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState("day");

    const labels = () => {
        if (mediaInfoId === 5) {
            return <NaverLabels />
        } else if (mediaInfoId === 89) {
            return <KakaoLabels />
        }
    } 

    useEffect(() => {
        const fetchData = async () => {
            const response = await CostTrendApi(mediaName, startDate, endDate, adAccountsId, selectedValue); // 선택된 값 전달
            setData(response);
            setLoading(false);
            // console.log(response);
        };
        fetchData();
    }, [startDate, endDate, selectedValue, adAccountsId])

    if (loading) {
        return (
            <div className={styles.costTrend}>
                <Loading />
            </div>
        );
    }

    const isDataEmpty = (data) => {
        return data.every(item => 
            item.tot_conversion_amount === 0 &&
            item.tot_cost === 0
        );
    };

    

    return (
        <div className={styles.costTrend}>
            <div className={styles.costTrendTitle}>광고비 추세</div>
            <div className={styles.costTrendSelect}>
                <div style={{height: "17px"}}>{labels()}</div>
                <select className={styles[`selectBox${mediaInfoId}`]} value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)}>
                    <option value="day">일자별</option>
                    <option value="week">주별</option>
                    <option value="month">월별</option>
                </select>
            </div>
            <div className={styles.costTrendChart}>
                {isDataEmpty(data.data) ? (
                    <NullBox />
                ) : (
                    <BarLine data={data} lineLabel="전환 매출액" barLabel="광고비" mediaInfoId={mediaInfoId} labels={labels()} />
                )}
            </div>
        </div>
    );
}