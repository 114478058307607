import axios from "../../axios";

export const DaCreativeTopApi = async (media, startDate, endDate, accountId, orderBy ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/creatives/creative-result-top-three/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/creatives/creative-result-top-three/`;
    } else {
        path = `${media}/${accountId}/creatives/creative-result-top-three/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
                order_by: orderBy,
            }
        });
        return data;
    } catch (error) {
        console.error("광고 소재 성과 탑,워스트 3 데이터 오류 발생:", error);
        throw error;
    }
};
