import axios, { setAccessToken } from "../axios";
import { jwtDecode } from "jwt-decode";
import { loginUser } from "../../store/UserSlice";
import {
  localDate,
  selectStartDate,
  selectEndDate,
} from "../../store/dateSlice";
import { formatDate } from "../../shared/navbar/utils/util";

export const LoginAPI = async (username, password, dispatch) => {
  if (!username) {
    return {
      data: null,
      errormsg: "아이디를 입력해주세요.",
    };
  }
  if (!password) {
    return {
      data: null,
      errormsg: "비밀번호를 입력해주세요.",
    };
  }

  try {
    const response = await axios.post("common/login/", {
      username,
      password,
    });
    // console.log(`response : ${response}`);
    const status = response.status;
    const data = response.data.data;
    if (status === 200) {
      // const decodedToken = jwtDecode(data.access);
      // console.log(decodedToken.approve);
      dispatch(
        loginUser({
          // accessToken: data.access_token,
          // refreshToken: data.refresh_token,
          username: data.username,
          // 필요시 다른 정보 추가
        })
      );
      // 로그인 시 날짜 새로고침
      const initialStartDate = new Date();
      const initialEndDate = new Date();
      initialStartDate.setDate(initialStartDate.getDate() - 7);
      initialEndDate.setDate(initialEndDate.getDate() - 1);
      // console.log(initialStartDate)
      dispatch(
        localDate({
          startDate: formatDate(initialStartDate),
          endDate: formatDate(initialEndDate),
        })
      );

      setAccessToken(data.accessToken);
      // 리프레시 토큰 생성 예정
      // console.log(dispatch);
      return { data, errormsg: null }; // 로그인 성공
    }
  } catch (error) {
    return {
      data: null,
      error,
      errormsg: error.response.data.data.details,
    };
  }
};
