import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "../../scss/social/ga4onestep.module.scss"
import { ReactComponent as ModalClose } from "../../../../../assets/images/share/modal-close.svg";
import { ReactComponent as Step } from "../../../../../assets/images/datalink/datalink-ga4-step.svg"

export const Ga4OneStep = ({closeModal, handleNextStep}) => {
    const [checked, onChange] = useState({
        yes: false,
        no: false,
    });

    const handleIndividualChange = (e) => {
        const name = e.currentTarget.getAttribute('data-name');
        onChange((prevState) => {
            const newState = { yes: false, no: false };
            newState[name] = true;
            return newState;
        });
    };

    const isNextButtonActive = checked.yes;

    return (
        <div className={styles.modalBackdrop}>
            <div className={styles.modalContent}>
                <div className={styles.modalCloseBtn}>
                    <div onClick={closeModal} style={{ cursor: "pointer" }}>
                        <ModalClose />
                    </div>
                </div>
                <div className={styles.modalText}>
                    <p className={styles.text1}>데이터 선택하기</p>
                    <p className={styles.text2}>GA4 데이터 연결을 요청해주세요.</p>
                </div>
                <div className={styles.stepBox}>
                    <Step />
                    <div className={styles.stepTxtBox}>
                        <p className={styles.stepTxt1}>데이터 수집 동의</p>
                        <p className={styles.stepTxt2}>GA4 편집자 권한 부여 및 정보 입력</p>
                    </div>
                </div>
                <div className={styles.agreeBox}>
                    <p className={styles.agreeTxt1}>데이터 수집 동의</p>
                    <p className={styles.agreeTxt2}>GA4 데이터 수집을 위해 아래와 같이 개인정보를 수집, 이용합니다.</p>
                    <div className={styles.agreeTable}>
                        <div className={styles.column1}>
                            <p className={styles.row1}>수집 목적</p>
                            <p className={styles.row2}>빅쿼리 연동</p>
                            <p className={styles.row3}>보고서 생성</p>
                        </div>
                        <div className={styles.column2}>
                            <p className={styles.row1}>수집 항목</p>
                            <p className={styles.row4}>GA4 속성 아이디</p>
                            <p className={styles.row4}>이메일</p>
                            <p className={styles.row3}>GA4 빅쿼리 데이터</p>
                        </div>
                        <div className={styles.column3}>
                            <p className={styles.row1}>보유∙이용 기간</p>
                            <div className={styles.row5}>
                                <p>이용 목적</p> 달성 시
                            </div>
                        </div>
                    </div>
                    <p className={styles.infoTxt}>
                        사용자님께서는 해당 사항에 대해 동의 또는 거부할 수 있으나, <br/>
                        필수 동의를 하지 않을 경우 서비스 이용에 어려움이 있을 수 있습니다.
                    </p>
                    <div className={styles.checkBox}>
                        <p className={styles.checkTxt}>위 개인정보 수집 이용에 동의합니다.</p>
                        <div className={styles.checkContainer}>
                            <div
                                className={`${styles['custom-checkbox']} ${checked.yes ? styles.checked : ''}`}
                                onClick={handleIndividualChange}
                                data-name="yes"
                            />
                            <label>예</label>
                            <div
                                className={`${styles['custom-checkbox']} ${checked.no ? styles.checked : ''}`}
                                onClick={handleIndividualChange}
                                data-name="no"
                            />
                            <label>아니오</label>
                        </div>
                    </div>
                    <div className={styles.nextBtn}>
                        <button 
                            className={isNextButtonActive ? styles.active : styles.deactive}
                            onClick={handleNextStep}
                        >
                            다음
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}