import React, { useImperativeHandle, forwardRef } from "react";

export const NaverLogin = forwardRef((props, ref) => {
    const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_LOGIN_CLIENT_ID;
    const NAVER_REDIRECT_URI = process.env.REACT_APP_NAVER_LOGIN_REDIRECT_URI;
    const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&redirect_uri=${NAVER_REDIRECT_URI}&state=${Math.random().toString(36).substring(3,14)}`;

    const triggerNaverLogin = () => {
        window.location.href = NAVER_AUTH_URL;
    };

    useImperativeHandle(ref, () => ({
        triggerNaverLogin,
    }));

    return null;
});