import axios from "../../axios";

export const DaCampaignSumGaugeApi = async ( media, startDate, endDate, accountId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/cost-campaign-dount/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/cost-campaign-dount/`;
    } else {
        path = `${media}/${accountId}/campaigns/cost-campaign-dount/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate
            }
        });
        return data;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 캠페인 게이지차트 오류 발생:", error);
        throw error;
    }
};

export const DaCampaignSumLineApi = async ( media, startDate, endDate, accountId, campaignId, period_type ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/roas-campaign-chart/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/roas-campaign-chart/`;
    } else {
        path = `${media}/${accountId}/campaigns/roas-campaign-chart/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
                campaign_ids: Array.isArray(campaignId) ? campaignId.join(',') : campaignId,
                period_type: period_type
            }
        });
        return data;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 캠페인 라인차트 오류 발생:", error);
        throw error;
    }
};

export const DaCampaignSumTableApi = async ( media, startDate, endDate, accountId, campaignId, page, row, orderBy ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/detail-campaign/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/detail-campaign/`;
    } else {
        path = `${media}/${accountId}/campaigns/detail-campaign/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
                campaign_ids: Array.isArray(campaignId) ? campaignId.join(',') : campaignId,
                page_size: page,
                row_num: row,
                order_by: orderBy
            }
        });
        return data;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 캠페인 테이블 오류 발생:", error);
        throw error;
    }
};

export const DaCampaignSumTotalApi = async ( media, startDate, endDate, accountId, campaignId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/detail-campaign-total/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/detail-campaign-total/`;
    } else {
        path = `${media}/${accountId}/campaigns/detail-campaign-total/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
                campaign_ids: Array.isArray(campaignId) ? campaignId.join(',') : campaignId,
            }
        });
        return data;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 캠페인 테이블 총합 오류 발생:", error);
        throw error;
    }
};