import React from "react";

import styles from "../scss/predimpclick.module.scss";
import { ReactComponent as Labels } from "../../../../assets/images/predict/pred-imp-click-labels.svg"

import { PredResultLine } from "../../../utils/chart/PredChart";
import { InfoIcon } from "../../../utils/info/InfoIcon";

export const PredImpClick = ({ advData, target }) => {
    const pred = advData.data[advData.data.length - 1];
    const data = pred.data[pred.data.length - 1];
    // console.log(advData.data);

    const message = () => {
        return (
            <>
            광고비 조정에 따라 예측된 노출수와 클릭수를 보여줍니다.
            </>
        )
    }

    return (
        <div className={styles.predImpClick}>
            <div className={styles.predImpTxt}>
                <p>예상 노출수, 클릭수</p>
                <InfoIcon message={message()} className="infoTxtBox" />
            </div>
            <div className={styles.predImpLabels}>
                <Labels />
            </div>
            <div className={styles.predImpChart}>
                <PredResultLine data={advData} />
                <div className={styles.lineBorder}></div>
            </div>
        </div>
    )
}