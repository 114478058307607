import React, { useState } from "react";

import { ReactComponent as Banner1 } from "../../../assets/images/home/home-banner-1.svg";
import { ReactComponent as Banner2 } from "../../../assets/images/home/home-banner-2.svg";
import { ReactComponent as Banner3 } from "../../../assets/images/home/home-banner-3.svg";
import { ReactComponent as LeftBtn } from "../../../assets/images/home/home-banner-leftBtn.svg";
import { ReactComponent as RightBtn } from "../../../assets/images/home/home-banner-rightBtn.svg";
import { ReactComponent as Line } from "../../../assets/images/home/home-banner-line.svg";

import styles from "./banner.module.scss";

export const Banner = () => {
    const banners = [<Banner1 />, <Banner2 />, <Banner3 />];
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? banners.length - 1 : prevIndex - 1
        );
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === banners.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <>
            <div className={styles.bannerBtnBox}>
                <div className={styles.bannerBtn}>
                    {/* <div className={styles.bannerBtnStyle} onClick={handlePrevClick} >
                        <LeftBtn />
                    </div> */}
                    <div className={styles.bannerTxtBox}>
                        {/* <p className={styles.bannerTxt1}>애드포레스트</p>
                        <p className={styles.bannerTxt2}>데이터 분석 꿀팁!</p>
                        <p className={styles.bannerTxt3}>자세히보기</p>
                        <Line /> */}
                    </div>
                    {/* <div onClick={handleNextClick} className={styles.bannerBtnStyle}>
                        <RightBtn />
                    </div> */}
                </div>
                {/* <div className={styles.indicatorContainer}>
                    {banners.map((_, index) => (
                        <ul
                            key={index}
                            className={`${styles.indicator} ${currentIndex === index ? styles.active : ""} ${styles.bannerBtnStyle}`}
                            onClick={() => setCurrentIndex(index)}
                        >
                            <li></li>
                        </ul>
                    ))}
                </div> */}
            </div>
            <div className={styles.bannerImg}>
                {banners[currentIndex]}
            </div>
        </>
    );
};
