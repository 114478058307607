import React, { useState } from "react";

import styles from "../scss/box/linkdatalistbox.module.scss"

import { UnLinkModal } from "../modal/UnLinkModal";
import { UnLinkSuccessModal } from "../modal/UnLinkSuccessModal";

export const UnLinkBox = ({increment_id, link_data_nm, handleUnlink, onClose, onConfirm, unLinkSuccessModal, closeUnLinkSuccessModal}) => {
    const [unLinkModal, setUnLinkModal] = useState(false);
    const [selectedIncrementId, setSelectedIncrementId] = useState(null);
    const [selectedLinkDataName, setSelectedLinkDataName] = useState("");

    // const unLinkOpenModal = (incrementId, linkDataName) => {
    //     setSelectedIncrementId(incrementId);
    //     setSelectedLinkDataName(linkDataName);
    //     setUnLinkModal(true);
    // };

    // const unLinkCloseModal = () => {
    //     setUnLinkModal(false);
    //     setSelectedIncrementId(null);
    // };

    // const confirmUnlink = (incrementId) => {
    //     handleUnlink(incrementId);
    //     unLinkCloseModal();
    // };

    // const closeUnLinkSuccessModal = () => {
    //     setUnLinkSuccessModal(false);
    // };

    return (
        <>
        {/* <div className={styles.unlinkBtn}>
            <button onClick={() => unLinkOpenModal(increment_id, link_data_nm)}>연결 해지</button>
        </div> */}
        {/* {unLinkModal && ( */}
            <UnLinkModal 
                linkDataName={link_data_nm}
                incrementId={increment_id}
                onClose={onClose}
                onConfirm={onConfirm}
            />
        {/* )} */}
        {/* {unLinkSuccessModal && (
            <UnLinkSuccessModal onClose={closeUnLinkSuccessModal} />
        )} */}
        </>
    );
}