import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAccessToken, setAccessToken } from "../../../api/axios";
import { checkTokenAPI } from "../../../api/accounts/checkToken";
import { formatDate } from "../../../shared/navbar/utils/util";
import { setCode } from "../../../store/SocialSlice";
import { loginUser } from "../../../store/UserSlice";
import { localDate } from "../../../store/dateSlice";
import { KakaoLoginApi } from "../../../api/socialLogin/KakaoLoginApi";

const { Kakao } = window;

const KakaoLogin = forwardRef((props, ref) => {
    const KAKAO_TOKEN = process.env.REACT_APP_KAKAO_TOKEN;
    const [data, setData] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!Kakao.isInitialized()) {
            Kakao.init(KAKAO_TOKEN);
        }
    }, [Kakao, KAKAO_TOKEN]);

    const getToken = async (authObj) => {
        try {
            const response = await KakaoLoginApi(authObj);
            setData(response);

            if (response.status === 200) {
                localStorage.setItem("accessToken", response.access_token);
                dispatch(loginUser({ username: response.user_id }));
                const initialStartDate = new Date();
                const initialEndDate = new Date();
                initialStartDate.setDate(initialStartDate.getDate() - 7);
                initialEndDate.setDate(initialEndDate.getDate() - 1);
                dispatch(localDate({
                    startDate: formatDate(initialStartDate),
                    endDate: formatDate(initialEndDate),
                }));
                setAccessToken(response.access_token);

                const accessToken = getAccessToken();
                if (accessToken) {
                    const tokenCheckResponse = await checkTokenAPI();
                    if (tokenCheckResponse.status === 200) {
                        navigate("/home");
                    } else {
                        console.error("토큰 검증 실패");
                        navigate("/");
                    }
                } else {
                    console.error("액세스 토큰 없음");
                    navigate("/");
                }
            } else if (response.status === 201) {
                navigate("/social/signup", { state: { username: response.user_id, media: response.media } });
            }
        } catch (error) {
            console.log(error);
            alert(error.response.data.error);
            navigate("/"); 
        }
    };

    const kakaoLoginClickHandler = async () => {
        try {
            await Kakao.Auth.logout(); // 로그아웃이 완료될 때까지 기다림
            Kakao.Auth.login({
                success: (authObj) => {
                    getToken(authObj);
                },
                fail: (err) => {
                    console.log(err);
                },
            });
        } catch (err) {
            console.log("로그아웃 중 오류 발생", err);
        }
    };

    useImperativeHandle(ref, () => ({
        triggerKakaoLogin: kakaoLoginClickHandler,
    }));

    return null;
});

export default KakaoLogin;
