import React from "react";

import { ReactComponent as LinkImg } from "../../../../assets/images/datalink/datalink-link.svg"

import styles from "../scss/modal/unlinkmodal.module.scss"

export const UnLinkModal = ({ linkDataName, incrementId, onClose, onConfirm }) => {
    return (
        <div className={styles.modalBackdrop}>
            <div className={styles.modalContent}>
                <LinkImg />
                <p>{linkDataName}</p>
                <p>연결을 해지하시겠습니까?</p>
                <div className={styles.btnBox}>
                    <button className={styles.closeBtn} onClick={onClose}>취소</button>
                    <button className={styles.unlinkBtn} onClick={() => onConfirm(incrementId)}>해지하기</button>
                </div>
            </div>
        </div>
    );
};

