import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    setSelected: '',
    setMenu:'dashboard'
};

const SelectedSlice = createSlice({
    name: 'selected',
    initialState,
    reducers: {
        setSelected: (state, action) => {
            state.setSelected = action.payload;
        },
        resetSelected: (state) => {
            state.setSelected = '';
        },
        setMenu: (state, action) => {
            state.setMenu = action.payload;
        },
        resetMenu: (state) => {
            state.setMenu = '';
        }
    },
});

export const { setSelected, resetSelected, setMenu, resetMenu } = SelectedSlice.actions;

export default SelectedSlice;
