import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { path } from "../../../store/PathSlice";
import DataLinkBox from "./box/DataLinkBox";
import RecollectBox from "./box/RecollectBox";
import LinkDataListBox from "./box/LinkDataListBox";
import Header from "../../../shared/header/Header";
import Sidebar from "../../../shared/sidebar/Sidebar";

import styles from "./datalink.module.scss"


const DataLink = () => {
    const location = useLocation();
    const url = location.pathname.split("/").pop();
    const dispatch = useDispatch();
    dispatch(path(url));

    return (
        <>
            <div className={styles.main}>
                <div className={styles.container}>
                    <DataLinkBox />
                    {/* <RecollectBox /> */}
                </div>
                <div className={styles.container2}>
                    <LinkDataListBox />
                </div>
            </div>
        </>
    );
};

export default DataLink;