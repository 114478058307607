import axios from "../../axios";

export const DaPagePerformancePieApi = async (media, startDate, endDate, accountId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/creatives/cost-creative-dount/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/creatives/cost-creative-dount/`;
    } else {
        path = `${media}/${accountId}/creatives/cost-creative-dount/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
            }
        });
        return data;
    } catch (error) {
        console.error("지면별 성과 분석 파이 차트 데이터 오류 발생:", error);
        throw error;
    }
};

export const DaPagePerformanceBarApi = async (media, startDate, endDate, accountId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/creatives/roas-creative-chart/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/creatives/croas-creative-chart/`;
    } else {
        path = `${media}/${accountId}/creatives/croas-creative-chart/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
            }
        });
        return data;
    } catch (error) {
        console.error("지면별 성과 분석 막대 차트 데이터 오류 발생:", error);
        throw error;
    }
};