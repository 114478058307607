import React, { useState, useEffect, useContext } from "react";

import { DaPageTableApi, DaPageTableTotalApi } from "../../../../../api/reportDA/creative/DaPageTableApi";

import Loading from "../../../../utils/loading/Loading";
import { ReportDaTable } from "../../../../utils/table/ReportDaTable";

import { SummaryContext } from "../../../summary/DaSummaryProvider";
import { max } from "date-fns";

export const DaPageTable = ({mediaInfoId, adAccountsId, startDate, endDate, mediaName}) => {
    const [data, setData] = useState(null);
    const [total, setTotal] = useState(null);
    const [loading, setLoading] = useState(true);
    const { setPage } = useContext(SummaryContext);

    const handleResponse = (response) => {
        const maxRoas = response.data.reduce((maxItem, item) => {
            return (item.roas > (maxItem.roas || 0)) ? item : maxItem
        })
        
        setPage({roas: maxRoas.landing_type});
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await DaPageTableApi(mediaName, startDate, endDate, adAccountsId);
            const totResponse = await DaPageTableTotalApi(mediaName, startDate, endDate, adAccountsId);
            setData(response);
            setTotal(totResponse);
            handleResponse(response);
            setLoading(false);
            // console.log(response);
        };
        fetchData();
    }, [startDate, endDate, adAccountsId]);

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    const headers = ["노출 지면", "광고비", "노출수", "클릭수", "클릭률", "전환수", "전환 매출액", "광고수익률"]

    const transformedData = data.data.map(item => ({
        "노출 지면": item.landing_type,
        "광고비": item.tot_cost,
        "노출수": item.tot_impression,
        "클릭수": item.tot_click,
        "클릭률": item.click_rate,
        "전환수": item.tot_conversion_count,
        "전환 매출액": item.tot_conversion_amount,
        "광고수익률": item.roas,
    }));

    const transformedTotalData = () => {
        return {
            "노출 지면": "합계",
            "광고비": total.data.tot_cost,
            "노출수": total.data.tot_impression,
            "클릭수": total.data.tot_click,
            "클릭률": total.data.click_rate,
            "전환수": total.data.tot_conversion_count,
            "전환 매출액": total.data.tot_conversion_amount,
            "광고수익률": total.data.roas,
        };
    };

    const totalData = transformedTotalData();
    const combinedData = [totalData, ...transformedData];

    return (
        <>
            <ReportDaTable 
                headers={headers} 
                items={combinedData} 
                mediaInfoId={mediaInfoId}
            />
        </>
    );
}