import React from "react";

const ModalContent = ({ModalTitle}) => {
    if (ModalTitle === "이용약관 동의") {
        return (
            <div>
                서비스 이용약관<br/>

                제 1조 (목적)<br/>
                이 약관은 ㈜트리플하이엠(이하 "제공자"라 합니다)가 고객사명(이하 "이용자"라 칭함)에게 제공하는 데이터 분석 마케팅 솔루션 서비스인 애드포레스트
                (이하 "서비스"라 합니다)의 이용조건, 절차 및 기타 필요한 사항을 규정함을 목적으로 합니다.<br/>
                제 2 조 (용어의 정의)<br/>
                1.	이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br/>
                o	"서비스"라 함은 제공자가 이용자에게 제공하는 데이터 분석 마케팅 솔루션인 애드포레스트의 유·무료 서비스를 통칭합니다.<br/>
                o	"이용자"라 함은 본 약관에 동의하고 아이디 및 비밀번호를 부여받아 서비스를 이용할 수 있는 개인이나 법인 또는 법인에 준하는 단체를 의미합니다.<br/>
                o	"본 계약"이라 함은 서비스 이용과 관련해 제공자와 맺은 별도 계약을 의미합니다.<br/>
                o	"콘텐츠"라 함은 이용자 또는 이용자와 제휴한 자가 판매 또는 제공하는 각종 재화 또는 용역에 대한 부호·문자·도형·색채·음성·음향·이미지 및 영상 등
                (이들의 복합체를 포함합니다)의 자료 또는 정보를 의미합니다.<br/>
                o	"아이디"라 함은 서비스를 이용하기 위해 직접 입력한 전자우편 주소로 회원 고유 정보를 의미합니다.<br/>
                o	"비밀번호"라 함은 이용자가 부여받은 아이디와 일치되는 이용자임을 확인하고, 이용자의 권익 보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을 의미합니다.<br/>
                o	"데이터": 이용자 광고 계정 또는 타 플랫폼을 애드포레스트에 연동하여 생성 및 수집되는 정보로서, 온라인상의 이용자 광고 성과, 전자상거래 성과 등의 정보를 포함해 서비스가 수집, 생성, 가공, 출력 등 처리하는 모든 데이터를 말합니다.<br/>
                o	"서비스 사용료"(이하 "사용료"이라 합니다) : 이용자가 유료 서비스를 사용한 대가에 따라 제공자가 청구하는 금액을 말합니다.<br/>
                2.	이 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은 서비스를 통한 제공자의 안내, 운영정책, 관계 법령 및 상관례에 따릅니다.<br/>
                제 3 조 (개별약정의 우선)<br/>
                제공자는 필요한 경우 서비스 및 관련 제반사항에 대하여 개별 약관 또는 개별 운영 원칙(공고, 공지사항 등)을 정할 수 있으며, 이 약관과 개별 약관 또는 개별 운영 원칙의 내용이 상충되는 경우에는 개별 약관 또는 개별 운영 원칙의 내용을 우선하여 적용한다.<br/>
                제 4 조 (약관의 효력 및 변경)<br/>
                1.	이 약관은 서비스 또는 홈페이지를 통하여 이를 공지하거나 전자우편, 기타의 방법으로 이용자에게 통지함으로써 효력이 발생합니다. 다만, 기존 이용자에게 변경 전 내용이 그대로 적용되는 경우 또는 이용자가 변경 내용에 대한 통지를 받지 아니하겠다는 의사를 명시적으로 표시한 경우에는 그러하지 아니합니다.<br/>
                2.	이용자가 서비스를 이용하기에 앞서 본 계약을 체결한 경우, 이용자의 이용약관 동의는 본 계약 체결로 효력이 발생합니다.<br/>
                3.	제공자는 이 약관의 내용을 변경할 수 있으며, 변경된 약관은 제1항과 같은 방법으로 공지 또는 통지함으로써 효력이 발생합니다.<br/>
                4.	제공자가 약관을 개정하는 경우 적용일자 및 개정 내용을 명시하여 제공자의 홈페이지 또는 서비스 화면에 적용일자 전일까지 공지합니다.<br/>
                5.	제공자가 전 항에 따라 이용자에게 불리하거나 중요한 약관 내용의 변경을 공지 또는 통지하면서 이용자에게 개정약관 적용일자 전까지 의사표시를 하지 않으면 개정약관에 동의한 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도, 이용자가 명시적으로 거부의사를 표명하지 아니한 경우 이용자가 개정 약관에 동의한 것으로 봅니다.<br/>
                6.	이용자는 개정약관에 동의하지 않을 권리가 있습니다. 이용자가 개정약관에 동의하지 않는 경우 제공자는 해당 이용자에 대하여 개정약관의 내용을 적용할 수 없으며, 이 경우 제공자는 이용자와 이용계약을 해지하거나 이용자의 서비스 이용을 제한할 수 있습니다.<br/>
                7.	이용자는 제공자에게 실제로 연락이 가능한 전자우편 등의 정보를 제공하고 해당 정보들을 최신으로 유지하여야 하며, 정기적으로 이용약관을 열람하여 약관의 변경사항을 확인하여야 합니다. 이용자가 이를 게을리하여 발생하는 피해에 대하여 제공자는 책임을 지지 않습니다.<br/>
                제 5 조 (준칙)<br/>
                1.	이 약관에 명시되지 않은 사항이 홈페이지 내에 게시된 내용, 운영정책 또는 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보 등 기타 관련법령에 규정되어 있는 경우 그 규정에 따릅니다.<br/>
                2.	이 약관과 관련해 제공자의 정책 변경, 법령의 제/개정 또는 공공기관의 고시나 지침, 가이드 등에 의하여 제공자가 공지사항 등을 통해 공지하는 내용도 이 약관의 일부를 구성합니다.<br/>
                제 6 조 (서비스 이용계약 성립)<br/>
                1.	서비스 이용계약은 제공자가 정한 온·오프라인 가입 절차와 방법에 따라 이용자가 동의하고, 이용 신청을 한 후 제공자가 이러한 신청에 대하여 승낙함으로써 체결됩니다. 이때, 제공자는 이용자에게 이용자, 이용자의 대리인, 이용자의 직원 등에 대하여 전문기관을 통한 실명확인 및 본인인증 또는 회원 가입 정보 확인을 위한 각종 서류 및 자료를 요청할 수 있습니다.<br/>
                2.	제공자는 다음 각 호에 해당하는 신청에 대하여는 그 사유가 해소될 때까지 승낙을 유보하거나, 승낙을 거부할 수 있습니다.<br/>
                o	타인의 명의를 사용한 본인인증, 회원가입 또는 서비스 신청 시 허위로 정보를 입력한 경우<br/>
                o	제공자로부터 이용자 자격을 상실한 사실이 있는 경우<br/>
                o	기술상 서비스 제공이 불가능한 경우<br/>
                o	제공자로부터 이용자 자격 정지 조치 등을 받은 이용자가 그 조치 기간 중에 회원 탈퇴하고 재가입 신청을 하는 경우<br/>
                o	사회의 안녕질서 또는 미풍양속을 저해하거나 저해할 목적으로 신청한 경우<br/>
                o	기타 제공자가 정한 요건이 만족되지 않았거나 위법 또는 부당한 목적으로 이용신청을 하였음이 확인된 경우<br/>
                3.	전항의 인증 절차 진행 시 이용자의 귀책사유로 타인이 이용자 명의의 인증절차를 수행하거나, 이용자가 타인 명의를 도용하여 인증을 진행하는 경우, 그로 인하여 발생하는 불이익, 기타 손해에 대하여 제공자는 책임을 지지 않습니다.<br/>
                4.	이용자는 서비스 이용을 위하여 제공자가 요청하는 정보를 정확하게 작성하여야 합니다.<br/>
                5.	제공자는 14세 미만 자의 이용 신청에 대하여는 승낙하지 않습니다.<br/>
                제 7 조 (서비스 이용계약 기간)<br/>
                1.	서비스 이용계약 기간은 다음 각 호에서 정한 개시일부터 종료일까지로 합니다. 다만, 유료 서비스 이용계약 기간은 포함하지 않습니다.<br/>
                o	서비스 개시일은 이용자가 서비스에 회원가입을 하고, 회원가입이 최종적으로 완료된 날짜로 정합니다.<br/>
                o	 서비스 종료일은 이용자의 서비스 해지 요청 및 회원 탈퇴에 관한 결과를 제공자가 이용자에게 통보한 날짜로 정합니다. 단, 제공자가 서비스의 이용기간을 특정한 경우에 해당 서비스는 기간의 경과로 자동 종료됩니다.<br/>
                제 8 조 (서비스의 변경, 중단 또는 종료)<br/>
                1.	제공자는 운영상, 경영상, 기술상의 필요에 따라 제공하고 있는 서비스의 전부 또는 일부 내용을 변경하거나 중단 또는 종료할 수 있으며, 서비스의 내용, 이용방법 등에 대하여 변경이 있는 경우에는 변경사유, 변경내용 및 제공일자 등을 변경 7일 이전에, 서비스를 종료하는 경우에는 30일 이전에 이용자에게 해당 사실을 통지합니다. 다만, 사전에 통지할 수 없는 부득이한 사정이 있는 경우에는 사후에 지체 없이 이를 통지할 수 있습니다.<br/>
                2.	제공자는 서비스의 품질 향상 및 정기점검 등의 기술적 필요에 의하여 일시적인 서비스 중단이 불가피한 경우 서비스 중단 1일 전 회원에게 사전 통보 후 서비스를 중단할 수 있습니다. 다만, 불가피하게 사전 통보를 할 수 없는 경우 회사는 이를 사후 통지할 수 있습니다.<br/>
                3.	제공자는 천재지변, 전쟁, 폭동, 테러, 해킹, DDOS 등 불가항력적 사유로 서비스가 중단된 경우 즉시 이러한 사실을 이용자에게 통지하되, 만일 정보통신설비의 작동불능 등의 불가피한 사유로 인하여 즉시 통지가 불가능한 경우에는 이러한 사정이 해소된 이후 즉시 이를 통지합니다.<br/>
                4.	제공자는 필요하다고 판단하는 경우 서비스의 일부를 제휴업체 등 제 3자에게 위탁할 수 있습니다.<br/>
                5.	제공자는 이 약관 및 관련 법령에 특별한 규정이 없는 한 서비스의 변경, 중단 또는 종료로 인하여 이용자가 입은 손해에 대해 아무런 책임을 지지 않습니다.<br/>
                제 9 조 (회원탈퇴 또는 서비스 해지)<br/>
                1.	이 약관은 회원탈퇴와 서비스 해지에 대한 내용을 모두 동일한 내용으로 합니다.<br/>
                2.	제공자와 이용자는 상호 서면 합의에 의하여 회원탈퇴 또는 서비스 해지를 할 수 있습니다. 단, 본 계약을 맺은 이용자는 본 계약에서 다룬 계약 중도 해지 내용에 따라 서비스를 해지할 수 있습니다.<br/>
                3.	이용자가 회원탈퇴 또는 서비스를 해지하는 경우, 제공자는 관련 법령, 본 약관, 개인정보처리방침에 따라 정보를 보유하는 경우를 제외하고는 탈퇴 시점으로부터 6개월 보관 후 이용자의 모든 데이터를 삭제합니다.<br/>
                4.	제공자는 이용자의 회원탈퇴 또는 서비스 해지 신청에 대해서 본 조 제 3항을 모두 이행 후 회원탈퇴 또는 서비스 해지 요청을 받아들여 처리합니다.<br/>
                5.	제공자 또는 이용자는 상대방이 이 약관을 위반할 경우 7일 이상의 기간을 정하여 그 시정을 요구할 수 있고, 그럼에도 불구하고 상대방이 해당 기간 내 이를 시정하지 아니할 경우에는 별도의 최고 없이 서비스를 해지할 수 있습니다. 단, 제공자는 다음 각호의 어느 하나에 해당하는 사유가 이용자에게 발생하는 경우 즉시 이용계약을 해지할 수 있습니다.<br/>
                o	회사에 제출한 서류 또는 정보가 허위로 판명되는 경우<br/>
                o	휴업, 폐업, 영업양도 또는 업종변경(단, 회원과 회사 간에 별도의 합의가 있는 경우는 그에 의함)의 경우<br/>
                o	기업회생절차개시, 파산이 신청되거나 상대방의 중요자산에 대해 가압류나 가처분 등이 내려져 업무를 지속적으로 수행할 수 없는 경우<br/>
                o	이 약관 상의 권리, 의무를 상대방의 사전 서면 동의 없이 제3자에게 양도하는 경우<br/>
                o	업무 내용에 관련 기관의 인허가 미비 또는 취소 등 법률 상의 하자가 있는 경우<br/>
                o	위법 또는 부당한 목적으로 이용을 하였음이 확인된 경우<br/>
                제 10 조 (서비스 이용 제한)<br/>
                1.	제공자는 다음 각 호의 어느 하나에 해당하는 사유가 발생하는 경우 이용자의 서비스 이용을 제한하거나 중단할 수 있습니다. 이 경우 발생하는 모든 책임은 이용자가 부담하여야 합니다.<br/>
                o	이용자가 본 계약이나 이 약관 또는 관련 법령을 위반한 경우<br/>
                o	이용자가 서비스 정책을 위반하여 서비스를 이용하고자 하는 경우<br/>
                2.	제공자는 제1항의 경우 이용자가 해당 사유를 소명하는 등 회사가 정하는 기준을 충족하는 경우 이용 제한 및 중단 조치를 해소할 수 있습니다.<br/>
                제 11 조 (회원에 대한 통지)<br/>
                1.	제공자가 이용자에게 개별적인 통지를 하는 경우, 이용자가 회원가입 시 제출한 전자우편 주소로 해당 통지를 할 수 있습니다.<br/>
                2.	제공자는 불특정다수 회원에 대한 통지의 경우 서비스 사이트에 게시함으로써 개별 통지에 갈음할 수 있습니다.<br/>
                제 14 조 (제공자의 의무)<br/>
                1.	제공자는 이용자에게 서비스를 제공하며, 이용자가 서비스를 이용할 수 있도록 필요한 매뉴얼 및 기술 자문을 제공합니다. 단, 무료 서비스 이용자에 대하여는 제공자의 기술 자문이 제공되지 않을 수 있습니다.<br/>
                2.	제공자는 관련 법령과 이 약관을 위반하는 행위를 하지 않으며, 계속적이고 안정적인 서비스 제공을 위하여 최선을 다합니다.<br/>
                3.	서비스가 중단되는 경우 회사는 최대한 빠른 시간 내에 서비스를 재개하도록 노력합니다. 다만, 천재지변, 전쟁, 폭동, 테러, 해킹 등 불가항력적 사유로 서비스가 중단되어 즉시 조치가 불가능한 경우는 예외로 합니다.<br/>
                4.	제공자는 서비스 이용과 관련하여 이용자로부터 제기된 의견이나 불만, 서비스 개선 요청사항이 정당하다고 인정할 경우 이를 처리합니다.<br/>
                제 15 조 (이용자의 의무)<br/>
                1.	이용자는 이 약관 및 제공자와 맺은 본 계약, 또는 서비스와 관련하여 고지하는 사항, 회사의 정책, 서비스 이용안내 및 주의사항을 준수하여야 하며, 이를 위반하거나 이행하지 아니하여 발생하는 모든 손실, 손해에 대하여 책임을 부담합니다.<br/>
                2.	이용자는 법령상 의무 또는 관련 규제기관의 가이드라인 등의 준수를 위한 회사의 요청에 적극 응하여야 하며, 서비스를 제공받기 위해 제공자가 요청하는 기술적 요청 등에 대해서 특별한 사유가 없는 한 이에 적극 협조하여야 합니다.<br/>
                3.	이용자는 서비스를 통해 자신이 노출하는 콘텐츠 등의 정보가 관련 법령을 위반하거나 제3자의 권리를 침해하지 아니한다는 사실을 보증하여야 하고 이에 대한 모든 책임을 부담하며, 제공자는 이용자가 서비스를 통해 노출하는 콘텐츠 등의 정보가 다음 각 호에 해당하는 경우 제공자의 정책에 따라 이용자에게 해당 콘텐츠 등의 정보에 대하여 수정, 삭제를 요청하거나, 사전 통지 없이 해당 콘텐츠 등의 정보를 삭제, 수정, 열람 제한 등의 조치를 취할 수 있습니다.<br/>
                o	 내용에 허위 또는 과장이 있는 경우<br/>
                o	제공자 또는 제3자의 권리나 명예, 신용 기타 정당한 이익을 침해하는 경우<br/>
                o	불법 음란물 또는 청소년유해매체물 등이 내용에 포함된 경우<br/>
                o	공공질서 및 미풍양속에 해칠 위험이 있거나, 이 약관 또는 관련 법령을 위반하는 경우<br/>
                o	기타 제공자가 금지하는 내용 또는 운영정책에 반하는 내용으로 판단되는 경우<br/>
                4.	전항에 따라 이용자는 서비스를 통해 노출하는 콘텐츠 등의 정보로 인하여 제공자가 제3자에 대하여 손해배상금을 지급하게 되는 경우 이용자는 제공자에 발생한 손해 일체에 대한 배상 및 제공자가 제3자에게 배상한 금액을 즉시 제공자에게 배상하여야 합니다.<br/>
                5.	이용자는 자신에게 다음 각 호 중 어느 하나의 사유가 발생할 경우 이를 발생일로부터 5일 이내에 제공자에게 서면 또는 이메일을 통해 통지하여야 하며, 통지하지 아니하여 발생하는 불이익이나 책임은 모두 이용자가 부담해야 합니다.<br/>
                o	상호, 사업자등록번호, 주소 변��<br/>
                o	폐업, 휴업, 업종변경, 합병, 영업양수도 등의 결정<br/>
                o	서비스 이용과 관련한 회원의 담당직원 및 담당직원의 연락처 등<br/>
                o	기타 서비스 이용의 지속에 영향을 미칠 수 있는 사항<br/>
                6.	이용자는 서비스 및 솔루션을 통해 수집되는 비식별 온라인 행태 정보에 대하여 고객에게 자신의 비식�� 온라인 행태 정보가 수집∙이용되는 사실을 쉽게 알 수 있도록 회사가 정한 방법에 따라 알려야 하며, 회사는 이용자가 위 의무를 이행하지 아니하여 발생한 손해에 대하여 책임을 지지 않습니다.<br/>
                7.	이용자가 전항의 의무를 위반하여 제공자에 손해가 발생한 경우 제공자는 이용자에게 이에 대한 배상을 청구할 수 있습니다.<br/>
                제 16 조 (이용자의 금지행위)<br/>
                1.	이용자가 전항의 의무를 위반하여 제공자에 손해가 발생한 경우 제공자는 이용자에게 이에 대한 배상을 청구할 수 있습니다.<br/>
                o	제공자가 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인 방법으로 서비스를 이용하거나 시스템에 접근하는 행위<br/>
                o	제공자가 이용자의 서비스 이용을 위하여 제공하는 솔루션 등을 임의로 수정, 변경하는 행위<br/>
                o	제공자 및 기타 제3자의 저작권 등 지식재산권에 대한 침해행위<br/>
                o	제공자 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br/>
                o	이용자가 이 약관에 따른 권리, 의무 등의 지위를 이 약관에서 정한 바 이외에 제공자의 사전 서면 동의 없이 제3자에게 양도, 이전, 담보제공 등 일체의 처분 행위<br/>
                o	기타 불법적이거나 부당한 행위<br/>
                2.	이용자는 본 조 위반여부를 확인하기 위해 제공자가 자료 또는 접근권한의 제공 및 관련 사실에 대한 소명을 요청하는 경우, 이에 성실히 임하여야 합니다.<br/>
                제 17 조 (정보의 보호 및 활용)<br/>
                1.	제공자는 이용자에게 서비스를 제공하는 과정에서 관련 법령이 규정한 절차와 방법에 따라 이용자의 개인정보를 안전하게 보호하기 위해 노력하며, 기타 상세한 정책은 관련 법령 및 회사의 개인정보 처리방침에 따릅니다.<br/>
                2.	제공자는 서비스를 통하여 수집, 생성, 가공, 출력 등 처리하는 이용자의 행태정보를 포함한 모든 데이터를 회사의 고유 사업 목적으로 관련 법령을 준수하는 범위 내에서 활용 및 제공할 수 있습니다.<br/>
                3.	제공자는 서비스 이용 계약기간에 관계없이 이용자의 상표, 상호, 콘텐츠, 사이트 화면, 서비스를 통해 노출되는 정보 및 자료 등을 서비스 홍보 등 목적으로 활용 및 제공할 수 있습니다.<br/>
                4.	경우에 따라 제공자는 서비스를 통해 수집 및 처리된 개인정보(예:수신자 정보를 입력할 때, 이용자가 업로드 한 주소록 연락처)를, 이용자를 대리하여 행동합니다. 이 경우 제공자는 이용자가 요청한 서비스를 원활하게 하기 위해 이용자의 지시에 근거해서만 행동하므로, 업로드한 정보에 대해서는 이용자에게 책임이 있습니다.<br/>
                5.	이용자는 서비스 이용과정에서 취득한 일체의 정보를 서면 동의 없이 제3자에게 상업적인 목적으로 플랫폼 및 플랫폼을 가공한 형태, 플랫폼의 상당 부분을 복제한 형태의 소프트웨어 및 웹 서비스를 제공, 공개 또는 누설하여서는 안 됩니다.<br/>

                제 18 조 (정보 제공 및 홍보물 게재)<br/>
                1.	제공자는 서비스를 운영함에 있어서 각종 정보를 서비스에 게재하는 방법 등으로 이용자에게 제공할 수 있습니다.<br/>
                2.	제공자는 서비스에 적절하다고 판단되거나 활용 가능성 있는 홍보물을 게재할 수 있습니다.<br/>
                제 19 조 (이용자 정보의 관리)<br/>
                1.	아이디, 비밀번호에 대한 관리책임은 전적으로 이용자에게 있으며, 이용자는 아이디, 비밀번호를 타인에게 양도, 대여할 수 없습니다.<br/>
                2.	제공자는 제공자의 귀책사유 없이 발생한 이용자의 아이디, 비밀번호의 유출, 양도, 대여로 인한 손실이나 손해에 대하여 아무런 책임을 지지 않습니다.<br/>
                3.	이용자는 아이디, 비밀번호가 도용되거나 제3자가 무단 사용하고 있음을 인지한 경우, 지체 없이 제공자에 통지하여야 하고, 제공자의 안내가 있는 경우에는 그에 따라야 합니다. 이용자가 제공자에 이를 지체 없이 통지하지 않거나 통지한 경우에도 제공자의 안내에 따르지 않아 발생한 손실이나 손해에 대하여 제공자는 아무런 책임을 지지 않습니다.<br/>
                4.	이용자는 서비스의 사용 종료시마다 정확히 접속을 종료하도록 하여야 하며, 이를 태만히 하여 이용자의 정보가 제3자에게 유출되는 경우 등의 결과로 인해 발생하는 손해에 대하여 제공자는 아무런 책임을 지지 않습니다.<br/>
                5.	이용자는 서비스 이용신청 시 기재하거나 제공한 사항이 잘못되었거나 변경되었을 경우 제공자가 정하는 방법으로 즉시 회사에 해당 사항을 알려야 합니다.<br/>
                6.	이용자 정보가 변경되었음에도 해당 사항을 수정하지 않음으로써 발생한 각종 손해와 잘못된 수정으로 인하여 발생한 손해에 대하여 제공자는 아무런 책임을 지지 않습니다.<br/>
                7.	기타 상세한 정책은 제공자의 개인정보보호정책을 따릅니다.<br/>
                제 20 조 (이용자를 통해 취득한 개인정보의 보호)<br/>
                1.	제공자는 이용계약 이행을 위해 취득한 이용자 사이트의 회원 아이디(ID) 및 쿠키번호, 전자우편주소 등을 이 약관에서 정한 목적 이외의 용도로 사용하거나 제3자에게 제공하는 등 외부에 유출할 수 없으며, 관련 법령 등에 따라 철저히 보호하여야 합니다.<br/>
                제 21 조 (데이터 수집 및 이용 등)   <br/>
                1.	제공자는 이용계약 이행을 위해 이용자 웹/모바일에 설치된 분석코드를 통해 수집한 데이터를 본 이용 계약상의 서비스 제공 목적 및 제공자의 개인 맞춤형 상품 추천 서비스, 기타 마케팅 활용 등 회사의 사업 목적으로 이용하고, 관련 법령의 요건을 갖추어 제3자에게 제공할 수 있습니다.<br/>
                2.	데이터는 이용자의 별도 요청이 없는 한 데이터 연결 해지 신청 후 완료 시까지 유효하게 수집하며, 제공자의 데이터 이용목적 달성 시 또는 관계법령에서 정한 보존기간까지 보유합니다.<br/>
                제 22 조 (서비스 품질 개선)<br/>
                1.	제공자는 서비스의 품질 향상을 위해 이용자 사이트의 일부 방문자 트래픽을 대상으로 수시로 테스트를 진행할 수 있습니다.<br/>
                2.	이용자 사이트의 일부 방문자 트래픽을 대상으로 하는 테스트에서 사용하는 데이터의 규모, 상세 변수 설정, 추천 알고리즘 등은 제공자가 임의로 결정할 수 있습니다.<br/>
                제 23 조 (지식재산권)<br/>
                1.	서비스 이용계약과 관련한 제공자의 기술 및 서비스에 대한 소유권 및 일체의 지식재산권(저작권 및 2차적 저작물 작성권 포함)은 제공자에 귀속됩니다.<br/>
                2.	이용자는 제공자가 서비스 이용을 위해 제공한, 제공자가 일체의 지식재산권을 가지거나 제3자 소유의 자료 및 그러한 자료의 개선, 변형물에 대해, 어떠한 권리도 가지거나 취득하지 아니하며, 이에 대한 사용범위는 서비스 이용에 필요한 범위로 한정합니다.<br/>
                3.	이용자가 제공자에 제공한 일반정보, 서비스 이용정보, 리포트 등 생성정보에 대한 소유권 및 일체의 지식재산권(저작권 및 2차적 저작물 작성권 포함)은 제공자에 귀속됩니다.<br/>
                4.	이용자는 서비스를 이용함으로써 얻은 정보 중 회사에 지식재산권이 귀속된 정보를 제공자의 사전 승낙없이 복제, 송신, 출판, 배포, 방송 및 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.<br/>
                5.	이용자가 서비스를 통해 노출하는 콘텐츠가 타인의 저작권 또는 기타 권리를 침해하는 경우, 그에 대한 모든 책임은 이용자가 부담하며, 제공자는 이에 대해 일체 책임을 부담하지 않습니다.<br/>
                제 25 조 (손해배상)<br/>
                1.	이용자와 제공자는 자신이 이 약관을 위반하여 상대방에게 손해가 발생한 경우 상대방이 입은 손해를 배상하여야 합니다. 단, 제공자가 이용자에게 부담하는 손해배상액은 제공자가 이용자로부터 실제 수수한 이용요금을 한도로 합니다.<br/>
                2.	서비스를 이용하면서 불법행위를 하거나 이 약관의 규정을 위반하는 행위를 한 이용자로 말미암아 제공자가 이용자 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 이용자는 자신의 책임과 비용으로 제공자를 면책시켜야 하며, 제공자가 면책되지 못한 경우 그로 인하여 회사에 발생한 손해를 배상해야 합니다.<br/>
                제 26 조 (면책사항)<br/>
                1.	전쟁, 폭동, 테러, 기타 천재지변 및 제3자의 고의에 의한 해킹, DDOS 공격 등 제공자의 책임 없는 사유로 서비스의 장애, 중단 또는 회사의 의무 불이행이 발생한 경우, 제공자는 이에 대한 책임을 부담하지 않습니다.<br/>
                2.	제공자는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대해서는 책임을 부담하지 않습니다.<br/>
                3.	제공자는 이용자가 본 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도 및 정확성 등의 내용에 관하여는 책임을 지지 않습니다.<br/>
                4.	제공자는 이용자와 고객 상호 간에 서비스를 통하여 발생한 거래 등에 대하여 어떠한 책임도 부담하지 않습니다.<br/>
                5.	제공자는 서비스의 효과 및 이용자의 요구사항에 맞거나 특정 수준의 성능 또는 기능을 충족하는 지 여부에 대하여 어떠한 보증도 제공하지 아니하며, 이용자는 자신이 기대했던 서비스 효과의 미흡 등을 이유로 회사에 어떠한 책임도 물을 수 없습니다.<br/>
                6.	제공자는 무료로 제공되는 서비스 이용과 관련하여 관련법령에 특별한 규정이 없는 한 어떠한 손해배상 책임도 지지 않습니다.<br/>
                제 27 조 (분쟁의 조정)<br/>
                본 계약에 규정되지 아니한 사항 또는 해석상 내용이 불분명한 사항에 대해서는 상호 협의, 관련 법규, 상관습의 순서에 따라 해결하되, 협의가 성립되지 아니한 경우 본 계약에 관한 소송의 관할법원은 이용자 소재 관할법원으로 합니다.
            </div>
        );
    };
    if (ModalTitle === "개인정보 수집 및 이용 동의") {
        return (
            <div>   
                개인정보 처리방침<br/>

                ㈜트리플하이엠(이하 '회사')은 정보주체의 자유와 권리 보호를 위해 개인정보 보호법 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 개인정보 보호법 제30조에 따라 고객의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.<br/>
                <br/>
                제1조(개인정보의 처리 목적, 수집 항목, 보유 및 이용 기간)<br/>
                1. 회사는 다음과 같이 정보주체의 개인정보를 처리합니다.<br/>
                <br/>
                <table>
                    <thead>
                        <tr>
                            <th>구분</th>
                            <th>처리 목적</th>
                            <th>수집항목</th>
                            <th>보유 및 이용 기간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>회원 정보</td>
                            <td>회원자격 유지 및 관리, 서비스 제공</td>
                            <td>[필수] 이메일, 광고주명, 업종, 비밀번호</td>
                            <td>회원 탈퇴 시 또는 계약 만료 시</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                2. 서비스 이용과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.<br/>
                · 접속 로그, 접속 IP 정보, 쿠키, 서비스 이용 기록 등<br/>
                3. 관계법령 규정에 의하여 보존할 필요가 있는 경우 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보유합니다.<br/>
                <br/>
                <table>
                    <thead>
                        <tr>
                            <th>구분</th>
                            <th>보유근거</th>
                            <th>보유 및 이용기간</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>계약 또는 청약철회 등에 관한 기록</td>
                        <td rowspan="3">전자상거래 등에서의 소비자 보호에 관한 법률</td>
                        <td>5년</td>
                    </tr>
                    <tr>
                        <td>소비자 불만 또는 분쟁처리에 관한 기록</td>
                        <td>3년</td>
                    </tr>
                    <tr>
                        <td>표시·광고에 관한 기록</td>
                        <td>6개월</td>
                    </tr>
                    <tr>
                        <td>방문에 관한 기록</td>
                        <td>통신비밀보호법</td>
                        <td>3개월</td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                제2조(개인정보의 제3자 제공에 관한 사항)<br/>
                회사는 개인정보를 제1조(개인정보의 처리 목적, 수집 항목, 보유 및 이용 기간)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.<br/>
                1.	정보주체로부터 별도의 동의를 받은 경우<br/>
                2.	법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우<br/>
                <br/>
                제3조(개인정보의 파기 절차 및 방법)<br/>
                1.	회사는 개인정보 보유기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.<br/>
                2.	정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관 장소를 달리하여 보존합니다.<br/>
                3.	개인정보 파기의 절차 및 방법은 다음과 같습니다.<br/>
                o	파기절차<br/>
                회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호 책임자의 승인을 받아 개인정보를 파기합니다.<br/>
                o	파기방법<br/>
                회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.<br/>
                <br/>
                제4조(정보주체와 법정대리인의 권리·의무 및 행사방법에 관한 사항)<br/>
                1.	정보주체는 회사에 대해 언제든지 다음 각호의 개인정보보호 관련 권리를 행사할 수 있습니다.<br/>
                o	개인정보 열람 요구<br/>
                o	오류 등이 있을 경우 정정 요구<br/>
                o	삭제 요구<br/>
                o	처리정지 요구<br/>
                2.	권리 행사는 회사에 대해 개인정보 보호법 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체 없이 조치하겠습니다.<br/>
                3.	권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다. 이 경우 개인정보 처리 방법에 관한 고시(제2020-7호) 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br/>
                4.	개인정보 열람 및 처리정지 요구는 개인정보 보호법 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.<br/>
                5.	개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br/>
                6.	회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.<br/>
                <br/>
                제5조(개인정보의 안전성 확보 조치에 관한 사항)<br/>
                회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.<br/>
                1.	정기적인 자체 감사 실시<br/>
                개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.<br/>
                2.	개인정보 취급 직원의 최소화 및 교육<br/>
                개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.<br/>
                3.	내부관리 계획의 수립 및 시행<br/>
                개인정보의 안전한 처리를 위하여 내부관리 계획을 수립하고 시행하고 있습니다.<br/>
                4.	해킹 등에 대비한 기술적 대책<br/>
                회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안 프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적·물리적으로 감시 및 차단하고 있습니다.<br/>
                5.	개인정보의 암호화<br/>
                이용자의 비밀번호는 암호화되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안 기능을 사용하고 있습니다.<br/>
                6.	접속기록의 보관 및 위변조 방지<br/>
                개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며 다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별 정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다.<br/>
                또한, 접속기록이 위·변조 및 도난, 분실되지 않도록 보안 기능을 사용하고 있습니다.<br/>
                7.	개인정보에 대한 접근 제한<br/>
                개인정보를 처리하는 데이터베이스 시스템에 대한 접근 권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br/>
                8.	문서보안을 위한 잠금장치 사용<br/>
                개인정보가 포함된 서류, 보조 저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.<br/>
                9.	비인가자에 대한 출입 통제<br/>
                개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.<br/>
                <br/>
                제6조(개인정보의 자동 수집 장치의 설치·운영 및 거부에 관한 사항)<br/>
                1.	회사는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.<br/>
                2.	쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.<br/>
                o	쿠키의 사용목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용 형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.<br/>
                o	쿠키의 설치·운영 및 거부 : 웹 브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.<br/>
                	Chrome : [설정 메뉴 선택 &gt; 고급 설정 표시 선택 &gt; 개인정보 및 보안 &gt; 콘텐츠 설정 선택 &gt; 쿠키 설정]<br/>
                	Microsoft Edge : [설정 메뉴 선택 &gt; 쿠키 및 사이트 권한 탭 선택 &gt; 쿠키 및 사이트 데이터 관리 및 삭제 설정]<br/>
                o	쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.<br/>
                <br/>
                제7조(개인정보 보호 책임자 및 열람 청구를 접수·처리하는 부서)<br/>
                1.	회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만 처리 및 피해 구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br/>
                2.	정보주체는 회사의 서비스(또는 사업)를 이용하시면서 발생한 모든 개인정보보호 관련 문의, 불만 처리, 피해 구제 등에 관한 사항을 개인정보 보호 책임자 및 담당 부서로 문의할 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해 드릴 것입니다.<br/>
                3.	정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람 청구가 신속하게 처리되도록 노력하겠습니다.<br/>
                <br/>
                <table>
                    <thead>
                        <tr>
                            <th colspan="2">개인정보 보호 책임자</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>부서명</td>
                            <td>연구개발본부</td>
                        </tr>
                        <tr>
                            <td>성명</td>
                            <td>채광은</td>
                        </tr>
                        <tr>
                            <td>직책</td>
                            <td>솔루션 개발팀 PM</td>
                        </tr>
                        <tr>
                            <td>연락처</td>
                            <td>cls0110@hmcorp.co.kr, 02-2039-7413</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                제8조(권익 침해 구제방법)<br/>
                1.	정보주체는 개인정보 침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해 신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보 침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.<br/>
                <br/>
                <table>
                    <thead>
                        <tr>
                            <th>기관</th>
                            <th>홈페이지</th>
                            <th>전화번호</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>개인정보분쟁조정위원회</td>
                            <td><a href="https://www.kopico.go.kr" target="_blank">www.kopico.go.kr</a></td>
                            <td>(국번없이) 1833-6972</td>
                        </tr>
                        <tr>
                            <td>개인정보침해 신고센터</td>
                            <td><a href="https://privacy.kisa.or.kr" target="_blank">privacy.kisa.or.kr</a></td>
                            <td>(국번없이) 118</td>
                        </tr>
                        <tr>
                            <td>대검찰청</td>
                            <td><a href="https://www.spo.go.kr" target="_blank">www.spo.go.kr</a></td>
                            <td>(국번없이) 1301</td>
                        </tr>
                        <tr>
                            <td>경찰청</td>
                            <td><a href="https://ecrm.cyber.go.kr" target="_blank">ecrm.cyber.go.kr</a></td>
                            <td>(국번없이) 182</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                제9조(개인정보 처리방침의 변경)<br/>
                이 개인정보 처리방침은 2024.07.01부터 적용됩니다.
            </div>
        );
    };
    if (ModalTitle === "광고성 정보 수신 동의") {
        return (
            <p>
                광고성 정보
                제1장 총칙
                 제1조(목적) 이 약관은 앱 마켓 이용계약, 디지털콘텐츠 및 온라인 서비스 이용계약의 중개에 있어서 앱 마켓사업자와 이용자의 권리 및 의무 등에 관한 사항을 규정함을 목적으로 합니다.
                 제2조(정의) 이 약관에서 사용하는 용어의 뜻은 다음과 같습니다.  
                1. "앱 마켓"이라 함은 디지털콘텐츠등제공사업자와 이용자간 디지털콘텐츠 또는 온라인 서비스(이하 "디지털콘텐츠등"이라 합니다)의 거래를 중개하는 앱 마켓사업자의 가상 영업장을 말합니다. 
                2. "사이버몰"이라 함은 디지털콘텐츠등을 거래할 수 있는 디지털콘텐츠등제공사업자의 가상 영업장을 말합니다. 
                3. "앱 마켓사업자"라 함은 앱 마켓을 운영하는 것을 업으로 하는 자를 말합니다. 
                4. "디지털콘텐츠등제공사업자"라 함은 디지털콘텐츠등에 관한 거래를 업으로 하는 자를 말합니다. 
                5. "이용자"라 함은 앱 마켓사업자가 제공하는 서비스를 이용하는 자를 말합니다. 
                6. "회원"이라 함은 앱 마켓 이용계약을 체결하고, 회원 아이디(ID)를 부여받은 이용자를 말합니다. 
                7. "비회원"이라 함은 회원이 아닌 이용자를 말합니다. 
                8. "소비자"라 함은 다음 각 목의 어느 하나에 해당하는 자를 말합니다. 
                가. 앱 마켓사업자 또는 디지털콘텐츠등제공사업자(이하 "앱 마켓사업자등"이라고 합니다)가 제공하는 디지털콘텐츠등을 소비생활을 위하여 이용하는 자 
                나. 가목 외의 자로서 사실상 가목의 자와 같은 지위 및 거래조건으로 디지털콘텐츠등을 거래하는 자 
                다. 디지털콘텐츠등을 최종적으로 사용하거나 이용하는 사업자. 다만, 디지털콘텐츠등을 원재료(중간재를 포함합니다) 및 자본재로 사용하는 사업자는 제외합니다. 
                라. 디지털콘텐츠등을 농업(축산업을 포함합니다) 또는 어업 활동을 위하여 구입한 자. 다만, 「원양산업발전법」 제6조제1항에 따라 해양수산부장관의 허가를 받은 원양어업자는 제외합니다. 
                9. "이용사업자"라 함은 이용자 중 소비자가 아닌 자를 말합니다. 
                10. "디지털콘텐츠"라 함은 부호ㆍ문자ㆍ도형ㆍ색채ㆍ음성ㆍ음향ㆍ이미지 및 영상 등(이들의 복합체를 포함합니다)의 자료 또는 정보로서 그 보존 및 이용의 효용을 높일 수 있도록 디지털 형태로 제작하거나 처리한 것을 말합니다. 
                11. "중개 서비스"라 함은 디지털콘텐츠등제공사업자와 이용자간 디지털콘텐츠등에 관한 거래가 체결될 수 있도록 조력하는 앱 마켓사업자의 알선행위를 말합니다. 
                12. "온라인 서비스"라 함은 앱 마켓사업자의 중개서비스를 제외하고, 앱 마켓사업자등이 앱 마켓 또는 사이버몰(이하 "앱 마켓등"이라고 합니다)에서 이용자에게 제공하는 유상 또는 무상의 서비스를 말합니다. 
                13. "아이디(ID)"라 함은 앱 마켓에서 회원을 식별하기 위한 문자 또는 숫자 등의 조합을 말합니다. 
                14. "비밀번호(PASSWORD)"라 함은 회원임을 확인하기 위해 아이디와 일치되는 문자 또는 숫자 등의 조합 또는 생체인식정보 등을 말합니다. 
                15. "포인트"라 함은 이용자가 디지털콘텐츠등의 대금으로 지급할 수 있는 전자결제수단으로써 앱 마켓사업자가 발행한 것을 말합니다. 
                16. "계속거래"라 함은 1개월 이상에 걸쳐 계속적으로 또는 부정기적으로 온라인 서비스 또는 중개 서비스(이하 "온라인 서비스등"이라 합니다)를 제공하는 계약으로 중도에 해지할 경우 대금 환급의 제한 또
            </p>
        );
    };
}

export default ModalContent;