import React from "react";

import styles from "../scss/predadvresult.module.scss";
import { ReactComponent as AmountLabels } from "../../../../assets/images/predict/pred-adv-result-amount-labels.svg";
import { ReactComponent as CountLabels } from "../../../../assets/images/predict/pred-adv-result-count-labels.svg";

import { PredSingleBarLine } from "../../../utils/chart/PredChart";
import { InfoIcon } from "../../../utils/info/InfoIcon";


export const PredAdvResult = ({ advData, target }) => {
    const totalData = advData.data.flatMap(item => 
        item.data.filter(innerItem => innerItem.media === "total")
    );

    const message = () => {
        return (
            <>
            광고비 조정에 따라 예측된 광고 목표 성과를 보여줍니다.
            </>
        )
    }

    return (
        <div className={styles.predAdvResult}>
            <div className={styles.predAdvTxt}>
                <p>예상 광고 목표</p>
                <InfoIcon message={message()} className="infoTxtBox" />
            </div>
            <div className={styles.predAdvLabels}>
                {target === "conv_count" ? (
                    <><CountLabels /></>
                ):(
                    <><AmountLabels /></>
                )}
            </div>
            <div className={styles.predAdvChart}>
                <PredSingleBarLine data={advData} totalData={totalData} target={target} width="570px" height="320px" />
                <div className={styles.barLineBorder}></div>
            </div>
        </div>
    )
}


