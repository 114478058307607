import React from 'react'

import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    PointElement,
    LineElement,
} from "chart.js";
 
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    PointElement,
    LineElement
);

export const DaBarLine = ({ data, mediaInfoId }) => {
    const barColor1 = () => {
        if (mediaInfoId === 6) {
            return "rgba(0, 176, 80, 1)";
        } else if (mediaInfoId === 7) {
            return "rgba(255, 180, 18, 1)";
        } else if (mediaInfoId === 100) {
            return "rgba(46, 103, 156, 1)";
        }
        return ;
    };

    const barColor2 = () => {
        if (mediaInfoId === 6) {
            return "rgba(181, 205, 250, 1)";
        } else if (mediaInfoId === 7) {
            return "rgba(181, 205, 250, 1)";
        } else if (mediaInfoId === 100) {
            return "rgba(255, 118, 118, 1";
        }
    }

    const lineColor = () => {
        if (mediaInfoId === 6) {
            return "rgba(30, 0, 203, 1)";
        } else if (mediaInfoId === 7) {
            return "rgba(30, 0, 203, 1)";
        } else if (mediaInfoId === 100) {
            return "rgba(30, 235, 235, 1)";
        }
        return ;
    }

    const options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 20,
                        weight: "bold"
                    },
                    color: '#000'
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "bold"
                    },
                    color: '#000'
                },
                grid: {
                    drawOnChartArea: true,
                },
            },
            y2: {
                type: 'linear',
                display: true,
                position: 'right',
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "bold"
                    },
                    color: '#000'
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
            },
            layout: {
                padding: {
                    
                }
            },
            plugins: {
                legend: {
                    display: false,
                    position: "top",
                    align: "start",
                    margin: {
                        bottom: 10,
                    },
                    labels: {
                        // usePointStyle: true,
                        display: false,
                        boxWidth: 40,
                        boxHeight: 0,
                        color: '#000',
                        font: {
                            family: "Pretendard",
                            size: 12,
                            weight: "bold",
                        },
                    },
                    generateLabels: (chart) => {
                        const datasets = chart.chartData.datasets;
                        return datasets.map((dataset, i) => ({
                            text: dataset.label,
                            fillStyle: dataset.backgroundColor,
                            strokeStyle: dataset.borderColor,
                            lineWidth: dataset.borderWidth,
                            hidden: !chart.isDatasetVisible(i),
                            index: i,
                            // line 차트와 bar 차트에 대해 다른 boxWidth 설정
                            boxWidth: dataset.type === 'line' ? 40 : 20,
                        }));
                    },
                },
                datalabels: {
                    display: false,
                },
                responsive: true,
                maintainAspectRatio: false,
        },
    };

    const chartData = {
        labels: data.data.map(item => item.day_week),
        datasets: [
            {
                yAxisID: "y2",
                type: "line",
                // label: lineLabel,
                data: data.data.map(item => item.roas),
                backgroundColor: lineColor,
                borderColor: lineColor,
                borderWidth: 2,
                fill: false,
                tension: 0.1,
                pointRadius: 4,
                pointBackgroundColor: "rgba(0, 0, 0, 1)",
                pointBorderColor: "rgba(0, 0, 0, 1)",
                borderDash: [20, 5],
            },
            {
                yAxisID: "y",
                type: "bar",
                // label: barLabel,
                data: data.data.map(item => item.tot_conversion_amount),
                backgroundColor: barColor1,
                borderColor: barColor1,
                barPercentage: 1,
                categoryPercentage: 0.4,
            },
            {
                yAxisID: "y",
                type: "bar",
                // label: barLabel,
                data: data.data.map(item => item.tot_cost),
                backgroundColor: barColor2,
                borderColor: barColor2,
                barPercentage: 1,
                categoryPercentage: 0.4,
            },
        ]
    };

    return (
        <Bar data={chartData} options={options} />
    );
};