import React from "react";

import styles from "./pwchange.module.scss"
import {ReactComponent as Logo} from "../../assets/images/share/pwfind-logo.svg"

import { PwchangeBox } from "../../components/pwchange/PwchangeBox";

export const Pwchange = () => {
    return (
        <div className={styles.pwcMain}>
            <div className={styles.pwcHeader}>
                <Logo />
            </div>
            <div className={styles.pwcBody}>
                <PwchangeBox />
            </div>
        </div>
    );
};

