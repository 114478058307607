import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "../../scss/social/ga4requestlist.module.scss"
import { ReactComponent as ModalClose } from "../../../../../assets/images/share/modal-close.svg";
import { ReactComponent as GA4 } from "../../../../../assets/images/datalink/datalink-ga4.svg"
import { ReactComponent as Delete } from "../../../../../assets/images/datalink/datalink-delete.svg"

import { Ga4RequestResult } from "./Ga4RequestResult";

export const Ga4RequestList = ({closeModal, handleNextStep, handleHomeStep}) => {
    const [data, setData] = useState(["test","test2","test","test2","test","test2"])
    const [showResult, setShowResult] = useState(false);

    const handleResultOn = () => {
        setShowResult(true)
    };

    const handleResultOff = () => {
        setShowResult(false)
    }

    return (
        <>
            <div className={styles.modalBackdrop}>
                <div className={styles.modalContent}>
                    <div className={styles.modalCloseBtn}>
                        <div onClick={closeModal} style={{ cursor: "pointer" }}>
                            <ModalClose />
                        </div>
                    </div>
                    <div className={styles.modalText}>
                        <p className={styles.text1}>데이터 선택하기</p>
                        <p className={styles.text2}>GA4 데이터 연결을 요청해주세요.</p>
                    </div>
                    <div className={styles.ga4}>
                        <div>
                            <p className={styles.text1} style={{marginBottom:20}}>요청 내역 확인하기</p>
                            {data.length > 0 ? 
                                (
                                    <div className={styles.dataBox}>
                                        {data.map((item, index) => (
                                            <div key={index} className={styles.dataContainer}>
                                                <GA4 style={{marginLeft: "10px"}}/>
                                                <p className={styles.dataName}>{item}</p>
                                                <p className={styles.dataAccount}>Demo Account</p>
                                                <button className={styles.dataBtn} onClick={handleResultOn} >결과 조회하기</button>
                                                <Delete style={{marginLeft: "10px"}}/>
                                            </div>
                                        ))}
                                    </div>
                                ):(
                                    <>
                                        <p className={styles.text2}>연결 요청 내역이 없습니다.</p>
                                    </>
                                )
                            }
                        </div>
                        <div className={styles.ga4LoginBtn}>
                            <button onClick={handleNextStep}>
                                <div>GA4 연결 요청하기</div>
                                <p>▶</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {showResult && <Ga4RequestResult closeModal={closeModal} handleResultOff={handleResultOff}  />}
        </>
    );
}