import React, { useEffect, useImperativeHandle, forwardRef } from "react";
import { GoogleApi } from "../../../../../api/socialLogin/GoogleApi";

const GoogleLink = forwardRef((props, ref) => {
    const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const GOOGLE_REDIRECT_URI = process.env.REACT_APP_GOOGLE_REDIRECT_URI;

    // const code = new URL(window.location.href).searchParams.get('code');

    // const getData = async (google_token) => {
    //     try {
    //         const response = await GoogleApi(google_token);
    //         console.log(response);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };
    // const getToken = async () => {
    //     if (code) {
    //         try {
    //             const response = await GoogleApi(code);
    //             console.log(response);
    //         } catch (err) {
    //             console.log(err);
    //         }
    //     }
    // };

    // useEffect(() => {
    //     if (code) {
    //         getToken();
    //     }
    // }, [code]);

    const GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${GOOGLE_REDIRECT_URI}&response_type=code&state=${Math.random().toString(36).substring(3,14)}&scope=https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile&access_type=offline&prompt=consent`;

    const triggerGoogleLogin = () => {
        window.location.href = GOOGLE_AUTH_URL;
    };

    // 외부에서 로그인 기능을 호출할 수 있도록 설정
    useImperativeHandle(ref, () => ({
        triggerGoogleLogin,
    }));

    return null; // 버튼 자체는 렌더링하지 않음
});

export default GoogleLink;
