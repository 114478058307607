import { useState, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";

import styles from "./mypage.module.scss";

import { path } from "../../store/PathSlice";

import Footer from "../../shared/footer/Footer";
import { MyInfo } from "../../api/mypage/MyInfoAPI";
import Header from "../../shared/header/Header";
import Sidebar from "../../shared/sidebar/Sidebar";
import Login from "../login/Login";

const Mypage = () => {
  const user = useSelector((state) => state.user);
  return <>{user.username !== "" ? <LoggedInView /> : <LoggedOutView />}</>;
};

const LoggedInView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const url = location.pathname.split("/").pop();
  dispatch(path(url));
  const [myInfo, setMyInfo] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await MyInfo();
        setMyInfo(response);
        setLoading(false);
        // console.log(response);
      } catch (error) {
        console.error("Error : ", error);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <>
      </>
    )
  }

  const handleLinkClick = () => {
    alert("준비중인 기능입니다.")
  }
 
  return (
    <>
        <Header />
        <Sidebar />
        <div className={styles.mypageContainer}>
          <div className={styles.mypageContents}>
            <div className={styles.myInfo}>
              <p className={styles.infoTxt}>내 정보</p>
              <pre>이메일:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{myInfo.data.username}</pre>
              <pre>광고주명:&nbsp;&nbsp;&nbsp;{myInfo.data.adv_name}</pre>
              <pre>업종:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{myInfo.data.category}</pre>
            </div>
            <div className={styles.linkContainer}>
              <div className={styles.linkBox}>
                <p>FAQ</p>
                <pre onClick={handleLinkClick}>&gt;</pre>
              </div>
              <div className={styles.linkBox}>
                <p>공지사항</p>
                <pre onClick={handleLinkClick}>&gt;</pre>
              </div>
              <div className={styles.linkBox}>
                <p>이용약관</p>
                <Link to="/terms-of-use">
                  <pre>&gt;</pre>
                </Link>
              </div>
            </div>
            {/* <div className={styles.bannerContainer}>
              <Banner
                selectTarget={selectTarget}
                setSelectTarget={setSelectTarget}
              />
              <div className={styles.detailContainer}>
                {selectTarget === "info" && <MediaInfo />}
                {selectTarget === "ga" && <GaInfo />}
                {selectTarget === "help" && <Help />}
              </div>
            </div> */}
          </div>
          <Footer />
        </div>
    </>
  );
};

export const LoggedOutView = () => {
  return (
    <div>
      <Login />
    </div>
  );
};

export default Mypage;
