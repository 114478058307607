import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './reportcreativeda.module.scss';

import { DaCreativeTop } from './top/DaCreativeTop';
import { DaCreativeWorst } from './worst/DaCreativeWorst';
import { DaPagePerformance } from './analysis/DaPagePerformance';
import { DaDetailAnalysis } from '../campaign/analysis/DaDetailAnalysis';
import { DaCreativeType } from './analysis/DaCreativeType';

import { selectStartDate, selectEndDate } from '../../../../store/dateSlice';

import { useMediaName } from '../../../../hooks/useMediaNm';

const formatDate = (dateString) => {
    const date = new Date(dateString); // 문자열을 Date 객체로 변환
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`; // YYYYMMDD 형식으로 반환
};

export const ReportCreativeDa = ({mediaInfoId, adAccountsId, reportType}) => {
    const startDate = formatDate(useSelector(selectStartDate));
    const endDate = formatDate(useSelector(selectEndDate));
    const mediaName = useMediaName(mediaInfoId);

    return (
        <div className={styles.reportCreativeDa}>
            <DaCreativeTop mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} mediaName={mediaName} />
            <DaCreativeWorst mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} mediaName={mediaName} />
            {/* <DaGroupSumChart mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} selectedValue={selectedValue} setSelectedValue={setSelectedValue} /> */}
            {mediaInfoId === 7 && 
                <DaPagePerformance mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} mediaName={mediaName} />
                // <DaCampTypeAnalysis mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
            }
            {/* <DaDetailAnalysis mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} /> */}
            {mediaInfoId === 7 && 
                <></>
            }
            {/* <DaCreativeType mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} /> */}
            <DaDetailAnalysis mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} reportType={reportType} mediaName={mediaName} />
        </div>
    );
};