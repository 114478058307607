import React from "react";
import { Link } from "react-router-dom";

import './footer.scss'

const Footer = () => {
    return (
        <div className="main-footer">
            <div className="footer-top-box">
                <div className="footer-top-1">
                    <Link to="/terms-of-use">
                        <p>서비스 이용약관</p>
                    </Link>
                    <Link to="/privacy-policy">
                        <p>개인정보 처리방침</p>
                    </Link>
                    {/* <p>광고문의</p> */}
                </div>
                <div className="footer-top-2">
                    {/* <p>NaverBlog</p>
                    <p>Facebook</p>
                    <p>Youtube</p> */}
                </div>
            </div>
            <div className="footer-bottom-box">
                <div className="footer-left">
                        <p>Address.</p>
                        <p>가산오피스 | 서울시 금천구 디지털로 121 에이스가산타워 10F</p>
                        <p>성수오피스 | 서울시 성동구 뚝섬로17가길 48 성수에이스원 지식산업센터 803호</p>
                    <div className="footer-left-div">
                        <p>E-mail. <u>help@hmcorp.co.kr</u></p>
                        <p>Tel. 02.2039.3800</p>
                        <p>Fax. 02.866.2923</p>
                    </div>
                </div>
                <div className="footer-right">
                    <div className="footer-right-div">
                        <p>(주)트리플하이엠</p>
                        <p>CEO. 박혁</p>
                        <p>Business Number. 220-86-93067</p>
                    </div>
                    <p>Copyright © 2024 TipleHM Inc. All Rights Reserved.</p>
                </div>
            </div>
        </div>
    );
}

export default Footer