import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  username: "",
  approve: "",
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.username = action.payload.username;
      state.approve = action.payload.approve;
      // state.accessToken = action.payload.accessToken;
      // state.refreshToken = action.payload.refreshToken;

      return state;
    },
    clearUser: (state) => {
      state.username = "";
      state.approve = "";
      // state.accessToken = "";
      // state.refreshToken = "";
      return state;
    },
  },
  clearUser: (state) => {
    // state.accessToken = "";
    // state.refreshToken = "";
    state.username = "";
    state.approve = "";

    return state;
  },
});

export const { loginUser, clearUser } = UserSlice.actions;

export const selectUsername = (state) => state.user.username;
export const selectApprove = (state) => state.user.approve;

export default UserSlice;
