import React from "react";

import styles from "./content.module.scss"

import { ReactComponent as Kakao } from "../../../../assets/images/home/home-kakao.svg"

export const KakaoKeyword = ({handlePreviewClick}) => {
    return (
        <div className={`${styles.kakaoContent} ${styles.contentBtnStyle}`} onClick={handlePreviewClick} >
            <div className={styles.kakaoBox}>
                <div className={styles.kakaoImg}>
                    <Kakao />
                </div>
                <p className={styles.kakaoTxt1}>카카오키워드 광고 성과 분석 보고서</p>
                <p className={styles.kakaoTxt2}>
                    카카오키워드 SA 광고 성과를 확인해보세요!
                </p>
                <p className={styles.kakaoTxt3}>미리보기 →</p>
            </div>
        </div>
    );
}