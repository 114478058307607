import React, { useState, useEffect } from 'react';

import styles from '../scss/campaigncost.module.scss';

import { CostCampaignGaugeChartApi, CostCampaignLineChartApi } from '../../../../../api/report/cost/CostCampaignChartApi';

import { CampaignGauge } from '../../../../utils/chart/CampaignGauge';
import { CampaignLine } from '../../../../utils/chart/CampaignLine';
import Loading from '../../../../utils/loading/Loading';

export const CampaignCost = ({mediaInfoId, adAccountsId, startDate, endDate, mediaName}) => {
    const [data, setData] = useState(null);
    const [gaugeData, setGaugeData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [metricType, setMetricType] = useState('tot_cost');
    const [timeUnit, setTimeUnit] = useState('day');

    useEffect(() => {
        const fetchData = async () => {
            const gaugeResponse = await CostCampaignGaugeChartApi(mediaName, startDate, endDate, adAccountsId);
            setGaugeData(gaugeResponse);
            const campaignId = gaugeResponse.data.map(item => item.campaign_id)
            let lineResponse = null;
            if (campaignId.length > 0) {
                lineResponse = await CostCampaignLineChartApi(mediaName, startDate, endDate, adAccountsId, campaignId, timeUnit);
            }
            setData(lineResponse);
            setLoading(false);
            // console.log(lineResponse)
        };
        fetchData();
    }, [startDate, endDate, metricType, timeUnit, adAccountsId]);

    if (loading) {
        return (
            <div className={styles.campaignCost}>
                <Loading />
            </div>
        );
    }

    if (!data) {
        return null;
    }

    return (
        <div className={styles.campaignCost}>
            <div className={styles.campaignCostTitle}>캠페인별 광고비</div>
            <div className={styles.campaignCostSelect}>
                <select className={styles[`selectBox${mediaInfoId}`]} value={metricType} onChange={(e) => setMetricType(e.target.value)} style={{width: "105px"}}>
                    <option value="tot_cost">광고비</option>
                    <option value="impression_cost">노출당 비용</option>
                    <option value="cost_click">클릭당 비용</option>
                    <option value="conversion_cost">전환당 비용</option>
                </select>
                <select className={styles[`selectBox${mediaInfoId}`]} value={timeUnit} onChange={(e) => setTimeUnit(e.target.value)}>
                    <option value="day">일자별</option>
                    <option value="week">주별</option>
                    <option value="month">월별</option>
                </select>
            </div>
            <div className={styles.campaignCostChart}>
                <CampaignGauge data={gaugeData} selectedData={metricType} mediaInfoId={mediaInfoId} />
                <CampaignLine data={data.data} selectedData={metricType} mediaInfoId={mediaInfoId} />
            </div>
        </div>
    );
};