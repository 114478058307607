import axios from "../axios"

export const PwchangeApi = async (newPassword, confirmPassword, username) => {
    try {
        const { data } =await axios.post("common/change-password/", {
            new_password: newPassword,
            confirm_password: confirmPassword,
            username: username
        });
        return data
    } catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error)
        return null;
    }
}