import React, { useState, useEffect } from 'react';

import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud';

import styles from '../scss/keywordtrend.module.scss';

import { KeywordTrendApi } from '../../../../../api/report/keyword/KeywordTrendApi';

import Loading from '../../../../utils/loading/Loading';
import { InfoIcon } from '../../../../utils/info/InfoIcon';
import { NullBox } from '../../../../utils/nullBox/NullBox';

// getRotationDegree 함수 정의
function getRotationDegree() {
    const rand = Math.random();
    const degree = rand > 0.5 ? 60 : -60;
    return rand * degree;
}

const colors = ['#143059', '#2F6B9A', '#82a6c2'];

export const KeywordTrend = ({ width = 300, height = 250, adAccountsId, startDate, endDate, mediaName }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [spiralType, setSpiralType] = useState('archimedean');
    const [withRotation, setWithRotation] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await KeywordTrendApi(mediaName, startDate, endDate, adAccountsId);
            setData(response);
            setLoading(false);
            // console.log(response);
        };
        fetchData();
    }, [startDate, endDate, adAccountsId])

    if (loading) {
        return (
            <div className={styles.keywordTrend}>
                <Loading />
            </div>
        );
    }

    if (!data) {
        return null;
    }

    const words = data.data
        .filter(item => item.keyword_name !== 'nan') // 'nan' 제외
        .map((item, index) => ({
            text: item.keyword_name,
            value: item.roas, // count를 정수로 변환
            id: `${item.keyword_name}-${index}`, // 고유 ID 추가
        }));

    const fontScale = scaleLog({
        domain: [Math.min(...words.map((w) => w.value)), Math.max(...words.map((w) => w.value))],
        range: [10, 100],
    });
    const fontSizeSetter = (datum) => fontScale(datum.value);

    const message = () => {
        return (
            <>
            광고수익률 순으로 주요 키워드를 시각적으로 나타냅니다.<br/>
            키워드의 크기가 클수록 해당 키워드가 더 높은 수익을 기록한 것을 의미합니다.
            </>
        )
    };

    const noData = words.every(item => item.value === 0 || item.value == null);

    return (
        <div className={styles.keywordTrend}>
            <div className={styles.keywordTrendTitle}>
                <p>키워드 추세</p>
                <InfoIcon message={message()} className="infoTxtBox" />
            </div>
            <div className={styles.wordcloud}>
                {noData ? (
                    <NullBox />
                ) : (
                    <Wordcloud
                        words={words}
                        width={width}
                        height={height}
                        fontSize={fontSizeSetter}
                        font={'Pretendard'}
                        padding={4}
                        spiral={spiralType}
                        rotate={withRotation ? getRotationDegree : 0}
                        random={() => 0.5}
                    >
                        {(words) =>
                            words.map((w, i) => (
                                <Text
                                    key={w.id} // 고유 ID를 key로 사용
                                    fill={colors[i % colors.length]}
                                    textAnchor={'middle'}
                                    transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                                    fontSize={w.size}
                                    fontFamily={w.font}
                                >
                                    {w.text}
                                </Text>
                            ))
                        }
                    </Wordcloud>
                )}
            </div>
        </div>
    )
}