import React, { useState, useEffect } from 'react';

import styles from "../scss/dadetailanalysis.module.scss"
import {ReactComponent as ExcelDownloadNaver} from '../../../../../assets/images/report/report-excel-down-naver.svg'
import {ReactComponent as ExcelDownloadKakao} from '../../../../../assets/images/report/report-excel-down-kakao.svg'
import {ReactComponent as ExcelDownloadGoogle} from '../../../../../assets/images/report/report-excel-down-google-da.svg'

import { DaDetailCampaignDownloadApi } from '../../../../../api/reportDA/campaign/DaDetailAnalysisApi';

import { FunnelTable } from '../../../../utils/table/FunnelTable';
import Loading from '../../../../utils/loading/Loading';

import { useMediaName } from '../../../../../hooks/useMediaNm';

export const DaCampaignAnalysis = ({
        mediaInfoId, 
        adAccountsId, 
        startDate, 
        endDate, 
        adLevel, 
        setadLevel, 
        fetchData, 
        loading, 
        data, 
        total,
        buttonClicked, 
        setButtonClicked, 
        handleCampaignSelect, 
        handlePageChange, 
        currentPage, 
        pageChange, 
        setPageChange,
        reportType
    }) => {
    const [orderBy, setOrderBy] = useState("campaign_name ASC");
    const [selectedButtons, setSelectedButtons] = useState({
        '클릭률': true,
        '전환수': true,
        '전환 매출액': true,
        '광고수익률': true,
    });

    const mediaName = useMediaName(mediaInfoId);

    useEffect(() => {
        fetchData(orderBy);
        setButtonClicked(null);
        setPageChange(null);
    }, [startDate, endDate, orderBy, pageChange, buttonClicked, adAccountsId]);

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    const transformedData = data.data.map(item => ({
        "캠페인": item.campaign_name,
        "campaignId": item.campaign_id,
        "광고비": item.tot_cost,
        "노출수": item.tot_impression,
        "클릭수": item.tot_click,
        "클릭률": item.click_rate,
        "전환수": parseInt(item.tot_conversion_count),
        "전환 매출액": parseInt(item.tot_conversion_amount),  
        "광고수익률": item.roas,
    }))

    const transformedTotalData = () => {
        return {
            "캠페인": "총합",
            "campaignId": total.campaign_id,
            "광고비": total.data.tot_cost,
            "노출수": total.data.tot_impression,
            "클릭수": total.data.tot_click,
            "클릭률": total.data.click_rate,
            "전환수": parseInt(total.data.tot_conversion_count),
            "전환 매출액": parseInt(total.data.tot_conversion_amount),
            "광고수익률": total.data.roas,
        };
    };

    const getExcelDownloadIcon = () => {
        switch (mediaInfoId) {
            case 6:
                return <ExcelDownloadNaver />;
            case 7:
                return <ExcelDownloadKakao />;
            case 100:
                return <ExcelDownloadGoogle />;
            default:
                return ; // 기본값
        }
    };

    const handleDownload = async () => {
        let fields = [];
    
        for (const [key, value] of Object.entries(selectedButtons)) {
            if (value) {
                switch (key) {
                    case '클릭률':
                        fields.push('click_rate');
                        break;
                    case '전환수':
                        fields.push('tot_conversion_count');
                        break;
                    case '전환 매출액':
                        fields.push('tot_conversion_amount');
                        break;
                    case '광고수익률':
                        fields.push('roas');
                        break;
                    default:
                        break;
                }
            }
        }
    
        // 고정으로 전달할 필드 추가
        fields.push('tot_cost', 'tot_impression', 'tot_click');
    
        try {
            const response = await DaDetailCampaignDownloadApi(
                mediaName,
                startDate,
                endDate,
                adAccountsId,
                fields.join(','),
                orderBy,
                'blob'
            );

            if (!response.headers) {
                throw new Error("응답에 헤더가 포함되어 있지 않습니다.");
            }

            const disposition = response.headers['content-disposition'];
            let fileName = 'download.xlsx';

            if (disposition && disposition.includes('filename=')) {
                const fileNameMatch = disposition.match(/filename[^;=\n]*=(['"]?)([^'"]*)\1/);
                if (fileNameMatch != null && fileNameMatch.length > 2) {
                    fileName = `${decodeURIComponent(fileNameMatch[2])}.xlsx`;
                }
            }

            
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
    
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("다운로드 중 오류 발생:", error);
        }
    };

    const handleCampaignClick = (campaignId) => {
        const newOrderBy = "ad_group_name ASC";
        handleCampaignSelect(newOrderBy, campaignId);
    };

    return (
        <>
            <div className={styles[`detailedAnalysisTable${mediaInfoId}`]}>
                <div className={styles.dataSelectBox}>
                    <div className={styles[`dataSelectButton${mediaInfoId}`]}></div>
                    <button className={styles.excelDownload} onClick={handleDownload}>
                        {getExcelDownloadIcon()}
                    </button>
                </div>
                <FunnelTable 
                    defaultHeaders={['캠페인','광고비','노출수','클릭수']} 
                    headers={selectedButtons} 
                    items={transformedData} 
                    totalItems={transformedTotalData()} 
                    pageData={data.page}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    mediaInfoId={mediaInfoId}
                    adLevel={adLevel}
                    onCampaignClick={handleCampaignClick}
                    reportType={reportType}
                    setOrderBy={setOrderBy}
                    defaultOrderBy={"campaign_name ASC"}
                />
            </div>
        </>
    );
};
