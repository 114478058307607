import React, { useState, useEffect } from "react";

import styles from "../scss/resultweekly.module.scss";

import { PeriodPredictApi } from "../../../../../api/predict/period/PeriodPredictApi";

import { ResultBarLine } from "./ResultBarLine";
import { ResultLine } from "./ResultLine";
import { ResultScoreCard } from "./ResultScoreCard";
import { ResultMediaCard } from "./ResultMediaCard";
import Loading from "../../../../utils/loading/Loading";

export const ResultWeekly = ({ startDate, endDate, incrementId, reportType, linkedData, date }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async() => {
            const response = await PeriodPredictApi(incrementId, reportType);
            setData(response);
            setLoading(false);
            // console.log(response)
        };

        fetchData();
    }, [incrementId]);

    if (loading) {
        return (
            <div className={styles.resultWeek}>
                <Loading />
            </div>
        )
    }

    const mediaData = {
        naver: data.data.flatMap(item => 
            item.data.filter(innerItem => innerItem.media === "naver")
        ),
        kakao: data.data.flatMap(item => 
            item.data.filter(innerItem => innerItem.media === "kakao")
        ),
        google: data.data.flatMap(item => 
            item.data.filter(innerItem => innerItem.media === "google")
        ),
        meta: data.data.flatMap(item => 
            item.data.filter(innerItem => innerItem.media === "meta")
        ),
        total: data.data.flatMap(item => 
            item.data.filter(innerItem => innerItem.media === "total")
        )
    };

    return (
        <div className={styles.resultWeek}>
            <p className={styles.resultWeekTitle}>매출 추세</p>
            <div className={styles.resultChartBox}>
                <ResultBarLine data={data} />
                <ResultLine data={data} mediaData={mediaData} />
            </div>
            <ResultScoreCard startDate={startDate} endDate={endDate} data={data} total={mediaData.total} date={date} reportType={reportType} />
            <ResultMediaCard startDate={startDate} endDate={endDate} data={data} mediaData={mediaData} linkedData={linkedData} reportType={reportType} date={date} />
        </div>
    )
}