import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    setCode: ''
};

const SocialSlice = createSlice({
    name: 'social',
    initialState,
    reducers: {
        setCode: (state, action) => {
            state.Code = action.payload;
        },
        resetCode: (state) => {
            state.Code = '';
        },
    },
});

export const { setCode, resetCode } = SocialSlice.actions;

export default SocialSlice;
