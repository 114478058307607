import React from "react";

import styles from "./content.module.scss"

import { ReactComponent as Google } from "../../../../assets/images/home/home-google.svg"

export const GoogleSA = ({handlePreviewClick}) => {
    return (
        <div className={`${styles.googleContent} ${styles.contentBtnStyle}`} onClick={handlePreviewClick} >
            <div className={styles.googleBox}>
                <div className={styles.googleImg}>
                    <Google />
                </div>
                <p className={styles.googleTxt1}>구글 SA 광고 성과 분석 보고서</p>
                <p className={styles.googleTxt2}>
                    구글 SA 광고 성과를 확인해보세요!
                </p>
                <p className={styles.googleTxt3}>미리보기 →</p>
            </div>
        </div>
    );
}