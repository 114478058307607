import axios from "../axios";

export const CreateReportApi = async (username, reportNm, mediaData, mediaCode, type) => {
    try {
        const { data }  = await axios.post("/report/create/", { 
            username: username,
            reportNm: reportNm,
            mediaData: mediaData,
            mediaCode: mediaCode,
            type: type
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        throw error;
    }
};

export const CreatePredictApi = async (username, reportNm, mediaData, mediaCode, media) => {
    // console.log(mediaData)
    try {
        const formattedMediaData = mediaData.join(',');
        const payload = {
            name: reportNm, // name 필드에 reportNm 값
            type: media, // type 필드
            ad_account_ids: formattedMediaData, // mediaData를 콤마로 구분된 문자열로 변환
        };

        const { data } = await axios.post("/prediction-model/reports/", payload, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return data;
    } catch (error) {
        throw error;
    }
};