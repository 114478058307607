import axios from "../axios";

export const GoogleRedirectApi = async (payload) => {
    const { data } = await axios.post(
        'googleads/datalink/',
        payload,
        { headers: { 'Content-Type': 'application/json' } }
    );
    return data;
}
