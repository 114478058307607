import axios from "../axios"

export const GoogleApi = async (credential) => {
    try {
        const { data } = await axios.post('googleads/ads/',
            { credential },
            { headers: { 'Content-Type': 'application/json' } }
        );
        return data; // 가져온 데이터 반환
    } catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error);
        return null;
    }
}

export const GoogleAccountsApi = async (incrementId) => {
    try{
        const { data } = await axios.get('googleads/accounts/',{
            params: {
                incrementId: incrementId,
            },
        })
        return data;
    }catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error);
        return null;
    }
}