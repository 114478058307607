import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './reportcostda.module.scss';

import { ReportDaScoreCard } from './scorecard/ReportDaScoreCard';
import { DaTrendChart } from './trend/DaTrendChart';
import { DaTrendTable } from './trend/DaTrendTable';
import { DaAvgChart } from './avg/DaAvgChart';
import { DaAvgTable } from './avg/DaAvgTable';
import { DaDeviceChart } from './device/DaDeviceChart';
import { DaFunnelReport } from './funnel/DaFunnelReport';

import { selectStartDate, selectEndDate } from '../../../../store/dateSlice';

import { useMediaName } from '../../../../hooks/useMediaNm';

const formatDate = (dateString) => {
    const date = new Date(dateString); // 문자열을 Date 객체로 변환
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`; // YYYYMMDD 형식으로 반환
};

export const ReportCostDa = ({mediaInfoId, adAccountsId, reportType, setResultData}) => {
    const startDate = formatDate(useSelector(selectStartDate));
    const endDate = formatDate(useSelector(selectEndDate));
    const [selectedValue, setSelectedValue] = useState("day");
    const mediaName = useMediaName(mediaInfoId);

    return (
        <div className={styles.reportCostDa}>
            <ReportDaScoreCard startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} reportType={reportType} mediaName={mediaName} />
            <div className={styles.trendBox}>
                <DaTrendChart mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} selectedValue={selectedValue} setSelectedValue={setSelectedValue} reportType={reportType} mediaName={mediaName} />
                <DaTrendTable mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} selectedValue={selectedValue} reportType={reportType} setResultData={setResultData} mediaName={mediaName} />
            </div>
            <div className={styles.avgBox}>
                <DaAvgChart mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} reportType={reportType} mediaName={mediaName} />
                <DaAvgTable mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} reportType={reportType} mediaName={mediaName} />
            </div>
            {mediaInfoId === 7 &&
                <div className={styles.deviceBox}>
                    <DaDeviceChart mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} reportType={reportType} mediaName={mediaName} />
                </div>
            }
            {mediaInfoId === 100 &&
                <div className={styles.deviceBox}>
                    <DaDeviceChart mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} reportType={reportType} mediaName={mediaName} />
                </div>
            }
            {mediaInfoId === 7 &&
                <DaFunnelReport startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} mediaName={mediaName} />
            }
        </div>
    );
};