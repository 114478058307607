import React, { useState, useEffect } from "react";
import { ReactComponent as ModalClose } from "../../../assets/images/share/modal-close.svg";
import styles from "./datalinkbox.module.scss";

export const InputBox = ({ data, removeOption, onInputChange }) => {
    const [inputValues, setInputValues] = useState({});

    const handleInputChange = (value, event) => {
        const updatedValues = {
            ...inputValues,
            [value]: event.target.value
        };

        setInputValues(updatedValues);

        // 입력된 값을 부모 컴포넌트로 전달
        onInputChange(updatedValues);
    };

    useEffect(() => {
        // 선택된 옵션들이 바뀔 때마다 부모 컴포넌트에 현재 상태를 전달
        onInputChange(inputValues);
    }, [inputValues, onInputChange]);

    return (
        <div className={styles.inputContainer}>
            {data.length > 0 && data.map(option => (
                <div key={option.value} className={styles.inputBox}>
                    <p className={styles.inputTxt}>{option.label}</p>
                    <div className={styles.inputContent}>
                        <input 
                            placeholder="저장할 이름을 입력해주세요."
                            value={inputValues[option.value] || ''} // 상태에서 값 가져오기
                            onChange={(event) => handleInputChange(option.value, event)} // 값 변경 핸들러
                        />
                        <div onClick={() => removeOption(option.value)} style={{ cursor: "pointer" }}>
                            <ModalClose />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
