import React from "react";

import styles from "./menu.module.scss"
import { ReactComponent as RightBtn } from "../../../assets/images/home/home-preview-rightBtn.svg"

import { MyData } from "./mydata/MyData";

export const Menu = ({setMediaData}) => {
    const handleLinkClick = () => {
        alert("준비중인 기능입니다.")
      }
    return (
        <div className={styles.main}>
            <div className={styles.linkToBox}>
                <p className={styles.linkToBoxTxt}>바로가기</p>
                <div className={styles.linkToContent}  onClick={handleLinkClick}>
                    <p className={styles.contentTxt}>FAQ</p>
                    <RightBtn/>
                </div>
                <div className={styles.linkToContent}  onClick={handleLinkClick}>
                    <p className={styles.contentTxt}>공지사항</p>
                    <RightBtn/>
                </div>
            </div>
            <MyData setMediaData={setMediaData} />
        </div>
    );
};