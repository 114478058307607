export const sortItems = (key, sortConfig, setSortConfig, setOrderBy, defaultOrderBy) => {
    let direction = 'descending';
    if (sortConfig.key === key && sortConfig.direction === 'descending') {
        direction = 'ascending';
    } else if (sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'none';
    }
    setSortConfig({ key, direction });
    if (direction === 'none') {
        setOrderBy(defaultOrderBy);
    } else if (direction === 'descending') {
        if (key === "일자") {
            setOrderBy("ad_date DESC");
        } else if (key === "광고비") {
            setOrderBy("tot_cost DESC");
        } else if (key === "노출수") {
            setOrderBy("tot_impression DESC");
        } else if (key === "클릭수") {
            setOrderBy("tot_click DESC");
        } else if (key === "클릭률") {
            setOrderBy("click_rate DESC");
        } else if (key === "클릭당 비용") {
            setOrderBy("cost_click DESC");
        } else if (key === "전환 매출액") {
            setOrderBy("tot_conversion_amount DESC");
        } else if (key === "전환율") {
            setOrderBy("conversion_rate DESC");
        } else if (key === "전환수") {
            setOrderBy("tot_conversion_count DESC");
        } else if (key === "광고수익률") {
            setOrderBy("roas DESC");
        }
    } else if (direction === 'ascending') {
        if (key === "일자") {
            setOrderBy("ad_date ASC");
        } else if (key === "광고비") {
            setOrderBy("tot_cost ASC");
        } else if (key === "노출수") {
            setOrderBy("tot_impression ASC");
        } else if (key === "클릭수") {
            setOrderBy("tot_click ASC");
        } else if (key === "클릭률") {
            setOrderBy("click_rate ASC");
        } else if (key === "클릭당 비용") {
            setOrderBy("cost_click ASC");
        } else if (key === "전환 매출액") {
            setOrderBy("tot_conversion_amount ASC");
        } else if (key === "전환율") {
            setOrderBy("conversion_rate ASC");
        } else if (key === "전환수") {
            setOrderBy("tot_conversion_count ASC");
        } else if (key === "광고수익률") {
            setOrderBy("roas ASC");
        }
    }
};