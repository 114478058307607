import React, { useContext } from "react";
import { useSelector } from "react-redux";

import { SummaryContext } from "./DaSummaryProvider";

import styles from "./summary.module.scss";

import { selectStartDate, selectEndDate } from "../../../store/dateSlice";

export const DaSummary = ({mediaInfoId, selectedButton}) => {
    const {
        conversion,
        convAmount,
        day,
        campaign,
        group,
        subject,
        highSub,
        lowSub,
        page
    } = useContext(SummaryContext);

    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);

    if (conversion === null || convAmount === null || day === null || campaign === null || group === null || subject === null || highSub === null || lowSub === null || page === null) {
        return <div className={styles.summary}>로딩 중...</div>;
    }

    const upDownMent = (upDown) => {
        if (upDown === null) { return ""; }
        // console.log(upDown);
        if (upDown === "up") { return "+"; }
        else if (upDown === "down") { return "-"; }
        return "";
    }

    const mentData = () => {
        if (mediaInfoId === 7) {
            if (selectedButton === 'cost') {
                return [
                    {ment: `설정된 기간동안 <span style="color: red">총 ${conversion}번</span>의 전환이 발생하였고 <span style="color: red">${convAmount}원</span>의 매출이 발생하였네요.`},
                    {ment: `평균 매출이 가장 높은 요일은 <span style="color: red">${day.convAmount}</span>입니다.<br/><span style="color: red">${day.convAmount}</span>에 프로모션이나 할인 이벤트를 추가로 강화해보면 어떨까요?`},
                ]
            } else if (selectedButton === 'campaign') {
                return [
                    {ment: `'<span style="color: red">${campaign.high}</span>'가 광고 수익률이 가장 높아요!<br/><span style="color: red">${campaign.high}</span>와 성과가 낮은 <span style="color: red">${campaign.low}</span>를 비교 분석해보면<br/><span style="color: red">${campaign.low}</span>의 성과를 개선할 수 있는 기회를 찾을 수 있습니다!`},
                    {ment: `'<span style="color: red">${group.high}</span>'에서 높은 광고 수익률을 보이고,<br/>'<span style="color: red">${group.low}</span>'에서 가장 낮은 광고 수익률을 보이고 있어요.<br/>'<span style="color: red">${group.low}</span>'에서의 광고 비용을 줄이고,<br/>'<span style="color: red">${group.high}</span>'에서 비용을 좀 더 투자하는 것이 효과적일 수 있어요!`}
                ]
            } else if (selectedButton === 'subject') {
                return [
                    {ment: `모든 소재 중에서 가장 높은 효율을 보이는 소재는<br/><span style="color: red">${subject.creative}</span>로써, <span style="color: red">${subject.roas}%</span>광고 수익률을 기록하고 있어요. <br/>이전 기간 대비 광고 수익률이 <span style="color: red">${upDownMent(subject.upDown)}${subject.prevRoas}%</span>입니다.<br/>다음 광고 전략에서는 <span style="color: red">${subject.creative}</span>에 광고 예산을 좀 더 투자해보면 어떨까요?`},
                    {ment: `소재 성과 상위 3위까지의 평균 노출 수는 <span style="color: red">${highSub.impression}</span>이며, 평균 클릭수는 <span style="color: red">${highSub.click}</span>입니다.<br/>또한, 평균 매출은 <span style="color: red">${highSub.amount}</span>입니다.<br/>'<span style="color: red">${highSub.creative}</span>'에서 매출이 이전 기간 대비 <span style="color: red">${upDownMent(highSub.upDown)}${highSub.prevRoas}%</span> 한 것으로 보여요.<br/>매출이 높은 소재를 중심으로 마케팅 전략을 최적화 하는 것이 필요해 보이네요.`},
                    {ment: `소재 성과 하위 3위까지의 평균 노출 수는 <span style="color: red">${lowSub.impression}</span>이며, 평균 클릭수는 <span style="color: red">${lowSub.click}</span>입니다.<br/>또한, 평균 매출은 <span style="color: red">${lowSub.amount}</span>입니다.<br/>광고 소재 성과 TOP3의 광고 전략을 참고하여 최적화를 진행해보면 어떨까요?`},
                    {ment: `지면별 성과에서 가장 높은 성과를 보인 노출 지면은 <span style="color: red">${page.roas}</span>이예요.<br/><span style="color: red">${page.roas}</span> 지면의 광고비를 좀 더 투자해보면 어떨까요?`}
                ]
            }
            return [];
        }
        if (mediaInfoId === 100) {
            if (selectedButton === 'cost') {
                return [
                    {ment: `설정된 기간동안 <span style="color: red">총 ${conversion}번</span>의 전환이 발생하였고 <span style="color: red">${convAmount}원</span>의 매출이 발생하였네요.`},
                    {ment: `평균 매출이 가장 높은 요일은 <span style="color: red">${day.convAmount}</span>입니다.<br/><span style="color: red">${day.convAmount}</span>에 프로모션이나 할인 이벤트를 추가로 강화해보면 어떨까요?`},
                    
                ]
            } else if (selectedButton === 'campaign') {
                return [
                    {ment: `'<span style="color: red">${campaign.high}</span>'가 광고 수익률이 가장 높아요!<br/><span style="color: red">${campaign.high}</span>와 성과가 낮은 <span style="color: red">${campaign.low}</span>를 비교 분석해보면<br/><span style="color: red">${campaign.low}</span>의 성과를 개선할 수 있는 기회를 찾을 수 있습니다!`},
                    {ment: `'<span style="color: red">${group.high}</span>'에서 높은 광고 수익률을 보이고,<br/>'<span style="color: red">${group.low}</span>'에서 가장 낮은 광고 수익률을 보이고 있어요.<br/>'<span style="color: red">${group.low}</span>'에서의 광고 비용을 줄이고,<br/>'<span style="color: red">${group.high}</span>'에서 비용을 좀 더 투자하는 것이 효과적일 수 있어요!`}
                ]
            } else if (selectedButton === 'subject') {
                return [
                    {ment: `모든 소재 중에서 가장 높은 효율을 보이는 소재는<br/><span style="color: red">${subject.creative}</span>로써, <span style="color: red">${subject.roas}%</span>광고 수익률을 기록하고 있어요. <br/>이전 기간 대비 광고 수익률이 <span style="color: red">${upDownMent(subject.upDown)}${subject.prevRoas}%</span>입니다.<br/>다음 광고 전략에서는 <span style="color: red">${subject.creative}</span>에 광고 예산을 좀 더 투자해보면 어떨까요?`},
                    {ment: `소재 성과 상위 3위까지의 평균 노출 수는 <span style="color: red">${highSub.impression}</span>이며, 평균 클릭수는 <span style="color: red">${highSub.click}</span>입니다.<br/>또한, 평균 매출은 <span style="color: red">${highSub.amount}</span>입니다.<br/>'<span style="color: red">${highSub.creative}</span>'에서 매출이 이전 기간 대비 <span style="color: red">${upDownMent(highSub.upDown)}${highSub.prevRoas}%</span> 한 것으로 보여요.<br/>매출이 높은 소재를 중심으로 마케팅 전략을 최적화 하는 것이 필요해 보이네요.`},
                    {ment: `소재 성과 하위 3위까지의 평균 노출 수는 <span style="color: red">${lowSub.impression}</span>이며, 평균 클릭수는 <span style="color: red">${lowSub.click}</span>입니다.<br/>또한, 평균 매출은 <span style="color: red">${lowSub.amount}</span>입니다.<br/>광고 소재 성과 TOP3의 광고 전략을 참고하여 최적화를 진행해보면 어떨까요?`},
                ]
            }
            return [];
        }
    }

    return (
        <div className={styles.summary}>
            <div className={styles.summaryTitle}>
                <p className={styles.summaryTitleText}>보고서 핵심 요약</p>
                <p className={styles.summaryTitleDate}>({startDate} ~ {endDate})</p>
            </div>
            <div className={styles[`summaryContent${mediaInfoId}`]}>
                {mentData().map((data, index) => (
                    <p key={index} className={styles.summaryContentText} dangerouslySetInnerHTML={{ __html: data.ment }} />
                ))}
            </div>
        </div>
    );
}