import React from "react";
import { RingLoader } from 'react-spinners';

import "./loading.scss"

const Loading = () => {
    return (
        <div className="Loading-box">
            <RingLoader color="#00A0A0"/>
            <div className="Loading-text-box">
                <p>데이터를 분석하고 있어요.</p>
                <p>잠시만 기다려주세요!</p>
            </div>
        </div>
    );
}

export default Loading