import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Header from "../../shared/header/Header";
import Login from "../login/Login";
import styles from "./home.module.scss";
import Sidebar from "../../shared/sidebar/Sidebar";
import { AdforestHome } from "../../components/sidebar/home/AdforestHome";
import DataLink from "../../components/sidebar/dataLink/DataLink";


const Home = () => {
    const user = useSelector((state) => state.user);
    return <>{user.username !== "" ? <LoggedInView /> : <LoggedOutView />}</>;
};

const LoggedInView = () => {
    const location = useLocation();
    const url = location.pathname.split("/").pop();    
    const selected = useSelector((state) => state.selected.setMenu);
    // console.log(url)
    return (
        <div className={styles.mainBox}>
            
            <div className={styles.mainHome}>
                <Header />
                <Sidebar/>
                {/* {url === "datalink" && <DataLink />} */}
                {(selected === "datalink" || url === 'datalink') && <DataLink />}
                {(selected === "home" || url === 'home') && <AdforestHome />}
            </div>
        </div>
    );
};

export const LoggedOutView = () => {
    return (
        <div>
            <Login />
        </div>
    );
};

export default Home;
