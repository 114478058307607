import React, { useState, useEffect } from 'react';

import styles from "../scss/conversiontrend.module.scss"
import { ReactComponent as KakaoLabels } from "../../../../../assets/images/report/report-trend-kakao-labels.svg"
import { ReactComponent as NaverLabels } from "../../../../../assets/images/report/report-trend-naver-labels.svg"
import { ReactComponent as GoogleLabels } from "../../../../../assets/images/report/report-trend-google-labels.svg"


import { KeywordConversionTrendApi } from '../../../../../api/report/keyword/KeywordConversionTrendApi';

import { BarLine } from "../../../../utils/chart/BarLine";
import Loading from '../../../../utils/loading/Loading';
import { NullBox } from '../../../../utils/nullBox/NullBox';


export const ConversionTrend = ({mediaInfoId, adAccountsId, startDate, endDate, mediaName}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState("day"); // 선택된 값 상태 추가

    const labels = () => {
        if (mediaInfoId === 5) {
            return <NaverLabels />
        } else if (mediaInfoId === 89) {
            return <KakaoLabels />
        } else if (mediaInfoId === 100) {
            return <GoogleLabels />
        }
    } 

    useEffect(() => {
        const fetchData = async () => {
            const response = await KeywordConversionTrendApi(mediaName, startDate, endDate, adAccountsId, selectedValue); // 선택된 값 전달
            setData(response);
            setLoading(false);
            // console.log(response);
        };
        fetchData();
    }, [startDate, endDate, selectedValue, adAccountsId])

    const isDataEmpty = (data) => {
        return data.every(item => 
            item.tot_conversion_amount === 0 &&
            item.tot_cost === 0
        );
    };

    if (loading) {
        return (
            <div className={styles.conversionTrend}>
                <Loading />
            </div>
        );
    }    
                
    return (
        <div className={styles.conversionTrend}>
            <div className={styles.conversionTrendTitle}>전환 추세</div>
            <div className={styles.conversionTrendSelect}>
                <div style={{height: "17px"}}>{labels()}</div>
                <select className={styles[`selectBox${mediaInfoId}`]} value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)}>
                    <option value="day">일자별</option>
                    <option value="week">주별</option>
                    <option value="month">월별</option>
                </select>
            </div>
            <div className={styles.conversionTrendChart}>
                {isDataEmpty(data.data) ? (
                    <NullBox />
                ) : (
                    <BarLine data={data} lineLabel="전환 매출액" barLabel="광고비" mediaInfoId={mediaInfoId} />
                )}
            </div>
        </div>
    )
}
