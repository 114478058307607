import axios from "axios";

const ACCESS_TOKEN_KEY = "accessToken";

export const setAccessToken = (accessToken) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
};

export const getAccessToken = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  return accessToken;
};

export const deleteAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

axios.defaults.withCredentials = true; // 쿠키값 전송 허용
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  // baseURL: "https://adf-back.adforest.co.kr/",
  timeout: 200000, // timeout after 5 seconds
  // headers: {
  //   "Content-Type": "application/json",
  //   Accept: "application/json",
  //   Authorization: `${localStorage.getItem("persist:root")}`,
  // },
});

axiosInstance.interceptors.request.use((config) => {
  const accessToken = getAccessToken();

  if (accessToken) config.headers.Authorization = `${accessToken}`;

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      deleteAccessToken();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
