import React from "react";

import { ReactComponent as Weekly } from "../../../../../assets/images/predict/pred-weekly-img.svg";
import { ReactComponent as Monthly } from "../../../../../assets/images/predict/pred-monthly-img.svg";

export const ReportPreviewPeriod = ({ currentIndex }) => {
    const images = [<Weekly />, <Monthly />];

    return (
        <>
            {images[currentIndex]}
        </>
    );
};
