import axios from "../../axios";

export const DaGroupSumGaugeApi = async ( media, startDate, endDate, accountId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/cost-group-dount/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/cost-group-dount/`;
    } else {
        path = `${media}/${accountId}/campaigns/cost-group-dount/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate
            }
        });
        return data;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 광고그룹 게이지차트 오류 발생:", error);
        throw error;
    }
};

export const DaGroupSumLineApi = async ( media, startDate, endDate, accountId, groupId, period_type ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/roas-group-chart/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/roas-group-chart/`;
    } else {
        path = `${media}/${accountId}/campaigns/roas-group-chart/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
                ad_group_ids: Array.isArray(groupId) ? groupId.join(',') : groupId,
                period_type: period_type
            }
        });
        return data;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 광고그룹 라인차트 오류 발생:", error);
        throw error;
    }
};

export const DaGroupSumTableApi = async ( media, startDate, endDate, accountId, groupId, page, row, orderBy ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/detail-group/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/detail-group/`;
    } else {
        path = `${media}/${accountId}/campaigns/detail-group/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
                ad_group_ids: Array.isArray(groupId) ? groupId.join(',') : groupId,
                page_size: page,
                row_num: row,
                order_by: orderBy
            }
        });
        return data;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 광고그룹 테이블 오류 발생:", error);
        throw error;
    }
};

export const DaGroupSumTotalApi = async ( media, startDate, endDate, accountId, groupId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/detail-group-total/`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/detail-group-total/`;
    } else {
        path = `${media}/${accountId}/campaigns/detail-group-total/`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
                ad_group_ids: Array.isArray(groupId) ? groupId.join(',') : groupId,
            }
        });
        return data;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 광고그룹 테이블 총합 오류 발생:", error);
        throw error;
    }
};