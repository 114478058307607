import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./pwfindbox.module.scss";

import { CertBox } from "./CertBox";
import { EmailCertApi } from "../../api/pwfind/EmailCertApi";


export const PwfindBox = () => {
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(true);
    const [isCertBoxVisible, setIsCertBoxVisible] = useState(false);
    const [username, setUsername] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate()

    const handleSendCode = async () => {
        try {
            const response = await EmailCertApi(username);
            setCode(response);
            // console.log("인증번호 전송 성공:", response);
            setIsCertBoxVisible(true);
        } catch (error) {
            alert("이메일을 다시 확인해주세요.");
            console.error("인증번호 전송 실패:", error);
        }
    };

    // if (loading) {
    //     return (
    //         <>
    //             <Loading />
    //         </>
    //     );
    // }

    const handleAuthenticationSuccess = () => {
        setIsAuthenticated(true);
    };

    const handlePasswordReset = () => {
        if (isAuthenticated) {
            navigate("/pw-change", { state: {username} });
        }
    };

    return (
        <div className={styles.box}>
            <p className={styles.pwfindTxt1}>비밀번호를 잊으셨나요?</p>
            <p className={styles.pwfindTxt2}>이메일을 인증한 후 비밀번호를 재설정해주세요</p>
            <div className={styles.inputBox}>
                <input
                    placeholder="이메일을 입력해주세요."
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <button 
                    onClick={handleSendCode} 
                    className={styles.sendBtn}
                    disabled={!username} // username이 없으면 버튼 비활성화
                >
                    인증번호 전송
                </button>
            </div>
            {isCertBoxVisible && (
                <CertBox 
                    isCertBoxVisible={isCertBoxVisible} 
                    code={code.code} 
                    handleSendCode={handleSendCode}
                    onAuthenticationSuccess={handleAuthenticationSuccess}
                />
            )}
            <div className={styles.btnBox}>
                <button className={styles.closeBtn}>
                    닫기
                </button>
                <button 
                    className={isAuthenticated ? styles.okActiveBtn : styles.okBtn}
                    onClick={handlePasswordReset}
                    disabled={!isAuthenticated}
                >
                    비밀번호 재설정
                </button>
            </div>
        </div>
    );
};
